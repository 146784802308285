import React, { useState } from "react";
import blueMinus from "../images/blue-minus.png";
import bluePlus from "../images/blue-plus.png";

const AccordionItem = ({ title, subHeading, children, isFirstDay = false }) => {
  const [isOpen, setIsOpen] = useState(isFirstDay);

  return (
    <div className="rounded p-2 bg-xtraWhite30 mb-2">
      <div
        className="px-4 p-2 cursor-pointer flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-col">
          <h2 className="text-lg font-semibold">{title}</h2>
          <p className="text-sm">{subHeading}</p>
        </div>
        {isOpen ? (
          <img src={blueMinus} className="w-5 h-5" alt="blue minus" />
        ) : (
          <img src={bluePlus} className="w-5 h-5" alt="blue plus" />
        )}
      </div>
      {isOpen && <div className="p-2">{children}</div>}
    </div>
  );
};

export default AccordionItem;
