import Api from '../components/Api';

const updateCustomerSessionDuration = async (enable, returnMessage) => {
  const service = await Api(`/customer/subscription/session`, 'PUT', {
    enableService: enable,
    returnPrice: returnMessage,
  });

  if (service.status === 200) {
    const result = service.data;
    if (result.success) {
      return result;
    }
  } else {
    return service;
  }
};

export default updateCustomerSessionDuration;
