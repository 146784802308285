import React from 'react';
import { useNavigate } from 'react-router-dom';
import notifySwift from '../constants/notifySwift';

function MembershipOptionsDrawer({ show, setShow, activeMembership, setShowRestartDrawer }) {
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <div className={`drawer-overlay ${show ? 'visible' : ''}`} onClick={handleClose} />
      <div className={`drawer ${show ? 'drawer-open' : 'drawer-closed'} pt-5`}>
        <div className="drawer-content">
          <h4 className="mb-5">Membership Options</h4>
          {activeMembership !== null && activeMembership.cancellationDate ? (
            <div className="flex flex-col gap-4 mb-5">
              <button className="w-full text-sm" onClick={() => navigate('/reconsider-membership')}>
                Reconsider Cancellation?
              </button>
            </div>
          ) : activeMembership !== null && !activeMembership.onHold ? (
            <div className="flex flex-col gap-4 mb-5">
              <button className="bg-xtraWhite30 w-full text-sm" onClick={() => navigate('/pause-membership')}>
                Pause
              </button>
              <button
                className="text-sm w-full"
                onClick={() => {
                  if (!activeMembership) {
                    let path = '/become-a-member/?loggedInUser=true';
                    let token = localStorage.getItem('accessToken');
                    let url = `${process.env.REACT_APP_WP_ACCESS_PAGE}`;
                    notifySwift({
                      function: 'openInternalLink',
                      data: {
                        url: `${url}?token=${token}&path=${path}`,
                      },
                    });
                  } else {
                    navigate('/change-membership', {
                      state: {
                        activeMembership: activeMembership,
                      },
                    });
                  }
                }}
              >
                {activeMembership ? 'Change' : 'Purchase'}
              </button>
              {activeMembership && (
                <button className="redButton w-full text-sm" onClick={() => navigate('/cancel-membership')}>
                  Cancel
                </button>
              )}
            </div>
          ) : (
            <div className="flex flex-row gap-2.5 mb-8 w-full">
              <button
                className="text-sm w-full"
                onClick={() => {
                  if (activeMembership !== null && activeMembership.onHold) {
                    setShow(false);
                    setShowRestartDrawer(true);
                  } else {
                    let path = '/become-a-member/?loggedInUser=true';
                    let token = localStorage.getItem('accessToken');
                    let url = `${process.env.REACT_APP_WP_ACCESS_PAGE}`;
                    notifySwift({
                      function: 'openInternalLink',
                      data: {
                        url: `${url}?token=${token}&path=${path}`,
                      },
                    });
                  }
                }}
              >
                {activeMembership !== null && activeMembership.onHold ? 'Restart' : 'Purchase'}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MembershipOptionsDrawer;
