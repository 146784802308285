import React, { useState } from 'react';
import formatDateForData from '../renderFunctions/formatDateForData';
import { formatTimeSchedule, formatTime } from '../renderFunctions/formatTime';
import emptyCircle from '../images/empty-circle.png';
import fullCircle from '../images/full-circle.png';
import SlideUpDrawer from './SlideUpDrawer';
import moment from 'moment-timezone';
import Api from './Api';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import renderToastError from '../renderFunctions/renderToastError';

const SlotItem = ({ slot, handleOnClick, timeIsSelected }) => {
  const handleClick = () => {
    if (!slot.disabled && !slot.existingReservation && slot.numReservations < slot.capacity) {
      handleOnClick(formatDateForData(slot.date), slot.time, slot.type);
    }
  };

  const [showDrawer, setShowDrawer] = useState(false);

  const addToWaitList = async () => {
    try {
      const addToWlist = await Api(`/schedule/waitlist/add`, 'POST', {
        site: slot.site._id,
        slot: slot,
      });

      if (addToWlist.status === 200) {
        renderToastSuccess({
          message: addToWlist.data.message,
        });
      } else {
        renderToastError({
          message: addToWlist.data.message,
        });
      }
    } catch (error) {
      renderToastError({
        message: error,
      });
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`flex flex-row justify-between items-center rounded p-2 bg-xtraBlack30 mb-2 ${
        slot.numReservations >= slot.capacity || slot.existingReservation ? 'fullSchedule' : ''
      }`}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-row">
          <p className="text-sm">Arrival between</p>
        </div>
        <div className="flex flex-row items-center">
          <h6 className="flex-grow text-lg">{formatTimeSchedule(slot.time)}</h6>
          <div className="flex-row flex items-center">
            {slot.numReservations >= slot.capacity && (
              <button
                onClick={() => {
                  setShowDrawer(true);
                }}
                className="text-xs w-full mr-2 p-1"
              >
                Join Waitlist
              </button>
            )}
            <p className={`${slot.type === 'Peak' ? 'slot-peak' : ''} mr-2 rounded-full pr-2 pl-2 pt-1 pb-1 text-xs`}>
              {slot.type === 'Peak' ? slot.type : ''}
            </p>
            {slot.numReservations >= slot.capacity || slot.existingReservation || slot.timeDisabled ? (
              <p
                className={`${
                  slot.numReservations >= slot.capacity
                    ? 'slot-full'
                    : slot.existingReservation
                    ? 'slot-reserved'
                    : slot.timeDisabled
                    ? 'slot-disabled'
                    : ''
                } mr-2 rounded-full pr-2 pl-2 pt-1 pb-1 text-xs`}
              >
                {slot.numReservations >= slot.capacity
                  ? 'Full'
                  : slot.existingReservation
                  ? 'Reserved'
                  : slot.timeDisabled
                  ? 'Unavailable'
                  : ''}
              </p>
            ) : null}

            {slot.numReservations >= slot.capacity ||
            slot.existingReservation ||
            slot.timeDisabled ? null : timeIsSelected(formatDateForData(slot.date), slot.time) ? (
              <img src={fullCircle} className="h-8 w-8" alt="Full Circle" />
            ) : (
              <img src={emptyCircle} className="h-8 w-8" alt="Empty Circle" />
            )}
          </div>
        </div>
      </div>
      {showDrawer && (
        <SlideUpDrawer
          show={showDrawer}
          setShow={setShowDrawer}
          action={addToWaitList}
          actionHeading={
            'Confirm you would like to be added to waitlist for ' +
            moment(formatDateForData(slot.date) + ' ' + formatTime(slot.time), 'YYYY-MM-DD H:mm').format('LLL')
          }
          actionRightAway={true}
          actionText={'Join Waitlist'}
          message={`By joining the waitlist, you will have the chance to 
              join this session when there is availibility. We will send you a 
              notification, letting you know that you are now able to select this time 
              to reserve. You must be quick when receiving this notification as there may not 
              be many spots available.`}
        />
      )}
    </div>
  );
};

export default SlotItem;
