import React, { useEffect, useState } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import CircularProgressBar from '../components/CircularProgressBar';
import Api from '../components/Api';
import renderToastError from '../renderFunctions/renderToastError';
import BackButton from '../components/BackButton';
import { useCustomer } from '../contexts/CustomerContext';

function RetrieveLockerCode() {
  const { customer, getCustomer, customerLoading } = useCustomer();
  const [email, setEmail] = useState('');
  const [lockerDetails, setLockerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [seconds, setSeconds] = useState(10);
  const [timerStarted, setTimerStarted] = useState(false);
  const [lockerAccess, setLockerAccess] = useState('in-session');

  const handleEmailInput = (value) => {
    setEmail(value.target.value);
  };

  const getTheCode = async () => {
    setLoading(true);
    const locker = await Api(`/locker/session?email=${email}`, 'GET', null);

    if (locker.status === 200) {
      let result = locker.data;
      if (result.success) {
        setLockerDetails(result.locker);
        setLockerAccess(result.access);
        setSeconds(lockerAccess === 'in-access' ? 10 : 30);
        setTimerStarted(true);
      } else {
        renderToastError({
          message: result.message,
        });
      }
    } else {
      renderToastError({
        message:
          'Sorry, we were unable to retrieve the locker information. Please try again or speak to one of our staff to assist.',
      });
    }

    setLoading(false);
  };
  useEffect(() => {
    let timer;
    if (timerStarted && seconds > 0) {
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (seconds === 0) {
      setLockerDetails(null);
      setSeconds(lockerAccess === 'in-access' ? 10 : 30);
      setTimerStarted(false);
      setEmail('');
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [seconds, timerStarted, lockerAccess]);

  useEffect(() => {
    getCustomer();
    setLoading(false);
  }, [getCustomer]);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-10 items-center justify-center"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      {customer && (
        <div className="absolute top-6 left-6">
          <BackButton />
        </div>
      )}
      <div className="flex flex-col max-w-[70vw] ">
        {loading || customerLoading ? (
          <CircularProgressBar />
        ) : lockerDetails !== null ? (
          <>
            {lockerAccess !== 'in-session' && (
              <div className="bg-[#be0707] mb-8 p-2.5 rounded-lg text-center">
                {' '}
                <p className="text-[14px]">
                  You have requested this locker code after you ended your session. We have logged this in our system
                  and will review surveillance footage in the event of reports of stolen goods or misuse of the club's
                  terms and conditions.
                </p>{' '}
              </div>
            )}
            <h1 className="mb-5">Your Locker</h1>
            <h3 className="mb-2">Number: {lockerDetails.lockerNumber}</h3>
            <h3 className="mb-2">Code: {lockerDetails.lockerCode}</h3>
            <h5 className="mt-10">This screen will reset in: {seconds}</h5>
          </>
        ) : (
          <>
            <h1 className="mb-5">Retrieve your locker code</h1>
            {customer && <h5 className="mb-2">Using your email address, we will retrieve your locker code</h5>}
            <input
              type={customer ? 'hidden' : 'email'}
              name="email"
              onChange={(value) => {
                handleEmailInput(value);
              }}
              disabled={!!customer}
              value={customer ? customer.email : email}
              placeholder="Enter your email address"
            />
            <button className="mt-5" onClick={() => getTheCode()} title="Retrieve">
              Retrieve
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default RetrieveLockerCode;
