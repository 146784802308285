import React, { useState } from 'react';
import CircularProgressBar from './CircularProgressBar';

function SlideUpDrawer({
  show,
  setShow,
  preAction = null,
  action,
  actionText,
  actionHeading = null,
  actionClass,
  actionRightAway = false,
  doNotCloseDrawer= false,
  message = null,
  loading = false,
  setLoading = null,
  confirmMessage = null,
  disableContinue = false,
  customContent,
}) {
  const [confirming, setConfirming] = useState(false);

  const handleOptionClick = async (option) => {
    if (option === 'Check Schedule') {
      await preAction();
      setConfirming(true);
    } else {
      setConfirming(true);
    }
  };

  const handleConfirm = () => {
    // console.log('handle confrm');
    action();

    if(!doNotCloseDrawer){
      // Close drawer
      setShow(false);
      setConfirming(false);
    }
  };

  const handleClose = () => {
    if (setLoading !== null) {
      setLoading();
    }
    setShow(false);
    setConfirming(false);
  };

  return (
    <>
      <div className={`drawer-overlay ${show ? 'visible' : ''}`} onClick={handleClose} />
      <div className={`drawer ${show ? 'drawer-open' : 'drawer-closed'} pt-5`}>
        <div className="drawer-content">
          {customContent ? (
            customContent
          ) : (
            <>
              {!confirming ? (
                <div className="flex flex-col pb-8">
                  <h4 className="mb-5">{actionHeading !== null ? actionHeading : actionText}</h4>
                  {loading ? (
                    <CircularProgressBar />
                  ) : (
                    <div className="flex flex-col">
                      {message !== null ? <p className="mb-5">{message}</p> : <></>}
                      <button
                        className={`mb-5 ${actionClass}`}
                        onClick={actionRightAway ? () => handleConfirm() : () => handleOptionClick(actionText)}
                      >
                        {actionText}
                      </button>
                      <button className="cancel" onClick={handleClose}>
                        Close
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col pb-8">
                  <h4 className="mb-5">
                    {preAction !== null && loading
                      ? 'Checking...'
                      : disableContinue
                      ? "Sorry, we're full!"
                      : 'Are you sure?'}
                  </h4>
                  {loading ? (
                    <CircularProgressBar />
                  ) : (
                    <>
                      {confirmMessage !== null ? <p className="mb-5">{confirmMessage}</p> : <></>}
                      {disableContinue ? (
                        <button className="confirm mb-5" onClick={handleClose}>
                          Close
                        </button>
                      ) : (
                        <>
                          <button
                            className={`mb-5 ${preAction !== null ? actionClass : 'confirm'}`}
                            onClick={handleConfirm}
                          >
                            Yes
                          </button>
                          <button className="cancel" onClick={handleClose}>
                            No
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SlideUpDrawer;
