import React, { useState, useEffect, useRef } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import StringListWithTicks from '../components/StringListWithTicks';
import ErrorPopover from '../components/ErrorPopover';
import moment from 'moment-timezone';
import Api from '../components/Api';
import SafeAreaHeader from '../components/SafeAreaHeader';
import withdrawReservation from '../constants/withdraw';
import { useCustomer } from '../contexts/CustomerContext';

const ReservationConfirmed = () => {
  const navigate = useNavigate();
  const { getCustomer, customerEntitlements } = useCustomer();
  const { state } = useLocation();
  const { reservations } = state;
  const [formattedReservations, setFormattedReservations] = useState([]);
  const [error, setError] = useState(null);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const statusIntervalRef = useRef();

  useEffect(() => {
    const formatReservations = (reservations) => {
      const formattedReservations = reservations.map((reservation) => {
        let time = String(reservation.time);
        time = time.includes('.5') ? `${String(reservation.time).replace('.5', ':30')}` : reservation.time;
        const date = moment(reservation.date + ' ' + time, 'YYYY-MM-DD H:mm').format('dddd, MMM Do h:mm a');
        const duration =
          !reservation.sessionPackUsage && customerEntitlements ? customerEntitlements['Session Duration'] : '90';
        return {
          text: `Reservation on ${date} for ${duration ? duration : '90'} minutes`,
          status: reservation.status,
        };
      });

      // Check if all reservations have status other than 'queued' or 'pending'
      const allStatusValid = formattedReservations.every(
        (reservation) => reservation.status !== 'Queued' && reservation.status !== 'Pending'
      );

      if (allStatusValid) {
        setShowContinueButton(true);
        clearInterval(statusIntervalRef.current);
      }

      setFormattedReservations(formattedReservations);
    };

    formatReservations(reservations);

    const checkQueueStatus = async (queuedReservations) => {
      try {
        const reservationStatus = await Api('/schedule/checkstatus', 'post', { reservations: queuedReservations });

        if (reservationStatus.status === 200) {
          const result = reservationStatus.data;
          if (result.success && result.data.reservations.length > 0) {
            formatReservations(result.data.reservations);
          }
        } else {
          setError('There was an error completing your reservation.');
        }
      } catch (error) {
        setError('There was an error completing your reservation.');
      }
    };

    const interval = setInterval(() => {
      checkQueueStatus(reservations);
    }, 1000);
    statusIntervalRef.current = interval;

    return () => {
      clearInterval(interval);
    };
  }, [reservations, customerEntitlements]);

  const undoReservation = async () => {
    await withdrawReservation({
      reservations: reservations,
      message: 'There was an error trying to undo your reservations. Please contact us for help.',
    });
  };

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-top bg-cover ]"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-col p-6 h-[80vh] overflow-y-scroll">
        <div className="flex flex-row mb-4 items-center">
          <h4 className="shrink">
            {showContinueButton
              ? 'Reservations have been processed'
              : `Confirming your reservation${formattedReservations.length > 1 ? 's' : ''}...`}
          </h4>
        </div>
        {formattedReservations.length > 0 && <StringListWithTicks items={formattedReservations} />}
      </div>
      <div className="flex align-center justify-center p-6">
        <button
          className={`${
            showContinueButton ? 'bg-xtraAqua' : 'bg-xtraWhite30'
          } xtraWhite50 rounded-full px-10 py-3 w-full`}
          onClick={() => {
            if (showContinueButton) {
              navigate('/your-sessions');
            } else {
              undoReservation();
              navigate(-1);
            }
          }}
        >
          {showContinueButton ? 'Continue' : 'Undo'}
        </button>
      </div>
      {error && <ErrorPopover message={error} onClose={() => setError(null)} />}
    </div>
  );
};

export default ReservationConfirmed;
