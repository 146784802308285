import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlanIcon } from '../assets/tabBar/Plan.svg';

const defaultTabs = [
  { name: 'Sessions', Icon: PlanIcon, route: '/your-sessions' },
  { name: 'Help', Icon: PlanIcon, route: '/help' },
];

const TabBarNavigator = ({ tabs = defaultTabs, paddingHorizontal }) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to update window size
    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener to window resize
    window.addEventListener('resize', updateSize);

    // Call updateSize function once initially
    updateSize();

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', updateSize);
  }, []); // Empty dependency array ensures this effect only runs once

  return (
    <div
      className="fixed bottom-0 left-0 right-0 shadow-md tabBarBackground"
      style={{
        paddingBottom: windowSize.height > 667 ? 30 : 0,
        paddingLeft: paddingHorizontal ?? 0,
        paddingRight: paddingHorizontal ?? 0,
      }}
    >
      <div className="flex flex-row justify-evenly">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`flex flex-column ${
              window.location.pathname.replace(/\/$/, '') === tab.route.split('?')[0] ? 'tabActive' : 'tabInactive'
            }`}
          >
            <button
              key={tab.name}
              className={`flex flex-col items-center tabBarIcon p-2 w-full`}
              onClick={() => navigate(tab.route)}
            >
              <tab.Icon className="h-7 w-7" />
              <span className="text-xs mt-2">{tab.name}</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabBarNavigator;
