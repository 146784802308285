import React, { useState, useEffect, useRef } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Api from '../components/Api';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import SlideUpDrawer from '../components/SlideUpDrawer';
import { useCustomer } from '../contexts/CustomerContext';

const ReconsiderMembership = () => {
  const navigate = useNavigate();
  const { getCustomer, customerMembership, customerLoading } = useCustomer();
  const mainContainer = useRef();
  const [loading, setLoading] = useState(true);
  const [renderUnableToReconsider, setRenderUnableToReconsider] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const handleReconsiderSubmission = async () => {
    // Form submission logic goes here
    setLoading(true);
    try {
      const cancel = await Api('/customer/membership/reactivate', 'POST', {
        customerMembership,
      });
      console.log('data: ', cancel.data);
      if (cancel.status === 200) {
        if (cancel.data?.success) {
          getCustomer({ update: true });
          renderToastSuccess({
            message: cancel.data.message,
            onClose: () => {
              navigate(-1);
            },
          });
        } else {
          renderToastError({
            message: cancel.data?.message,
          });
        }
      } else {
        if (cancel?.response?.status === 400) {
          var cancelResponse = cancel.response.data;
          console.log('cancel response: ', cancelResponse);
          if (cancelResponse?.message) {
            renderToastError({
              message: cancelResponse.message,
            });

            setRenderUnableToReconsider(true);
          } else {
            renderToastError({
              message:
                'Sorry, we tried to send your request but failed. Please try again or contact us for assistance.',
            });
          }
        } else {
          renderToastError({
            message: cancel.data?.message,
          });
        }
      }
    } catch (error) {
      renderToastError({
        message: 'Sorry, we tried to send your request but failed. Please try again or contact us for assistance.',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getCustomer();
    setLoading(false);
  }, [getCustomer]);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <div className="flex flex-row items-center mb-6 mx-6">
          <BackButton />
          <h6>Reconsider membership cancellation</h6>
        </div>
        {loading || customerLoading ? (
          <CircularProgressBar />
        ) : renderUnableToReconsider ? (
          <div className="flex flex-col">
            <div className="py-3 px-5 flex flex-row gap-3 rounded-lg text-sm bg-xtraWhite20 mb-2.5 mx-6">
              <div className="flex justify-center items-center rounded-full bg-xtraError w-4 h-4">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">
                Please note: We were unable to process your re-activation of your membership and your request has been
                sent to us. We will be in contact with you shortly.
              </p>
            </div>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="ml-6 mr-6"
            >
              Continue
            </button>
          </div>
        ) : customerMembership !== null ? (
          <div className="flex flex-col gap-2.5 overflow-scroll max-h-[70vh]" ref={mainContainer}>
            <div className="py-3 px-5 flex flex-row gap-3 rounded-lg text-sm bg-xtraWhite20 mb-2.5 mx-6">
              <p className="flex-1 text-sm">
                Please note: Your membership will be re-activated right away once you press to confirm re-activation.
              </p>
            </div>
            <div>
              <div className="acc-row px-6 py-5">
                <p className="text-sm">Your next payment date </p>
                <p className="text-bold">{moment(customerMembership?.cancellationDate).format('Do MMM YY')}</p>
              </div>
              <div className="acc-row px-6 py-5">
                <p className="text-sm">Your next payment amount</p>
                <p className="text-bold">{customerMembership?.nextPaymentAmount}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="px-5">
            <p>Sorry, your subscription details were not able to be retrieved. Please try again.</p>
          </div>
        )}
      </div>
      {customerMembership !== null && !renderUnableToReconsider && (
        <div className="flex flex-col mb-10 px-5">
          <button
            className="w-full bg-xtraWhite10 border border-xtraAqua"
            onClick={() => {
              // handleReconsiderSubmission();
              setDisplayConfirmation(true);
            }}
          >
            {'Re-Activate Membership'}
          </button>
        </div>
      )}
      <SlideUpDrawer
        show={displayConfirmation}
        setShow={setDisplayConfirmation}
        action={handleReconsiderSubmission}
        actionText={'Re-Activate'}
        actionHeading={'Confirm you would like to re-active your membership?'}
        message={'Please note: Your membership will be re-activated right away.'}
        actionRightAway={true}
      />
    </div>
  );
};

export default ReconsiderMembership;
