import React from 'react';

const ErrorMessagePopup = ({ message, messageTitle, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-4 md:p-8 rounded-lg shadow-lg text-center">
        <h2 className="text-red-500 text-xl font-bold mb-4">{messageTitle}</h2>
        <p className='text-red-500'>{message}</p>
        <button
          className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorMessagePopup;
