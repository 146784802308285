import React, { useEffect, useState } from 'react';
import TabBarNavigator from '../components/TabBarNavigator';
import xtraBG from '../images/xtra-bg.jpg';
import ReservationsList from '../components/ReservationsList';
import { useNavigate, useLocation } from 'react-router-dom';
import SafeAreaHeader from '../components/SafeAreaHeader';
import Api from '../components/Api';
import SlideUpDrawer from '../components/SlideUpDrawer';
import bluePlus from '../images/blue-plus.png';
import { ReactComponent as PlanIcon } from '../assets/tabBar/Plan.svg';
import { ReactComponent as HelpIcon } from '../assets/tabBar/Help.svg';
import AccountIcon from '../components/AccountIcon';
import renderToastError from '../renderFunctions/renderToastError';
import BackButton from '../components/BackButton';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import { useCustomer } from '../contexts/CustomerContext';

const Waitlist = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { waitlist } = state;
  const { customer, getCustomer } = useCustomer();
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedWaitlist, setSelectedWaitlist] = useState(null);
  const [theWaitlist, setWaitlist] = useState(waitlist);

  const withdrawFromWaitlist = async () => {
    console.log('selectedWaitlist: ', selectedWaitlist);
    try {
      const waitlistRemove = await Api(`/schedule/waitlist/remove`, 'POST', {
        waitlistId: selectedWaitlist._id,
      });
      if (waitlistRemove.status === 200) {
        let result = waitlistRemove.data;
        if (result.success) {
          renderToastSuccess({ message: result.message });
          setSelectedWaitlist(null); // Clear the selected waitlist
          setWaitlist((prevWaitlist) => prevWaitlist.filter((item) => item._id !== selectedWaitlist._id));
        }
      } else {
        renderToastError({ message: waitlistRemove.data.message });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center mb-4">
          <div className="flex flex-row">
            <BackButton onClick={() => navigate('/your-sessions')} />
            <h3 className="font-recoleta">Your waitlist</h3>
          </div>
          <div className="flex flexp-row">
            <img
              src={bluePlus}
              className="w-9 h-9 rounded-full mr-2"
              alt="blue plus"
              onClick={() => navigate('/reserve')}
            />
            <AccountIcon yourDetails={customer} handleClick={() => navigate('/you')} noPhoto={false} />
          </div>
        </div>
        {theWaitlist.length > 0 ? (
          <ReservationsList
            reservations={theWaitlist}
            page={1}
            setShowDrawer={setShowDrawer}
            setSelectedReservation={setSelectedWaitlist}
            theWaitlist={theWaitlist}
            hideWaitlist={true}
          />
        ) : (
          <div className="flex flex-col">
            <p>Hey, looks like you have no waitlists...</p>
          </div>
        )}
        <button className="mt-3" onClick={() => navigate('/reserve')}>
          Reserve
        </button>
      </div>
      <TabBarNavigator
        tabs={[
          { name: 'Sessions', Icon: PlanIcon, route: '/your-sessions' },
          { name: 'Help', Icon: HelpIcon, route: '/help' },
        ]}
        paddingHorizontal={80}
      />
      <SlideUpDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        action={withdrawFromWaitlist}
        actionText={'Withdraw from waitlist'}
        actionClass={'redButton'}
        message={
          'By withdrawing from the selected waitlist, you will not be notified of any availability for this time slot.'
        }
      />
    </div>
  );
};

export default Waitlist;
