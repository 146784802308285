import React, { useState, useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import renderToastError from '../renderFunctions/renderToastError';
import BackButton from '../components/BackButton';
import Api from '../components/Api';
import CircularProgressBar from '../components/CircularProgressBar';
import UpdateCardForm from '../components/UpdateCardForm';
import { useCustomer } from '../contexts/CustomerContext';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';

const BillingDetails = () => {
  const { customer, getCustomer, customerLoading } = useCustomer();
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [editViewOn, setEditView] = useState(false);
  const [activeTab, setActiveTab] = useState('address');
  const [updateDetails, setUpdateDetails] = useState({
    address_1: null,
    address_2: null,
    city: null,
    company: null,
    country: null,
    postcode: null,
    state: null,
  });

  const handleInputValue = (e, type) => {
    setUpdateDetails({
      ...updateDetails,
      [type]: e.target.value,
    });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const toggleEditView = () => {
    setEditView(!editViewOn);
    if (editViewOn) {
      setUpdateDetails({
        address_1: null,
        address_2: null,
        city: null,
        company: null,
        country: null,
        postcode: null,
        state: null,
      });
    } else {
      setUpdateDetails({
        address_1: customer.address_1,
        address_2: customer.address_2,
        city: customer.city,
        company: customer.company,
        country: customer.country,
        postcode: customer.postcode,
        state: customer.state,
      });
    }
  };

  const updateBillingDetails = async () => {
    setUpdateLoading(true);
    let formData = new FormData();

    Object.entries(updateDetails).forEach(([key, value]) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });

    let response = await Api(`/customer/details`, 'PUT', formData, null, '/v1', false, true);

    if (response.status === 200) {
      let result = response.data;
      if (result.success) {
        renderToastSuccess({
          message: 'You successfully updated your profile details',
        });
      }
    } else {
      renderToastError({ message: response.response.data.message });
    }
    setEditView(false);
    setUpdateLoading(false);
  };

  const renderUpdate = () => (
    <div className="flex flex-col overflow-scroll max-h-[65vh]">
      <div className="tabs flex pb-3 mt-3 px-6">
        <button
          className={`tab w-full focus:outline-none ${
            activeTab === 'address' ? 'text-white' : 'bg-gray-800 text-gray-300'
          }`}
          onClick={() => handleTabChange('address')}
        >
          Address
        </button>
        <button
          className={`tab w-full focus:outline-none ${
            activeTab === 'cardDetails' ? 'text-white' : 'bg-gray-800 text-gray-300'
          }`}
          onClick={() => handleTabChange('cardDetails')}
        >
          Card Details
        </button>
      </div>
      {activeTab === 'address' && (
        <div>
          {['address_1', 'address_2', 'city', 'postcode', 'country', 'company'].map((field) => (
            <div key={field} className="acc-row flex flex-col px-6 py-5">
              <p className="text-sm">{field.replace('_', ' ').toUpperCase()}</p>
              <input type="text" value={updateDetails[field]} onChange={(e) => handleInputValue(e, field)} />
            </div>
          ))}
        </div>
      )}
      {activeTab === 'cardDetails' && (
        <div className="acc-row flex flex-col px-6 py-5">
          <UpdateCardForm onSuccess={() => setEditView(false)} onFailure={() => setEditView(false)} />
        </div>
      )}
    </div>
  );

  const renderView = () => (
    <div className="flex flex-col">
      <div className="acc-row flex flex-col px-6 py-5">
        <p className="text-sm">Address</p>
        <h6 className="text-base">{`${customer.address_1}, ${
          customer.address_2 !== '' ? customer.address_2 + ',' : ''
        } ${customer.city}, ${customer.state},  ${customer.postcode}, ${customer.country}`}</h6>
      </div>
      {customer.company && (
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">Company</p>
          <h6 className="text-base">{customer.company}</h6>
        </div>
      )}
      <div className="acc-row flex flex-col px-6 py-5">
        <p className="text-sm">Saved Credit Card</p>
        <h6 className="text-base">{customer.ccLast4 ? `xxxx xxxx xxxx ${customer.ccLast4}` : 'xxxx xxxx xxxx xxxx'}</h6>
      </div>
    </div>
  );

  useEffect(() => {
    getCustomer();
    setLoading(false);
  }, [getCustomer]);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row items-center mb-6 mx-6">
        <BackButton />
        <h6 className="">Billing details</h6>
      </div>
      {loading || customerLoading ? (
        <CircularProgressBar />
      ) : customer ? (
        editViewOn ? (
          renderUpdate()
        ) : (
          renderView()
        )
      ) : (
        <div className="px-5">
          <p>Sorry, there was an issue retrieving your billing details, please try again.</p>
        </div>
      )}
      {customer &&
        (editViewOn && activeTab === 'address' ? ( // Only show Save/Cancel for address tab
          <div className="flex flex-col fixed bottom-14 left-0 right-0 z-10 px-6">
            <button className="mb-2" onClick={() => !updateLoading && updateBillingDetails()}>
              Save details
            </button>
            <button className="cancel" onClick={() => !updateLoading && toggleEditView()}>
              Cancel
            </button>
          </div>
        ) : editViewOn && activeTab === 'cardDetails' ? (
          <div className="flex flex-col fixed bottom-14 left-0 right-0 z-10 px-6">
            <button className="cancel" onClick={() => !updateLoading && toggleEditView()}>
              Cancel
            </button>
          </div>
        ) : (
          <div className="fixed bottom-14 left-0 right-0 z-10 px-6">
            <button className="w-full" onClick={toggleEditView}>
              Update details
            </button>
          </div>
        ))}
      {updateLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 flex justify-center items-center z-50">
          <CircularProgressBar />
        </div>
      )}
    </div>
  );
};

export default BillingDetails;
