export function detectIfInside(customerLat, customerLng, siteLocation, exit = false) {
  if (!customerLat || !customerLng || !siteLocation) {
    return false;
  }
  // Extracting points
  var bottomLeft = exit ? siteLocation.exit.bottomLeft : siteLocation.entry.bottomLeft;
  var bottomRight = exit ? siteLocation.exit.bottomRight : siteLocation.entry.bottomRight;
  var topLeft = exit ? siteLocation.exit.topLeft : siteLocation.entry.topLeft;
  var topRight = exit ? siteLocation.exit.topRight : siteLocation.entry.topRight;

  // Check if the point is within the range of latitudes and longitudes
  var minX = Math.min(bottomLeft.lng, bottomRight.lng, topLeft.lng, topRight.lng);
  var maxX = Math.max(bottomLeft.lng, bottomRight.lng, topLeft.lng, topRight.lng);
  var minY = Math.min(bottomLeft.lat, bottomRight.lat, topLeft.lat, topRight.lat);
  var maxY = Math.max(bottomLeft.lat, bottomRight.lat, topLeft.lat, topRight.lat);

  // Check if the point lies within the rectangle
  if (
    Number(customerLng) >= minX &&
    Number(customerLng) <= maxX &&
    Number(customerLat) >= minY &&
    Number(customerLat) <= maxY
  ) {
    return true;
  } else {
    return false;
  }
}

export function detectIfOutside(customerLat, customerLng, siteLocation) {
  // Extracting points
  var bottomLeft = siteLocation.exit.bottomLeft;
  var bottomRight = siteLocation.exit.bottomRight;
  var topLeft = siteLocation.exit.topLeft;
  var topRight = siteLocation.exit.topRight;

  // Check if the point is within the range of latitudes and longitudes
  var minX = Math.min(bottomLeft.lng, bottomRight.lng, topLeft.lng, topRight.lng);
  var maxX = Math.max(bottomLeft.lng, bottomRight.lng, topLeft.lng, topRight.lng);
  var minY = Math.min(bottomLeft.lat, bottomRight.lat, topLeft.lat, topRight.lat);
  var maxY = Math.max(bottomLeft.lat, bottomRight.lat, topLeft.lat, topRight.lat);

  // Check if the point lies within the rectangle
  if (
    Number(customerLng) >= minX &&
    Number(customerLng) <= maxX &&
    Number(customerLat) >= minY &&
    Number(customerLat) <= maxY
  ) {
    return false;
  } else {
    return true;
  }
}
