import React from 'react';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import callHelp from '../images/callHelp.png';
import emailHelp from '../images/emailHelp.png';
import BackButton from '../components/BackButton';

const HelpOption = ({ image, title, description, handleClick }) => (
  <div className="bg-xtraNavy rounded-lg p-4 flex flex-col mb-4">
    <img src={image} className="w-full h-24 rounded-lg mb-2.5" alt={'help option'} />
    <h6>{title}</h6>
    <p className="mt-1 text-sm">{description}</p>
    <button className="mt-4" onClick={handleClick}>
      Continue
    </button>
  </div>
);

function doesParamExist(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(paramName);
}

const InStoreHelp = () => {
  const goBack = doesParamExist('navigate');

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-cover bg-center p-6 overflow-y-auto pb-9"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row justify-start items-center mb-5">
        {goBack && <BackButton />}
        <h6>We're here to help</h6>

        {/* <AccountIcon yourDetails={yourDetails} handleClick={() => navigate('/you')} noPhoto={false} /> */}
      </div>
      <div className="flex flex-col overflow-scroll max-h-[75vh]">
        {/* <HelpOption
          image={liveChatHelp}
          title="Live chat with us"
          description="We’re online and ready to help with any question, just hit the button below."
          handleClick={() => {
            //TODO: Open Zendesk livechat
          }}
        /> */}
        <HelpOption
          image={callHelp}
          title="Call our help hotline"
          description="We’re available to help at any time. Click the button below to get in touch with us."
          handleClick={() => {
            window.open(`tel://${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}`, '_blank');
          }}
        />
        <HelpOption
          image={emailHelp}
          title="Email us"
          description="Any feedback or enquiries please send us an email."
          handleClick={() => {
            window.open(`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`, '_blank');
          }}
        />
      </div>
      {/* <TabBarNavigator
        tabs={[
          {
            name: sessionId ? 'Session' : 'Sessions',
            Icon: PlanIcon,
            route: sessionId ? `/session?id=${sessionId}` : isXtra ? '/xtra/sessions' : '/your-sessions',
          },
          { name: 'Help', Icon: HelpIcon, route: '/help' },
        ]}
        paddingHorizontal={80}
      /> */}
    </div>
  );
};

export default InStoreHelp;
