import React, { useState, useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import ErrorPopover from '../components/ErrorPopover';
import moment from 'moment-timezone';
import Api from '../components/Api';
import CircularProgressBar from '../components/CircularProgressBar';

const ScheduleQueue = () => {
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [customerQueue, setCustomerQueue] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCustomerQueue = async (page) => {
    try {
      const queue = await Api(`/schedule/customerqueue/${page}`, 'GET');
      if (queue.status === 200) {
        let result = queue.data;
        if (result.success) {
          if (result.data.queue.length > 0) {
            setCustomerQueue((prevItems) => [...prevItems, ...result.data.queue]);
          }
        }
      } else {
        setError('There was an error completing your reservation.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setLoading(false);
  };

  useEffect(() => {
    getCustomerQueue(page);
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledToBottom =
        window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight;

      if (scrolledToBottom) {
        // Calculate halfway point
        const halfwayPoint = Math.floor((page + 1) / 2);

        // Check if scrolled to halfway point
        if (page >= halfwayPoint) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page]);

  const renderStatus = (status) => {
    var text = null;
    switch (status) {
      case 'Success':
        text = <p className="text-lg font-bold text-green-300	">{status}</p>;
        break;
      case 'Withdrawn':
        text = <p className="text-base font-semibold text-orange-300">{status}</p>;
        break;
      case 'Error':
        text = <p className="text-lg font-semibold text-red-300">{status}</p>;
        break;
      case 'Session Full':
        text = <p className="text-lg font-bold text-rose-300">{status}</p>;
        break;
      default:
        text = <p className="text-base font-semibold">{status}</p>;
        break;
    }
    return <div className="flex mt-5 mb-5 self-center">{text}</div>;
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-top bg-cover justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col p-6">
        <div className="flex flex-col mt-4 mb-4 items-center">
          <h4 className="shrink mb-4">Here are your schedules you have requested to join...</h4>
          {customerQueue.map((schedule, index) => (
            <div className="flex flex-col justify-between items-start w-72 rounded p-4 bg-xtraWhite30 mb-5" key={index}>
              <div className="flex flex-col">
                <p>{schedule.site.name}</p>
                <p>
                  {' '}
                  {`${moment(schedule.date.split('T')[0], 'YYYY-MM-DD').format('ddd, MMM Do')} at ${moment(
                    schedule.date.split('T')[0] + schedule.time,
                    'YYYY-MM-DD H'
                  ).format('h:mm a')}`}
                </p>
              </div>
              {renderStatus(schedule.status)}
            </div>
          ))}
          {loading && (
            <div>
              <CircularProgressBar />
            </div>
          )}
        </div>
      </div>

      <button onClick={() => window.location.reload()}>Reload</button>

      {error && (
        <ErrorPopover
          message={error}
          onClose={() => {
            setError(null);
            getCustomerQueue(1);
          }}
        />
      )}
    </div>
  );
};

export default ScheduleQueue;
