import React, { PureComponent } from 'react';
import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%',
};

class SiteMap extends PureComponent {
  componentDidMount() {
    const map = this.map;

    if (map) {
      map.map.setOptions({
        styles: [
          {
            featureType: 'all',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      });
    }
  }

  render() {
    const { site, customerLocation, exit = false } = this.props;

    const polygonCoords = [
      exit ? site.location.exit.bottomLeft : site.location.entry.bottomLeft,
      exit ? site.location.exit.bottomRight : site.location.entry.bottomRight,
      exit ? site.location.exit.topLeft : site.location.entry.topLeft,
      exit ? site.location.exit.topRight : site.location.entry.topRight,
    ];

    return (
      <Map
        google={this.props.google}
        zoom={19}
        style={mapStyles}
        initialCenter={{
          lat: site.location.lat,
          lng: site.location.lng,
        }}
        draggable={false}
        zoomControl={false}
        scrollwheel={false}
        mapTypeId={'hybrid'}
        disableDefaultUI
        ref={(ref) => {
          this.map = ref;
        }}
      >
        <Polygon
          paths={polygonCoords}
          strokeColor={exit ? '#d53c3d' : '#0000FF'}
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor={exit ? '#d53c3d' : '#0000FF'}
          fillOpacity={0.35}
        />
        <Marker
          position={{ lat: site.location.lat, lng: site.location.lng }}
          icon={{
            url: 'https://cdn.xtraclubs.au/xtraLogoPin.png',
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        />
        <Marker
          position={{
            lat: Number(customerLocation.lat),
            lng: Number(customerLocation.lng),
          }}
          icon={{
            url: 'https://cdn.xtraclubs.au/userMapIcon.png',
            scaledSize: new window.google.maps.Size(30, 30), // Set the size of the marker
          }}
          zIndex={1111}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API,
})(SiteMap);
