import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import renderToastError from '../renderFunctions/renderToastError';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import Api from '../components/Api';
import CircularProgressBar from './CircularProgressBar';
import { useCustomer } from '../contexts/CustomerContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const inputStyle = {
  style: {
    base: {
      color: '#ffffff',
      fontSize: '16px',
      '::placeholder': {
        color: '#bfbfbf',
      },
    },
    invalid: {
      color: '#ff6b6b',
    },
  },
};

const CardForm = ({ onSuccess, onFailure }) => {
  const { getCustomer } = useCustomer();
  const stripe = useStripe();
  const elements = useElements();
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setUpdateLoading(true);

    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      renderToastError({ message: error.message });
      onFailure();
    } else {
      console.log('payment id : ', paymentMethod);
      const response = await Api('/customer/update/payment-method', 'PUT', {
        paymentMethodId: paymentMethod.id,
      });

      console.log('response: ', response.data);

      if (response.data.success) {
        renderToastSuccess({ message: 'Card updated successfully' });
        onSuccess();
      } else {
        renderToastError({ message: 'Failed to update card, please try again.' });
        onFailure();
      }
    }
    getCustomer({ update: true });
    setUpdateLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="mb-4">
          <label htmlFor="cardNumber" className="block text-white mb-1">
            Card number
          </label>
          <div className="w-full p-3 bg-xtraWhite10 rounded-md border border-xtraWhite30 text-white mb-2">
            <CardNumberElement
              id="cardNumber"
              options={inputStyle}
              onChange={(event) => setCardNumberComplete(event.complete)}
            />
          </div>
        </div>
        <div className="flex space-x-4 mb-4">
          <div className="w-full">
            <label htmlFor="cardExpiry" className="block text-white mb-1">
              Card expiry
            </label>
            <div className="w-full p-3 bg-xtraWhite10 rounded-md border border-xtraWhite30 text-white mb-2">
              <CardExpiryElement
                id="cardExpiry"
                options={inputStyle}
                onChange={(event) => setCardExpiryComplete(event.complete)}
              />
            </div>
          </div>
          <div className="w-full">
            <label htmlFor="cardCvv" className="block text-white mb-1">
              Card CVV
            </label>
            <div className="w-full p-3 bg-xtraWhite10 rounded-md border border-xtraWhite30 text-white mb-2">
              <CardCvcElement
                id="cardCvv"
                options={inputStyle}
                onChange={(event) => setCardCvcComplete(event.complete)}
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          disabled={!cardNumberComplete || !cardExpiryComplete || !cardCvcComplete || !stripe}
          className="w-full mt-4 p-3 rounded-md text-white"
        >
          Update Payment Method
        </button>
      </form>
      {updateLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 flex justify-center items-center z-50">
          <CircularProgressBar />
        </div>
      )}
    </>
  );
};

const UpdateCardForm = ({ onSuccess, onFailure }) => (
  <Elements stripe={stripePromise}>
    <CardForm onSuccess={onSuccess} onFailure={onFailure} />
  </Elements>
);

export default UpdateCardForm;
