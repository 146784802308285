import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

export default function SettingsButton({ onClick }) {
  return (
    <div className="flex jusitfy-end">
      <button
        onClick={onClick}
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
    </div>
  );
}
