import notifySwift from '../constants/notifySwift';
import alertIcon from '../images/alert.png';

const RetryPayment = ({ membershipPauseDetails, customerBillingDetails, retryPayment }) => {
  if (membershipPauseDetails) {
    return (
      <div
        className={`bg-xtraNavy rounded flex flex-col mb-3 mx-2 py-3 pr-2 pl-4 items-center justify-center text-center`}
      >
        <img className="w-14 h-auto mb-2" src={alertIcon} alt="alert icon" />
        <h6>Your account is overdue</h6>
        <p className="text-sm mt-1 mb-10">To reserve a session you must make payment.</p>
        <h6>Total Overdue: ${membershipPauseDetails.total}</h6>
        <p className="text-sm mt-1 mb-4">
          We will bill your card ending in {customerBillingDetails.billing_details.ccLast4}.
        </p>
        <button className={`mb-3 text-lg px-7 w-[90%]`} onClick={() => retryPayment()}>
          Retry payment
        </button>
        <button
          className={`blueBorder mb-2 text-lg px-7`}
          onClick={() => {
            let path = '/update-user-payment-method/';
            let token = localStorage.getItem('accessToken');
            let url = `${process.env.REACT_APP_WP_ACCESS_PAGE}`;
            notifySwift({
              function: 'openInternalLink',
              data: {
                url: `${url}?token=${token}&path=${path}`,
              },
            });
          }}
        >
          Update billing details
        </button>
      </div>
    );
  }

  return (
    <div className={`bg-xtraWhite30 rounded flex flex-col mb-3 mx-2 py-3 pr-2 pl-4 reservation-item`}>
      <p>Sorry, an error occured, please try again.</p>
    </div>
  );
};

export default RetryPayment;
