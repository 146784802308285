import Api from '../components/Api';

export const updateCustomerRequirements = ({ type, data, retrieveCustomer }) => {
  // console.log('update type: ', type);
  // console.log('update data: ', data);
  if (type === 'location') {
    handleLocationPermission(data, retrieveCustomer);
  }

  if (type === 'bluetooth') {
    handleBluetoothPermission(data, retrieveCustomer);
  }

  if (type === 'notifications') {
    // console.log('data: ', data);
    handlePushNotifications(data, retrieveCustomer);
  }
};

export const updateRequirement = async (requirement, value, retrieveCustomer) => {
  const response = await Api('/customer/requirement', 'PATCH', { requirement, value });

  if (response.status === 200) {
    if (retrieveCustomer !== null) {
      await retrieveCustomer();
    }
  }
};

const handleLocationPermission = (e, retrieveCustomer) => {
  let enabled = e?.detail?.data?.enabled ?? false;
  // console.log('locationPermissions: ', enabled);
  updateRequirement('locationPermissions', enabled, retrieveCustomer);
};
const handleBluetoothPermission = (e, retrieveCustomer) => {
  console.log('handleBluetoothPermission');
  let enabled = e?.detail?.data?.enabled ?? false;
  updateRequirement('bluetoothPermissions', enabled, retrieveCustomer);
};
const handlePushNotifications = (e, retrieveCustomer) => {
  let enabled = e?.detail?.data?.enabled ?? false;
  updateRequirement('pushNotifications', enabled, retrieveCustomer);
};
