import React, { useState, useEffect, useCallback } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import CircularProgressBar from '../components/CircularProgressBar';
import Api from '../components/Api';
import reservationTypeConstants from '../constants/reservationTypeConstants.json';
import notifySwift from '../constants/notifySwift';
import { useCustomer } from '../contexts/CustomerContext';

const SessionPackOption = ({ sessionDetails }) => {
  return (
    <div className="p-5 flex flex-col rounded-lg bg-xtraWhite20">
      {sessionDetails.products.map((item, index) => {
        const name = item.product_name.toLowerCase();
        const maximum = item.usage?.maximum ?? reservationTypeConstants[name] * item.quantity;
        let usage = item.usage;
        if (usage !== 0) {
          usage = usage.usage;
        }
        const remaining = maximum - usage;
        var mt = 'mt-0';
        if (index > 0) {
          mt = 'mt-5';
        }
        return (
          <div key={sessionDetails.order_id + item.product_id} className={`flex flex-col gap-2 ${mt}`}>
            <div className="flex flex-row justify-between mb-1 items-center">
              <div className="flex flex-row items-center">
                <h6>{item.product_name}</h6>
                <p className="text-sm ml-1 orderId"> - Order #{sessionDetails.order_id}</p>
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <p>Sessions Remaining </p>
              <p>
                <b>{remaining}</b>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CustomerSessionPack = () => {
  const { getCustomer, customerLoading, customerSessionPacksAndGifts } = useCustomer();
  const [loading, setLoading] = useState(true);
  const [sessionPackData, setSessionPackData] = useState([]);

  const getSessionPackUsage = useCallback(async () => {
    if (customerSessionPacksAndGifts) {
      const sessionPackData = await Promise.all(
        customerSessionPacksAndGifts.map(async (sessionPack) => {
          const updatedProducts = await Promise.all(
            sessionPack?.products.map(async (product) => {
              const productUsage = await Api(
                `/customer/sessionpack/usage?order=${sessionPack.order_id}&product=${product.product_id}`,
                'GET'
              );
              if (productUsage.status === 200) {
                var usageCount = productUsage.data.usage;
                if (usageCount == null) {
                  usageCount = 0;
                }
                return { ...product, usage: usageCount };
              }
              return product;
            })
          );
          return { ...sessionPack, products: updatedProducts };
        })
      );
      setSessionPackData(sessionPackData);
      setLoading(false);
    }
  }, [customerSessionPacksAndGifts]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  useEffect(() => {
    getSessionPackUsage();
  }, [getSessionPackUsage]);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <div className="flex flex-row items-center mb-6 mx-6">
          <BackButton />
          <h6>Your Session Packs</h6>
        </div>
        {loading || customerLoading ? (
          <CircularProgressBar />
        ) : (
          <div className="flex flex-col gap-2.5 px-6 overflow-scroll max-h-[70vh]">
            {sessionPackData.map((sessionPack) => (
              <SessionPackOption key={sessionPack.order_id} sessionDetails={sessionPack} />
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col mb-10 px-5">
        <button
          className="rounded-full text-sm"
          onClick={async () => {
            let path = 'https://xtraclubs.au/buy-session-packs/';
            let token = localStorage.getItem('accessToken');
            notifySwift({
              function: 'openInternalLink',
              data: {
                url: `${path}?token=${token}`,
              },
            });
          }}
        >
          {'Purchase a Session Pack'}
        </button>
      </div>
    </div>
  );
};

export default CustomerSessionPack;
