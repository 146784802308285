import Api from '../components/Api';

export const getCustomerDetails = async () => {
  const response = await Api('/customer/details', 'GET');

  if (response.status === 200) {
    const data = response.data;
    if (data.success) {
      return data;
    }
  } else {
    return null;
  }
};
