import React, { useState, useEffect } from 'react';

const SafeAreaHeader = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to update window size
    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener to window resize
    window.addEventListener('resize', updateSize);

    // Call updateSize function once initially
    updateSize();

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', updateSize);
  }, []); // Empty dependency array ensures this effect only runs once

  if (window.Android) {
    return null;
  }

  const style = {
    width: '100%',
    minHeight: windowSize.height > 667 ? '5vh' : '1vh', // 3% of the viewport height
  };

  return <div style={style}></div>;
};

export default SafeAreaHeader;
