import { ThreeCircles } from 'react-loader-spinner';

const CircularProgressBar = ({ width = 60, height = 60, marginTop = 'mt-10', color = '#3daed4' }) => {
  return (
    <div className={`flex flex-column w-['50%'] justify-center align-center ${marginTop}`}>
      <ThreeCircles
        height={width}
        width={height}
        color={color}
        ariaLabel="circle-loading"
        wrapperStyle={{}}
        wrapperClass="circle-wrapper"
        visible={true}
      />
    </div>
  );
};

export default CircularProgressBar;
