import React, { useState, useEffect, useCallback } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import CircularProgressBar from '../components/CircularProgressBar';
import Api from '../components/Api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

const SessionCard = ({ sessionDetails }) => {
  const sessionDate = moment(sessionDetails.schedule.date).format('DD MMMM YYYY');
  const startTime = moment(sessionDetails.startTimeUTC).tz(sessionDetails.site.timezone).format('HH:MMA');
  const endTime = moment(sessionDetails.finishTimeUTC).tz(sessionDetails.site.timezone).format('HH:MMA');
  const duration = sessionDetails.totalDuration;
  const lockerNumber = sessionDetails.lockerNumber;
  return (
    <div className="p-5 flex flex-col rounded-lg bg-xtraBlack30">
      <div key={sessionDetails._id} className={`flex flex-col gap-3`}>
        <div className="flex flex-row justify-between mb-1 items-center">
          <p className="font-bold">Session date</p>
        </div>
        <div className="flex flex-row justify-between mb-1 items-center bg-xtraWhite10 p-3 rounded">
          <p className="">{sessionDate}</p>
        </div>
        <div className="flex flex-row w-full mb-1 items-center gap-3">
          <div className="flex flex-col w-[50%]">
            <p className="font-bold mb-3">Start Time</p>
            <div className="flex flex-row justify-between mb-1 items-center bg-xtraWhite10 p-3 rounded">
              <p className="">{startTime}</p>
            </div>
          </div>
          <div className="flex flex-col w-[50%]">
            <p className="font-bold mb-3">End Time</p>
            <div className="flex flex-row justify-between mb-1 items-center bg-xtraWhite10 p-3 rounded">
              <p className="">{endTime}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full mb-1 items-center gap-3">
          <div className="flex flex-col w-[50%]">
            <p className="font-bold mb-3">Session duration</p>
            <div className="flex flex-row justify-between mb-1 items-center bg-xtraWhite10 p-3 rounded">
              <p className="">{duration} minutes</p>
            </div>
          </div>
          <div className="flex flex-col w-[50%]">
            <p className="font-bold mb-3">Locker #</p>
            <div className="flex flex-row justify-between mb-1 items-center bg-xtraWhite10 p-3 rounded">
              <p className="">{lockerNumber === null ? 'N/A' : lockerNumber}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerPastSessions = () => {
  const [loading, setLoading] = useState(true);
  const [sessionData, setSessionData] = useState([]);
  const [page, setPage] = useState(1); // Track current page
  const [hasMore, setHasMore] = useState(true); // Track if there are more results

  const navigate = useNavigate();

  const getSessionPackUsage = useCallback(async (page) => {
    setLoading(true);
    const productUsage = await Api(`/customer/past/sessions?page=${page}`, 'GET');

    if (productUsage.status === 200) {
      console.log('PAGE: ', page);
      console.log('productUsage: ', productUsage);
      const customerSessions = productUsage.data.customerSessions;
      setSessionData((prevSessions) => [...prevSessions, ...customerSessions]);
      setHasMore(customerSessions.length > 0); // Stop if no more results
    }
    setLoading(false);
  }, []);

  // Initial fetch
  useEffect(() => {
    getSessionPackUsage(page);
  }, [page, getSessionPackUsage]);

  // Scroll event listener for infinite scroll
  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight / 2 && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1); // Load next page
      }
    },
    [hasMore, loading]
  );

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <div className="flex flex-row items-center mb-6 mx-6">
          <BackButton />
          <h6>Your Sessions</h6>
        </div>
        {loading && sessionData.length === 0 ? (
          <CircularProgressBar />
        ) : (
          <div
            className="flex flex-col gap-2.5 px-6 overflow-scroll max-h-[70vh]"
            onScroll={handleScroll} // Attach scroll event
          >
            {sessionData.map((session) => (
              <SessionCard key={session._id} sessionDetails={session} />
            ))}
            {sessionData.length === 0 && !loading && <p>You have not attended any session yet!</p>}
            {loading && <CircularProgressBar />} {/* Show loader for next page */}
          </div>
        )}
      </div>
      <div className="flex flex-col mb-10 px-5">
        <button
          className="rounded-full text-sm"
          onClick={() => {
            navigate('/reserve', { replace: true });
          }}
        >
          {'Reserve Now'}
        </button>
      </div>
    </div>
  );
};

export default CustomerPastSessions;
