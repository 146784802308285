import React, { useState, useEffect, useCallback } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import Api from '../components/Api';
import renderToastError from '../renderFunctions/renderToastError';
import BackButton from '../components/BackButton';
import CircularProgressBar from '../components/CircularProgressBar';
import notifySwift from '../constants/notifySwift';
import redCrossWhiteBG from '../images/red-cross-white-bg.png';
import SiteMap from '../components/SiteMap';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import { detectIfInside } from '../constants/detectIfInside';

const OpenDoor = ({ type }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { reservationId } = state;

  const [loading, setLoading] = useState(true);
  const [theReservation, setTheReservation] = useState(null);
  const [site, setSite] = useState(null);
  const [canOpen, setCanOpen] = useState(false);

  const openMessage = type === 'gate' ? 'Open Gate' : 'Open Door';

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [customerLocation, setCustomerLocation] = useState({
    lat: null,
    lng: null,
  });

  const getReservation = useCallback(async () => {
    if (reservationId) {
      try {
        const reservation = await Api(`/schedule/${reservationId}/`, 'GET');
        if (reservation.status === 200) {
          let result = reservation.data;
          if (result.success) {
            if (result.reservation !== null) {
              setTheReservation(result.reservation);
              setSite(result.site);
            }
          }
        } else {
          renderToastError('Could not determine your location to start your session, please try again.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }, [reservationId]);

  const startSession = async () => {
    try {
      const session = await Api(`/session`, 'POST', { reservationId: theReservation._id });
      if (session.status === 200) {
        let result = session.data;
        if (result.success) {
          if (result.session !== null) {
            console.log('session: ', result.session);
            navigate(`/session/?id=${result.session._id}`);
            renderToastSuccess({
              message: 'Door is now open, session is now started.',
            });
          } else {
            renderToastError('Could not start your session, please try again, or contact us for assistance.');
          }
        }
      } else {
        renderToastError('Could not determine your location to start your session, please try again.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const openDoor = async () => {
    setLoading(true);
    const response = await Api(`/kisi/locks/${site.doors.FrontDoor}/unlock`, 'GET');
    if (response.status === 200 && response.data.message === 'Unlocked!') {
      await startSession();
    } else {
      renderToastError({
        message: 'Failed to open door. Please try again or contact us for assistance',
      });
    }
    setLoading(false);
  };

  const handleLocationDetails = useCallback(
    async (data) => {
      let locationData = data.detail.data;
      if (locationData.latitude !== customerLocation.lat && locationData.longitude !== customerLocation.lng) {
        setCustomerLocation({
          lat: locationData.latitude,
          lng: locationData.longitude,
        });

        if (site) {
          let isCustomerInside = detectIfInside(locationData.latitude, locationData.longitude, site.location);
          setCanOpen(isCustomerInside);
          setLoading(false);
        }
      }
    },
    [site, customerLocation]
  );

  useEffect(() => {
    getReservation();
  }, [getReservation]);

  useEffect(() => {
    const handleLocationPermissions = (e) => {
      let enabled = e?.detail?.data?.enabled ?? false;

      if (!enabled) {
        renderToastError({
          message: 'Location permissions must be enabled on your device before opening the door.',
          onClose: () => {
            navigate(-1);
          },
        });
      }
    };

    window.addEventListener('LocationPermissions', (e) => handleLocationPermissions(e));
    notifySwift({
      function: 'startTrackingLocation',
    });

    return () => {
      window.removeEventListener('LocationPermissions', (e) => handleLocationPermissions(e));
      notifySwift({
        function: 'stopTrackingLocation',
      });
    };
  }, [navigate]);

  useEffect(() => {
    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  useEffect(() => {
    if (site) {
      window.addEventListener('UserLocationUpdated', handleLocationDetails);

      return () => {
        window.removeEventListener('UserLocationUpdated', handleLocationDetails);
      };
    }
  }, [site, handleLocationDetails]);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-top bg-cover justify-start"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      {loading ? (
        <div className="flex flex-col justify-start p-6">
          <div
            className="flex flex-row justify-start items-center mb-4"
            style={{ paddingTop: windowSize.height > 667 ? '3vh' : '1vh' }}
          >
            <BackButton />
            <h3 className="font-recoleta">{openMessage}</h3>
          </div>
          <CircularProgressBar />
        </div>
      ) : (
        <div className="flex flex-col">
          <SiteMap site={site} customerLocation={customerLocation} />
          <div
            className={`absolute top-0 left-0 w-full bg-gradient-to-b from-xtraNavy to-trasnparent min-h-[12vh] p-6`}
            style={{ paddingTop: windowSize.height > 667 ? '5vh' : '1vh' }}
          >
            <div className="flex flex-row justify-start items-center mb-4 z-10 mt-1">
              <BackButton />
              <h3 className="font-recoleta">{openMessage}</h3>
            </div>
          </div>
          <div
            className={`absolute bottom-0 left-0 w-full p-6`}
            style={{ paddingTop: windowSize.height > 667 ? '5vh' : '1vh' }}
          >
            <div className="flex flex-col justify-center items-center mb-4 z-10 mt-1">
              {!canOpen && (
                <div className="flex flex-row bg-xtraError p-1 rounded items-center mb-2">
                  <img src={redCrossWhiteBG} className="w-4 h-4 mr-2" alt="red cross" />
                  <p className="font-semibold text-xs">Too far to open {type ?? 'door'}</p>
                </div>
              )}
              <button
                className="w-[90%] p-4 text-xl"
                disabled={!canOpen ? true : false}
                style={
                  !canOpen
                    ? {
                        opacity: 0.65,
                      }
                    : {}
                }
                onClick={openDoor}
              >
                {openMessage}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenDoor;
