import React from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import { useNavigate } from 'react-router-dom';
import xtraTick from '../images/xtra-tick.png';

const TapSuccess = ({
  title = "You're in!",
  message = 'Next time, no need to press start session - just tap your phone at the readers to open the door, gate and start your session!',
  buttonText = 'Continue',
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-top bg-cover justify-start"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="w-full flex-1 bg-xtraAqua80 flex flex-col justify-between px-7 py-24 items-center">
        <div className="flex flex-col items-center gap-1">
          <img className="w-8 h-8 object-fit mr-1" src={xtraTick} alt="xtra tick" />
          <p className="text-center text-2xl font-bold">{title}</p>
        </div>
        <p className="text-center text-3xl leading-normal font-bold">{message}</p>
        <button className="w-full py-4 text-xl bg-xtraAqua opacity-100" onClick={() => navigate('/splash')}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default TapSuccess;
