import React, { useEffect, useState } from 'react';
import TabBarNavigator from '../../components/TabBarNavigator';
import xtraBG from '../../images/xtra-bg.jpg';
import XtraReservationsList from '../../components/XTRA/ReservationsList';
import { useNavigate } from 'react-router-dom';
import SafeAreaHeader from '../../components/SafeAreaHeader';
import CircularProgressBar from '../../components/CircularProgressBar';
import { ReactComponent as PlanIcon } from '../../assets/tabBar/Plan.svg';
import moment from 'moment-timezone';
import logout from '../../images/logout.png';

const XtraSessions = () => {
  const navigate = useNavigate();

  const [customerQueue, setCustomerQueue] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCustomerQueue = () => {
    const currentDate = moment.tz(moment(), moment.tz.guess());
    const currentHour = currentDate.hours();
    const currentMinute = currentDate.minutes();

    // Calculate the start time for the first record
    let startTimeFirstRecord;
    if (currentMinute < 25) {
      startTimeFirstRecord = currentHour;
    } else {
      startTimeFirstRecord = currentHour + 0.5;
    }

    // Set the first record
    const firstRecord = {
      site: '65c468e98af2a3e02af945ae',
      date: currentDate.format(),
      time: startTimeFirstRecord,
    };

    // Calculate the start time for the second record (30 minutes from the current hour)
    const startTimeSecondRecord = startTimeFirstRecord + 0.5;

    // Set the second record
    const secondRecord = {
      site: '65c468e98af2a3e02af945ae',
      date: currentDate.format(),
      time: startTimeSecondRecord,
    };

    // Calculate the start time for the third record (1 hour from the start time of the second record)
    const startTimeThirdRecord = startTimeSecondRecord + 0.5;

    // Set the third record
    const thirdRecord = {
      site: '65c468e98af2a3e02af945ae',
      date: currentDate.format(),
      time: startTimeThirdRecord,
    };

    // Push all records into an array
    const records = [];
    records.push(firstRecord);
    records.push(secondRecord);
    records.push(thirdRecord);
    setCustomerQueue(records);
    setLoading(false);
  };

  useEffect(() => {
    getCustomerQueue();
  }, []);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center mb-4">
          <h3 className="font-recoleta">Your sessions</h3>
          <img src={logout} className="w-5 h-5 object-fit" alt="logout" onClick={() => navigate('/logout')} />
        </div>
        {loading ? (
          <div>
            <CircularProgressBar />
          </div>
        ) : customerQueue.length > 0 ? (
          <XtraReservationsList reservations={customerQueue} />
        ) : (
          <div className="flex flex-col">
            <p>Hey, looks like you have nothing scheduled...</p>
          </div>
        )}
      </div>
      <TabBarNavigator tabs={[{ name: 'Sessions', Icon: PlanIcon, route: '/xtra/sessions' }]} paddingHorizontal={80} />
    </div>
  );
};

export default XtraSessions;
