import React from 'react';
import TabBarNavigator from '../components/TabBarNavigator';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import notifySwift from '../constants/notifySwift';

const Doors = () => {
  const bluetoothPermissions = () => {
    notifySwift({
      function: 'bluetoothPermissions',
      data: {},
    });
  };

  const locationPermissions = () => {
    notifySwift({
      function: 'locationPermissions',
      data: {},
    });
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <h3 className="mb-4">Doors</h3>
      <button className="mt-4" onClick={bluetoothPermissions}>
        Bluetooth Permission
      </button>
      <button className="mt-4" onClick={locationPermissions}>
        Location Permission
      </button>
      <div className="bg-xtraNavy rounded p-4 flex flex-col">
        <h5>Arrived?</h5>
        <p className="mt-2">Hit the button below when you’re within 5 metres of the door</p>
        <button className="mt-4">Open door</button>
      </div>
      <div className="bg-xtraNavy rounded p-4 flex flex-col mt-2">
        <h5>Need a towel?</h5>
        <p className="mt-2">Hit the button below when you’re within a metre of the towel cabinet</p>
        <button className="mt-4">Open towel cabinet</button>
      </div>
      <TabBarNavigator />
    </div>
  );
};

export default Doors;
