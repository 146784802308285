import React, { useState, useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import CircularProgressBar from '../components/CircularProgressBar';
import renderToastError from '../renderFunctions/renderToastError';
import BackButton from '../components/BackButton';
import Api from '../components/Api';
import { Bounce, toast } from 'react-toastify';
import notifySwift from '../constants/notifySwift';
import redCrossWhiteBG from '../images/red-cross-white-bg.png';
import isCasual from '../constants/isCasual';
import { useCustomer } from '../contexts/CustomerContext';

const YourDetails = () => {
  const { customer, getCustomer, customerLoading } = useCustomer();
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [editViewOn, setEditView] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureLoading, setProfilePictureLoading] = useState(false);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  // const handleEmailChange = (e) => {
  //   setEmail(e.target.value);
  // };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleProfilePictureChange = (e) => {
    var input = null;
    if (e === 'camera') {
      // input.capture = 'user';
      notifySwift({
        function: 'presentCamera',
      });
    } else {
      input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
    }

    if (input) {
      input.click();
      const handleChange = (event) => {
        const selectedFile = event.target.files[0];
        console.log('Selected file:', selectedFile);
        setProfilePicture(selectedFile);

        // Remove the event listener after the image is selected
        input.removeEventListener('change', handleChange);
      };

      input.addEventListener('change', handleChange);
    }
  };

  const toggleEditView = () => {
    setEditView(!editViewOn);
    if (editViewOn) {
      setFirstName(null);
      setLastName(null);
      setEmail(null);
      setPhone(null);
      setProfilePicture(null);
    } else {
      setFirstName(customer.first_name);
      setLastName(customer.last_name);
      setEmail(customer.email);
      setPhone(customer.phone);
    }
  };

  const updateUserDetails = async () => {
    setUpdateLoading(true);
    let formData = new FormData();
    if (customer.first_name !== firstName) {
      formData.append('first_name', firstName);
    }

    if (customer.last_name !== lastName) {
      formData.append('last_name', lastName);
    }

    if (customer.email !== email) {
      formData.append('email', email);
    }

    if (customer.phone !== phone) {
      formData.append('phone', phone);
    }

    if (profilePicture !== null) {
      formData.append('profile_picture', profilePicture);
    }

    let response = await Api(`/customer/details`, 'PUT', formData, null, '/v1', false, true);

    if (response.status === 200) {
      let result = response.data;
      if (result?.success) {
        toast.success('You successfully updated your profile details', {
          position: 'top-center',
          style: { top: '5vh', marginLeft: 20, marginRight: 20 },
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        });
      }
    } else {
      renderToastError({
        message: response.data.message,
      });
    }
    getCustomer({ update: true });
    setEditView(false);
    setUpdateLoading(false);
  };

  const renderDetails = () => {
    return (
      <div className="rounded flex flex-col">
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">Your name</p>
          <h6 className="text-base">{`${customer.first_name ? customer.first_name : ''} ${
            customer.last_name ? customer.last_name : ''
          }`}</h6>
        </div>
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">Your email</p>
          <h6 className="text-base">{customer.email ? customer.email : 'No Email Set'}</h6>
        </div>
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">Phone</p>
          <h6 className="text-base">{customer.phone ? customer.phone : 'No Mobile Set'}</h6>
        </div>
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">Profile Picture</p>
          <img src={customer.profile_photo} alt="customer" className="mt-3 rounded-full w-28 h-28 object-cover" />
        </div>
      </div>
    );
  };

  const renderUpdate = () => {
    return (
      <div className="roundedflex flex-col overflow-scroll max-h-[65vh]">
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">First Name</p>
          <input type="text" value={firstName} onChange={handleFirstNameChange} />
        </div>
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">Last Name</p>
          <input type="text" value={lastName} onChange={handleLastNameChange} />
        </div>
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">Email</p>
          <h6 className="text-base">{customer.email ? customer.email : 'No Email Set'}</h6>
          <p className="text-sm mt-2">Please contact us to update your email.</p>
        </div>
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">Phone</p>
          <input type="tel" value={phone} onChange={handlePhoneChange} />
        </div>

        {!isCasual && (
          <div className="acc-row flex flex-col px-6 py-5">
            <p className="text-sm">Profile Picture</p>

            {profilePictureLoading ? (
              <div>
                <CircularProgressBar width={30} height={30} />
              </div>
            ) : profilePicture !== null ? (
              <div className="relative w-fit">
                <img
                  src={URL.createObjectURL(profilePicture)}
                  alt="customer"
                  className="mt-3 rounded-full w-28 h-28 object-cover"
                />
                <img
                  onClick={() => {
                    setProfilePicture(null);
                  }}
                  src={redCrossWhiteBG}
                  alt="close"
                  className="absolute top-0 right-0 w-7 h-7 object-fit"
                />
              </div>
            ) : (
              <div className="flex flex-col">
                <button
                  className="cancel my-2"
                  onClick={() => handleProfilePictureChange(window.Android ? 'library' : 'camera')}
                >
                  {window.Android ? 'Pick photo' : 'Take photo'}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    getCustomer();
    setLoading(false);
  }, [getCustomer]);

  useEffect(() => {
    window.addEventListener('CapturedPhoto', (e) => {
      setProfilePictureLoading(true);
      let photo = e.detail.data.photo;
      photo = base64ToBlob(photo);
      setProfilePicture(photo);
      setTimeout(() => {
        setProfilePictureLoading(false);
      }, 1000);
    });

    return () => {
      window.removeEventListener('CapturedPhoto', (e) => console.log('photo: ', e));
    };
  }, []);

  const base64ToBlob = (base64String, contentType = 'image/jpeg') => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />

      <div className="flex flex-row items-center mb-6 px-6">
        <BackButton />
        <h6>Account details</h6>
      </div>
      {loading || customerLoading ? (
        <CircularProgressBar />
      ) : customer ? (
        editViewOn ? (
          renderUpdate()
        ) : (
          renderDetails()
        )
      ) : (
        <div className="px-5">
          <p>Sorry, there was an issue retrieving your details, please try again.</p>
        </div>
      )}
      {customer &&
        (editViewOn ? (
          <div className="flex flex-col fixed bottom-14 left-0 right-0 z-10 px-6">
            <button
              className="mb-2"
              onClick={() => {
                if (!updateLoading) {
                  updateUserDetails();
                }
              }}
            >
              Save details
            </button>
            <button
              className="cancel"
              onClick={() => {
                if (!updateLoading) {
                  toggleEditView();
                }
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="fixed bottom-14 left-0 right-0 z-10 px-6">
            <button className="w-full" onClick={toggleEditView}>
              Update details
            </button>
          </div>
        ))}
      {updateLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 flex justify-center items-center z-50">
          <CircularProgressBar />
        </div>
      )}
    </div>
  );
};

export default YourDetails;
