import React from 'react';
import orangeWarning from '../images/orangeWarningMark.png';
import close from '../images/close.png';

function OverstayPopup({ overstays, handleEndSession, showOverstayPopup, setShowOverstayPopup }) {
  const handleClose = () => {
    setShowOverstayPopup(false);
  };

  let overstayMessage = '';
  let maxStrikesReached = false;

  if (3 - overstays <= 0) {
    maxStrikesReached = true;
    overstayMessage = 'You will be charged for your overstay!';
  } else {
    overstayMessage = `${3 - overstays} strikes left`;
  }

  if (showOverstayPopup) {
    return (
      <>
        <div className={`drawer-overlay ${showOverstayPopup ? 'visible' : ''}`} onClick={handleClose} />
        <div className={`drawer ${showOverstayPopup ? 'drawer-open' : 'drawer-closed'} pt-10 mb-[15vh]`}>
          <div className="drawer-content">
            <div className="flex flex-col pb-3">
              <img
                onClick={handleClose}
                src={close}
                className=" absolute top-[20px] right-[20px] w-6 h-6 object-fit"
                alt="close"
              />
              <h3 className="mb-5 font-recoleta">Overstay charges</h3>
              <div>
                <ul className="text-white list-disc ml-5 leading-7">
                  <li>Fees are capped at $5 per session</li>
                  <li>There is a 5 minute grace period</li>
                  <li>Your overstay fee is calculated once your session is ended</li>
                  <li>There is a 3 strike system: you will only be charged for your 3rd offense and beyond</li>
                  <li>Your session will automatically end 45 minutes after your included session duration</li>
                </ul>
              </div>
              <div className="flex flex-col mt-8">
                {maxStrikesReached && (
                  <p className="font-semibold text-center text-base mb-4">{`Your account has 3/3 strikes.`}</p>
                )}
                <div className="flex flex-row justify-center mb-2">
                  <img src={orangeWarning} alt="warning" className="object-fit w-5 h-5 mr-1" />
                  <p className="font-semibold text-sm">{`${overstayMessage}`}</p>
                </div>
                <button
                  className="redButton"
                  onClick={() => {
                    handleEndSession();
                  }}
                >
                  End session
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default OverstayPopup;
