import React, { useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraLogo from '../images/xtra-clubs-logo.png';
import BackButton from '../components/BackButton';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/splash');
  }, [navigate]);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-5 pt-10 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <div className="flex flex-row items-start">
          <div className="mt-1">
            <BackButton onClick={() => navigate('/splash')} />
          </div>
          <img className="w-52 h-auto" src={xtraLogo} alt="xtra" />
        </div>
        <p className="mt-5">Sorry, the screen you have navigated to cannot be found.</p>
      </div>
    </div>
  );
};

export default NotFound;
