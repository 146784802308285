import React from 'react';
import { components } from 'react-select';

const CustomOption = ({ children, ...props }) => {
  // Extracting the <p> tag with class 'optionHeading'
  var optionHeading = '';
  var orderId = '';
  React.Children.toArray(children).find((child) => {
    return React.Children.toArray(child.props.children).find((secondChild) => {
      if (secondChild.props.children.length === 2) {
        if (typeof secondChild.props.children[1] == 'object') {
          orderId = secondChild.props.children[1].props.children.toString();
          orderId = orderId.replace(',', '');
          orderId = orderId.replace('-', '');
        }
      }
      return React.Children.toArray(secondChild.props.children).find((thirdChild) => {
        if (thirdChild.props.className && thirdChild.props.className.includes('optionHeading')) {
          optionHeading = `${thirdChild.props.children[0]} <span style="font-size: 12px; margin-left:5px">${orderId}</span>`;
        }
        return optionHeading;
      });
    });
  });

  return (
    <components.Option {...props}>
      <span className="text-white" dangerouslySetInnerHTML={{ __html: optionHeading }}></span>
    </components.Option>
  );
};

export default CustomOption;
