import SettingsButton from '../SettingsButton';
import xtraTick from '../../images/xtra-tick.png';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import Api from '../Api';
import renderToastError from '../../renderFunctions/renderToastError';

const XtraReservationItem = ({
  reservation,
  toggleSettingsMenu,
  isInBufferRange = false,
  formatTimeSchedule,
  hideStatus = false,
}) => {
  const navigate = useNavigate();

  const startSession = async () => {
    let date = moment().format();
    const response = await Api('/session/xtra/start', 'POST', { site: reservation.site });
    if (response.status === 200) {
      navigate(`/xtra/session`, {
        state: {
          startTime: date,
          duration: 120,
        },
      });
    } else {
      renderToastError({
        message: 'There was an issue creating your session. Please try again!',
      });
    }
  };

  return (
    <div
      key={reservation._id}
      id={reservation._id}
      className={`bg-xtraWhite30 rounded flex flex-col mb-3 mx-2 py-3 pr-2 pl-4 reservation-item ${
        reservation.slideUp ? 'slide-up' : ''
      }`}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row">
          <p className="text-sm">Arrival between</p>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-base font-bold	p-0 m-0">{formatTimeSchedule(reservation.time)}</p>
        <div className="flex flex-row items-center mt-[-20px]">
          {!hideStatus && (
            <p className="flex items-center text-xs bg-xtraSuccess h-fit text-xtraNavy px-[5px] py-[2px] font-medium mr-2 rounded">
              <img className="w-3 h-3 object-fit mr-1" src={xtraTick} alt="xtra tick" />
              Reserved
            </p>
          )}
          <SettingsButton onClick={() => toggleSettingsMenu(reservation)} />
        </div>
      </div>
      {isInBufferRange && (
        <div className="flex flex-row pr-2">
          <button className="w-full mt-2" title="Start session" onClick={startSession}>
            Start session
          </button>
        </div>
      )}
    </div>
  );
};

export default XtraReservationItem;
