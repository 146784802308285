import moment from 'moment-timezone';

export function formatTimeReservation(time) {
  let date = moment();
  let endDate = moment();
  date.hour(time);
  date.minute('00');
  endDate.hour(time);
  endDate.minute('00');

  date = date.format('hA');
  endDate = endDate.add(1, 'hour').format('hA');

  return `Arrival between ${date} and ${endDate}`;
}

export function formatTimeSchedule(time) {
  time = String(time);
  time = time.includes('.5') ? `${String(time).replace('.5', ':30')}` : time + ':00';
  let startTime = moment(time, 'H:mm').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('h:mma');
  let endTime = moment(time, 'H:mm')
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .add(30, 'minutes')
    .format('h:mma');

  return `${startTime} to ${endTime}`;
}

export function formatTime(time){
  time = String(time);
  time = time.includes('.5') ? `${String(time).replace('.5', ':30')}` : time + ':00';
  return time;
}
