import reservationTypeConstants from '../constants/reservationTypeConstants.json';

const pushOptionDetails = (details) => {
  const resConstantTypes = reservationTypeConstants;
  let sessionUsages = details.sessionPackU;
  let usage = '';
  let remaining = '';
  let disable = false;
  let quantity = details.quantity;
  var resTypeName = details.name.toLowerCase();
  let maximum = resConstantTypes[resTypeName] * quantity;

  if (sessionUsages) {
    for (const sessionUsage of sessionUsages) {
      if (details.orderId === sessionUsage.orderId && details.productId === sessionUsage.productId) {
        remaining = sessionUsage.maximum - sessionUsage.usage;
        usage = sessionUsage.usage;
        if (sessionUsage.usage >= sessionUsage.maximum || sessionUsage.usage < 0) {
          disable = true;
        }
        break;
      }
    }
  }

  if (quantity === 0) {
    disable = true;
  }

  const optionDetails = {
    orderId: details.orderId || '',
    subscriptionId: details.subscriptionId || '',
    productId: details.productId || '',
    label: details.name || '',
    type: details.valueType || '',
    value: details.orderId
      ? details.name + '|' + details.orderId + '|' + details.productId
      : details.name + '|' + details.productId || '',
    valueName: details.name || '',
    remaining: usage || '',
    sessionsUsed: remaining || '',
    sessionMaximum: maximum || '',
    productQuantity: quantity || '',
  };
  if (!disable) {
    details.resTypeOptions.push(optionDetails);
  }
};

export default pushOptionDetails;
