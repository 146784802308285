import React, { useEffect, useState, useCallback } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import getProduct from '../constants/product';
import CircularProgressBar from '../components/CircularProgressBar';
import purchaseProduct from '../constants/purchaseProduct';
import renderToastError from '../renderFunctions/renderToastError';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import Api from '../components/Api';
import ErrorSlideUpDrawer from '../components/SlideUpDrawer';
import UpdateCardForm from '../components/UpdateCardForm'; // Import UpdateCardForm

const Product = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sku = queryParams.get('sku');
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [site, setSite] = useState(null);
  const [errorSlideUp, setErrorSlideUp] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false); // State to control UpdateCardForm visibility

  const retrieveProduct = useCallback(async () => {
    const product = await getProduct(sku);
    if (product?.success) {
      setProduct(product.data);
    }
  }, [sku]);

  useEffect(() => {
    retrieveProduct();
  }, [retrieveProduct]);

  useEffect(() => {
    const getSessionSite = async () => {
      const siteSession = await Api('/session/active', 'GET');
      if (siteSession.status === 200) {
        let result = siteSession.data;
        setSite(result.site);
        setLoading(false);
      } else {
        renderToastError({
          message: 'Failed to retrieve the site details, navigating back to previous screen. Please try again.',
          onClose: navigate(-1),
        });
      }
    };

    getSessionSite();
  }, [navigate]);

  const handleBuyClick = async () => {
    setPurchaseLoading(true);
    const purchase = await purchaseProduct({ sku: sku, qty: quantity });

    if (purchase?.success) {
      renderToastSuccess({
        message: purchase.message ?? 'Purchase has been confirmed.',
      });
      unlockKISIDoor();
    } else {
      console.log('updatetow : ', purchase.response.data.message);
      let message = purchase?.response?.data?.message ? purchase.response.data.message : purchase.message;

      if (purchase?.response?.data?.response?.decline_code !== 'insufficient_funds') {
        setErrorSlideUp(true);
      }
      renderToastError({
        message: message ?? 'Purchase failed. Please try again.',
      });
      setPurchaseLoading(false);
    }
  };

  const unlockKISIDoor = async (message = '') => {
    const response = await Api(`/kisi/locks/${site.doors.Fridge}/unlock`, 'GET');
    console.log('Unlock door resp: ', response);
    if (response.status === 200) {
      let result = response.data;
      if (result.message) {
        if (result.message !== 'Unlocked!') {
          renderToastError({
            message:
              'There was an issue opening the fridge door, please check there is nothing blocking the door, or the door is not open already.',
          });
        } else {
          renderToastSuccess({
            message: `Fridge door has been unlocked!`,
          });
          navigate('/check-user');
        }
      }
    } else {
      renderToastError({
        message: 'Failed to open fridge door, please try again or contact us.',
      });
    }
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover p-6 overflow-y-auto"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row items-center mb-4">
        <BackButton />
        <h6>Product</h6>
      </div>
      {loading && <CircularProgressBar />}
      {purchaseLoading && (
        <div className="flex flex-col justify-center items-center fixed top-0 left-0 w-full h-full bg-xtraBlack50 z-50">
          <CircularProgressBar />
        </div>
      )}
      {!loading && (
        <>
          {!product ? (
            <p>Product could not be found. Please go back and scan again.</p>
          ) : (
            <>
              <div className="flex flex-col overflow-scroll max-h-[54vh]">
                <img src={product.featured_image_url} className="w-full h-80 mb-2.5 rounded-lg" alt="feature" />
                <div className="px-2.5">
                  <h5 className="mb-0.5">
                    {product.name}
                    <span className="text-sm ml-2.5">${product.price}</span>
                  </h5>
                  <p>{product.description}</p>
                </div>
              </div>
              <div className="fixed bottom-14 left-0 right-0 z-10 px-6">
                <div className="py-3 pl-5 pr-14 flex flex-row rounded-lg gap-1 mt-2.5  text-sm bg-xtraWhite20 px-2.5">
                  <div className="flex justify-center items-center rounded-full bg-xtraOrange text-white w-4 h-4">
                    <FontAwesomeIcon icon={faExclamation} size="xs" />
                  </div>
                  <p className="flex-1 text-sm">Our fridge is under surveillance, only take what you pay for</p>
                </div>
                <div className="flex flex-row justify-between items-center mt-2.5 mb-5 px-2.5">
                  <h2
                    className="flex justify-center items-center w-16 h-16 rounded-full bg-xtraWhite20"
                    onClick={() => setQuantity(quantity - 1 ? quantity - 1 : 1)}
                  >
                    -
                  </h2>
                  <div className="flex flex-col items-center justify-center">
                    <h3>{quantity}</h3>
                    <h5 className="text-xs">Quantity</h5>
                  </div>
                  <h2
                    className="flex justify-center items-center w-16 h-16 rounded-full bg-xtraWhite20"
                    onClick={() => setQuantity(quantity + 1)}
                  >
                    +
                  </h2>
                </div>
                <div className="px-2.5">
                  <button className="w-full" onClick={handleBuyClick}>
                    $
                    {(Number(product.price) * quantity).toLocaleString('en-AU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    - Buy Now
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/* Conditionally render UpdateCardForm */}
      {showCardForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-xtraNavy rounded-lg p-6 w-11/12 max-w-md relative">
            <h2 className="text-xl font-bold text-center mb-2">Update Card Details</h2>
            <p className="text-center text-white mb-6">
              Your current card is not valid. Please update your card details to continue. Once you have updated your
              card, please try again.
            </p>
            <UpdateCardForm onSuccess={() => setShowCardForm(false)} onFailure={() => setShowCardForm(false)} />
            <button
              className="absolute top-2 right-2 text-white hover:text-white"
              onClick={() => setShowCardForm(false)}
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <ErrorSlideUpDrawer
        setShow={setErrorSlideUp}
        show={errorSlideUp}
        action={() => setShowCardForm(true)} // Trigger the form
        actionText="Update Card Details"
        actionHeading="Update Card Details"
        message="Looks like your payment did not go through successfully. Please update your card details to proceed."
        actionRightAway={true}
      />
    </div>
  );
};

export default Product;
