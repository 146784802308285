import React, { useState } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import xtraIcon from '../images/xtra-icon.png';
import SafeAreaHeader from '../components/SafeAreaHeader';
import Api from '../components/Api';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import renderToastError from '../renderFunctions/renderToastError';
import { useNavigate } from 'react-router-dom';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      const response = await Api('/customer/resetpassword', 'POST', {
        email: email,
      });

      if (response.status === 200) {
        renderToastSuccess({
          message: "We've just sent you an email to reset your password.",
          onClose: () => {
            handleGoBack();
          },
        });
      } else {
        renderToastError({
          message: response.data,
        });
      }
      setEmail('');
    } catch (error) {
      renderToastError({
        message: 'Failed to reset password. Please check your email and try again.',
      });
      setEmail('');
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-10 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <img className="w-10 h-auto" src={xtraIcon} alt="xtra" />
        <h2 className="mt-5">Reset Password</h2>

        <div className="flex flex-col justify-between h-[200px]">
          <label className="mt-5">Email</label>
          <input
            name="email"
            type="text"
            placeholder="Enter your email address"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          ></input>
          <button onClick={handleResetPassword}>Reset Password</button>
          <button onClick={handleGoBack}>Back</button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
