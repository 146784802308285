import React from 'react';
import greenTick from '../images/green-tick.png';
import redCross from '../images/red-cross.png';

import CircularProgressBar from '../components/CircularProgressBar';

const StringListWithTicks = ({ items }) => {
  const totalDuration = 5; // Total duration for all items to fade in (in seconds)
  const itemDuration = totalDuration / items.length; // Duration for each item to fade in
  const renderStatusTick = (status) => {
    var tick = null;
    switch (status) {
      case 'Success':
        tick = <img src={greenTick} alt="Tick" className="mr-2 w-5" />;
        break;
      case 'Withdrawn':
        tick = <img src={redCross} alt="Cross" className="mr-2 w-5" />;
        break;
      case 'Error':
        tick = <img src={redCross} alt="Cross" className="mr-2 w-5" />;
        break;
      case 'Session Full':
        tick = <img src={redCross} alt="Cross" className="mr-2 w-5" />;
        break;
      default:
        tick = <CircularProgressBar width={40} height={40} marginTop="" />;
        break;
    }
    return tick;
  };

  return (
    <div>
      {items.map((item, index) => (
        <div
          key={index}
          className="flex flex-row items-center mb-2 fade-in-item"
          style={{ animationDelay: `${index * itemDuration}s` }}
        >
          {renderStatusTick(item.status)}
          <div className="flex flex-col pr-2">
            <p className="text-base ml-2">{item.text}</p>
            {item.status !== 'Success' && item.status !== 'Queued' && (
              <p className="text-sm mt-1 ml-2 rounded py-1 px-4 bg-xtraWhite30 w-fit">{item.status}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StringListWithTicks;
