import React, { useState, useEffect, useMemo } from 'react';
import { formatTimeSchedule } from '../../renderFunctions/formatTime';
import FutureReservationsExist from '../../constants/futureReservationsExist';
import XtraReservationItem from '../XTRA/ReservationItem';
import moment from 'moment-timezone';
import renderToastError from '../../renderFunctions/renderToastError';

const XtraReservationsList = ({ reservations }) => {
  const formatDayOfWeek = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[new Date(date).getDay()];
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const groupedReservations = useMemo(() => {
    const groupedReservations = {};
    reservations.forEach((reservation) => {
      const date = reservation.date.split('T')[0];
      if (!groupedReservations[date]) {
        groupedReservations[date] = [];
      }
      groupedReservations[date].push(reservation);
    });
    return groupedReservations;
  }, [reservations]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to update window size
    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener to window resize
    window.addEventListener('resize', updateSize);

    // Call updateSize function once initially
    updateSize();

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', updateSize);
  }, []); // Empty dependency array ensures this effect only runs once

  const isSlotTimeSameOrAfterNow = (slot) => {
    const hours = Math.floor(slot.time);
    const minutes = (slot.time - hours) * 60;
    const slotTime = moment(slot.date).hours(hours).minutes(minutes).add(30, 'minutes');
    const startTime = moment(slot.date).hours(hours).minutes(minutes).subtract(5, 'minutes');
    const now = moment();
    const endTime = moment(slotTime).add(5, 'minutes');

    return {
      isFuture: slotTime.isSameOrAfter(now),
      isInBufferRange: now.isBetween(startTime, endTime),
    };
  };

  const todayDate = moment().format('YYYY-MM-DD');

  return (
    <div
      className={`flex flex-col overflow-scroll ${windowSize.height > 667 ? 'h-[63vh]' : 'h-[66vh]'}`}
      style={{
        overscrollBehaviorY: 'none',
        scrollBehavior: 'smooth',
      }}
    >
      {Object.entries(groupedReservations).map(([date, reservationsForDate], index) => {
        let futureReservationsExist = FutureReservationsExist(reservationsForDate, date, todayDate);
        const showHeading = futureReservationsExist || reservationsForDate.length === 0;

        return showHeading ? (
          <div key={index}>
            <div
              className={`reservationHeading flex flex-row items-center bg-xtraNavy pl-2 pr-2 pt-1 pb-1 rounded self-start mb-2 w-fit`}
              style={{
                transitionDelay: `${reservationsForDate[0].slideUpDelay}ms`,
              }}
            >
              <h6>{formatDayOfWeek(date)}</h6>
              <p className="text-xs opacity-50 ml-2 mt-0.5">{formatDate(date)}</p>
            </div>
            {reservationsForDate.map((reservation, index) => {
              if (date === todayDate) {
                if (isSlotTimeSameOrAfterNow(reservation).isFuture) {
                  return (
                    <XtraReservationItem
                      key={reservation._id}
                      reservation={reservation}
                      toggleSettingsMenu={() => {
                        renderToastError({
                          message: 'Cannot update this reservation.',
                        });
                      }}
                      isInBufferRange={isSlotTimeSameOrAfterNow(reservation).isInBufferRange}
                      formatTimeSchedule={formatTimeSchedule}
                      hideStatus={true}
                    />
                  );
                } else {
                  return null; // Don't render past reservations for today's date
                }
              } else {
                return (
                  <XtraReservationItem
                    key={reservation._id}
                    reservation={reservation}
                    toggleSettingsMenu={{
                      message: 'Cannot update this reservation.',
                    }}
                    formatTimeSchedule={formatTimeSchedule}
                    hideStatus={true}
                  />
                );
              }
            })}
          </div>
        ) : null;
      })}
    </div>
  );
};

export default XtraReservationsList;
