import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import notificationTypes from '../constants/notificationTypes.json';

const NotificationHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleReceivedNotification = (event) => {
      const { notification } = event.detail.data;
      if (notificationTypes[notification.title] === 'reloadPage') {
        navigate('/splash');
      }
    };

    const handleTappedNotification = (event) => {
      const { notification } = event.detail.data;
      console.log('handleTappedNotification', notification);
      if (
        notification.page &&
        (!location.pathname.startsWith('/session') || notification.page.startsWith('/session'))
      ) {
        navigate(notification.page, { state: { notification } });
      }
    };

    window.addEventListener('ReceivedRemoteNotification', handleReceivedNotification);
    window.addEventListener('TappedRemoteNotification', handleTappedNotification);

    return () => {
      window.removeEventListener('ReceivedRemoteNotification', handleReceivedNotification);
      window.removeEventListener('TappedRemoteNotification', handleTappedNotification);
    };
  }, [navigate, location.pathname]);

  return null;
};

export default NotificationHandler;
