import React from 'react';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import xtraIcon from '../images/xtra-icon.png';
import iosStore from '../images/ios-store.png'; // Replace with your iOS store image path
import androidStore from '../images/android-store.png'; // Replace with your Android store image path

const DownloadAppPage = () => {
  // Optional: Navigate back to home or any other page if needed
  const handleBackToHome = () => {
    window.location = 'https://xtraclubs.au';
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-5 justify-center items-center"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col items-center w-full max-w-md relative">
        <SafeAreaHeader />
        <img className="w-10 h-auto mb-5" src={xtraIcon} alt="xtra" />

        {/* Content container */}
        <div className="bg-xtraNavy bg-opacity-80 p-8 rounded-lg shadow-lg w-full max-h-[80vh] overflow-y-auto">
          <h2 className="text-2xl font-bold text-center mb-6 text-white">
            Thank You for Creating Your XtraClubs Account!
          </h2>
          <p className="text-center text-white mb-6">
            You're all set to start using our mobile app. Download the app now and get started!
          </p>

          {/* Store Links */}
          <div className="flex flex-col justify-center">
            {/* iOS App Store Link */}
            <a href="https://apps.apple.com/au/app/xtraclubs/id6479224485" target="_blank" rel="noopener noreferrer">
              <img src={iosStore} alt="Download on the App Store" className="w-full h-auto" />
            </a>

            {/* Android Google Play Store Link */}
            <a
              href="https://play.google.com/store/apps/details?id=au.xtraclubs.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={androidStore} alt="Get it on Google Play" className="w-full h-auto" />
            </a>
          </div>

          {/* Optional: Add a back to home button */}
          <button
            onClick={handleBackToHome}
            className="mt-8 w-full bg-aquaBlue text-white py-2 px-4 rounded-md shadow-md hover:bg-xtraNavy-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xtraNavy"
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPage;
