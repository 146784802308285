import React, { useEffect, useState } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Api from '../components/Api';

const OverstayCharges = () => {
  const [loading, setLoading] = useState(true);
  const [customerOverstays, setCustomerOverstays] = useState(null);

  const getCustomerOverstays = async () => {
    const response = await Api('/customer/overstays', 'GET');

    if (response.status === 200) {
      let result = response.data;
      if (result.success) {
        if (result.charges) {
          var charges = result.charges;

          const sessionMap = new Map(charges.sessions.map((session) => [session.sessionId, session]));

          charges.payments.forEach((payment) => {
            const session = sessionMap.get(payment.sessionId);
            if (session) {
              if (!session.payments) {
                session.payments = [];
              }
              session.payments.push(payment.paymentDetails);
            } else {
              console.log(`No session found for payment with sessionId ${payment.sessionId}`);
            }
          });

          console.log('charges: ', charges);

          setCustomerOverstays(charges);
        }
      } else {
        renderToastError({
          message: result.message,
        });
      }
    } else {
      renderToastError({
        message: 'Error retrieving your overstay detials. Please contact us for assistance.',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getCustomerOverstays();
  }, []);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6 justify-between overflow-hidden"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <div className="flex flex-row items-center mb-6 mx-6">
          <BackButton />
          <h6>Overstay Charges and Strikes</h6>
        </div>
        {loading ? (
          <CircularProgressBar />
        ) : (
          <div className="flex flex-col px-6 overflow-scroll max-h-[80vh]">
            <div>
              <ul className="text-white list-disc ml-5 leading-7">
                <li>Fees are capped at $5 per session</li>
                <li>There is a 5 minute grace period</li>
                <li>Your overstay fee is calculated once your session is ended</li>
                <li>There is a 3 strike system: you will only be charged for your 3rd offense and beyond</li>
                <li>Your session will automatically end 45 minutes after your included session duration</li>
              </ul>
            </div>
            {customerOverstays && customerOverstays.sessions.length > 0 ? (
              <div>
                <p className="mt-3 mb-3 text-center text-base">
                  Strikes on account
                  <br />
                  <b className="text-xl">{customerOverstays.count}</b>
                </p>
                {customerOverstays.sessions.length > 0 && (
                  <div>
                    <RenderOvertayItems sessions={customerOverstays.sessions} />
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="mt-5 mb-3 text-center text-base">You do not have any strikes on your account</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const RenderOvertayItems = ({ sessions }) => {
  console.log('sessions: ', sessions);

  return sessions.map(function (item, i) {
    return renderOvertayItem(item, i);
  });
};

const renderOvertayItem = (item, index) => {
  return (
    <div className={`flex flex-row justify-between items-center rounded p-5 bg-xtraBlack30 mb-2`}>
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between">
          <h3 className="text-sm">Strike {index + 1}</h3>
          {item?.payments?.length > 0 && (
            <h3 className="text-sm">{item.payments[0].charged ? 'Paid' : 'Failed Payment'}</h3>
          )}
        </div>
        <div className="flex flex-col items-start">
          <p className="flex-grow text-sm mt-2">{item.message}</p>
          <p className="flex-grow text-sm mt-2">
            Session:{' '}
            {moment(item.details.startTimeUTC).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('LLLL')}
          </p>
          {index <= 2 && (
            <>
              <p className="flex-grow text-sm mt-2">Session Duration: {item.totalDuration} minutes</p>
              <p className="flex-grow text-sm">Overstayed by: {item.overstayMinutes} minutes</p>
            </>
          )}
          {item?.payments?.length > 0 && <p className="flex-grow text-sm mt-5">{item.payments[0].message}</p>}
        </div>
      </div>
    </div>
  );
};

export default OverstayCharges;
