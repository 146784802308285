import React from 'react';
import formatDayOfWeek from '../renderFunctions/formatDayOfWeek';
import formatDate from '../renderFunctions/formatDate';
import SlotItem from './SlotItem';
import AccordionItem from './AccordionItem';
import moment from 'moment-timezone';

const ListTimeSlots = ({ timeSlots, onClickTimeSlot, reservationsSelected }) => {
  const groupByDay = timeSlots.reduce((acc, slot) => {
    const day = new Date(slot.date).toDateString();
    if (!acc[day]) {
      acc[day] = { Morning: [], Day: [], Evening: [] };
    }

    const hour = slot.time;
    if (hour >= 6 && hour < 12) {
      acc[day].Morning.push(slot);
    } else if (hour >= 12 && hour < 18) {
      acc[day].Day.push(slot);
    } else if (hour >= 18 && hour <= 22) {
      acc[day].Evening.push(slot);
    }

    return acc;
  }, {});

  function handleOnClick(date, time, peak) {
    onClickTimeSlot(date, time, peak);
  }

  const timeIsSelected = (date, time) => {
    for (var i = 0; i < reservationsSelected.length; i++) {
      if (date === reservationsSelected[i].date && time === reservationsSelected[i].time) {
        return true;
      }
    }
    return false;
  };

  const renderDisabled = () => {
    return (
      <div className="flex flex-row justify-between items-center rounded p-7 bg-xtraWhite30 mb-2">
        <p className="">
          Sorry, no times are available for this date. Please contact us if you have any questions:{' '}
          <a href="https://xtraclubs.au/contact" target="_blank" rel="noreferrer">
            Contact Us
          </a>
        </p>
      </div>
    );
  };

  const isTodayOrTomorrow = (index) => {
    if (index === 0) return 'Today';
    if (index === 1) return 'Tomorrow';
    return formatDayOfWeek(groupByDay[Object.keys(groupByDay)[index]].Morning[0].date);
  };

  const getFirstAndLastTimes = (times) => {
    const sortedTimes = times.sort((a, b) => a - b);
    const lastTime = String(sortedTimes[sortedTimes.length - 1]).includes('.5')
      ? sortedTimes[sortedTimes.length - 1] + ':30'
      : sortedTimes[sortedTimes.length - 1];
    return {
      first: moment(sortedTimes[0], 'H').format('h:mmA'),
      last: moment(lastTime, 'H:mm').add(30, 'minutes').format('h:mmA'),
    };
  };

  const isSlotTimeSameOrAfterNow = (slot) => {
    const hours = Math.floor(slot.time);
    const minutes = (slot.time - hours) * 60;
    const slotTime = moment(slot.date).hours(hours).minutes(minutes).add(30, 'minutes');
    const now = moment();
    return slotTime.isSameOrAfter(now);
  };

  return (
    <div>
      {Object.entries(groupByDay).map(([day, slots], index) => {
        const hasFutureMorningSlots = slots.Morning.some((slot) => {
          let time = String(slot.time);
          time = time.includes('.5') ? `${String(slot.time).replace('.5', ':30')}` : slot.time;
          return moment(`${slot.date} ${time}`, 'YYYY-MM-DD HH:mm').add(30, 'minutes').isAfter(moment());
        });
        const hasFutureDaySlots = slots.Day.some((slot) => {
          let time = String(slot.time);
          time = time.includes('.5') ? `${String(slot.time).replace('.5', ':30')}` : slot.time;
          return moment(`${slot.date} ${time}`, 'YYYY-MM-DD HH:mm').add(30, 'minutes').isAfter(moment());
        });
        const hasFutureEveningSlots = slots.Evening.some((slot) => {
          let time = String(slot.time);
          time = time.includes('.5') ? `${String(slot.time).replace('.5', ':30')}` : slot.time;
          return moment(`${slot.date} ${time}`, 'YYYY-MM-DD HH:mm').add(30, 'minutes').isAfter(moment());
        });

        const anyFutureSlots = hasFutureMorningSlots || hasFutureDaySlots || hasFutureEveningSlots;

        return (
          <div className="flex flex-col" key={day}>
            <div className="flex flex-row items-center bg-xtraNavy pl-2 pr-2 pt-1 pb-1 rounded self-start mb-2">
              <h6>{isTodayOrTomorrow(index)}</h6>
              <p className="text-xs opacity-50 ml-2 mt-0.5">{formatDate(slots.Morning[0].date)}</p>
            </div>
            {slots.Morning[0].disabled
              ? renderDisabled()
              : anyFutureSlots && (
                  <div>
                    {hasFutureMorningSlots && (
                      <AccordionItem
                        title="Morning"
                        subHeading={`${getFirstAndLastTimes(slots.Morning.map((slot) => slot.time)).first} - ${
                          getFirstAndLastTimes(slots.Morning.map((slot) => slot.time)).last
                        }`}
                        isFirstDay={index === 0}
                      >
                        {slots.Morning.map((slot, index) => {
                          if (isSlotTimeSameOrAfterNow(slot)) {
                            return (
                              <SlotItem
                                key={index}
                                slot={slot}
                                handleOnClick={handleOnClick}
                                timeIsSelected={timeIsSelected}
                              />
                            );
                          }
                          return null;
                        })}
                      </AccordionItem>
                    )}
                    {hasFutureDaySlots && (
                      <AccordionItem
                        title="Day"
                        subHeading={`${getFirstAndLastTimes(slots.Day.map((slot) => slot.time)).first} - ${
                          getFirstAndLastTimes(slots.Day.map((slot) => slot.time)).last
                        }`}
                      >
                        {slots.Day.map((slot, index) => {
                          if (isSlotTimeSameOrAfterNow(slot)) {
                            return (
                              <SlotItem
                                key={index}
                                slot={slot}
                                handleOnClick={handleOnClick}
                                timeIsSelected={timeIsSelected}
                              />
                            );
                          }
                          return null;
                        })}
                      </AccordionItem>
                    )}
                    {hasFutureEveningSlots && (
                      <AccordionItem
                        title="Evening"
                        subHeading={`${getFirstAndLastTimes(slots.Evening.map((slot) => slot.time)).first} - ${
                          getFirstAndLastTimes(slots.Evening.map((slot) => slot.time)).last
                        }`}
                      >
                        {slots.Evening.map((slot, index) => {
                          if (isSlotTimeSameOrAfterNow(slot)) {
                            return (
                              <SlotItem
                                key={index}
                                slot={slot}
                                handleOnClick={handleOnClick}
                                timeIsSelected={timeIsSelected}
                              />
                            );
                          }
                          return null;
                        })}
                      </AccordionItem>
                    )}
                  </div>
                )}
          </div>
        );
      })}
    </div>
  );
};

export default ListTimeSlots;
