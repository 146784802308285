import React, { useState, useEffect } from 'react';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import towelAccess from '../images/towelAccess.png';
import BackButton from '../components/BackButton';
import { useNavigate, useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons';
import SlideUpDrawer from '../components/SlideUpDrawer';
import renderToastError from '../renderFunctions/renderToastError';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import updateCustomerTowelService from '../constants/customerTowelService';
import CircularProgressBar from '../components/CircularProgressBar';
import Api from '../components/Api';
import ErrorSlideUpDrawer from '../components/SlideUpDrawer';
import UpdateCardForm from '../components/UpdateCardForm';

const terms = [
  'You can only open the towel cabinet once per session',
  'You can only take one towel per session',
  'You must close the cabinet after you have taken your towel',
  'At the end of the session, you must return your towel to the dirty towel bin below',
];

const TowelAccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { towelServiceType, prevPage, site, sessionId } = state;

  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [servicePopupMessage, setServicePopupMessage] = useState('');
  const [rentalPopupMessage, setRentalPopupMessage] = useState('');
  const [errorSlideUp, setErrorSlideUp] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false); // Control for displaying UpdateCardForm
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  if (prevPage && prevPage !== 'towel-service' && !towelServiceType) {
    navigate('/check-user');
  }

  const handleOpenClick = () => {
    setShowDrawer(true);
  };

  const getTowelServiceMessage = async () => {
    let updateTowel = await updateCustomerTowelService(true, 1);
    if (updateTowel) {
      if (updateTowel.success && updateTowel.message) {
        setServicePopupMessage(updateTowel.message);
      }
    }
  };

  const getRentalMessage = async () => {
    let rentalTowelMessage = await updateCustomerTowelService(true, 1, true);
    if (rentalTowelMessage) {
      if (rentalTowelMessage.success && rentalTowelMessage.message) {
        setRentalPopupMessage(rentalTowelMessage.message);
      }
    }
  };

  const updateTowelService = async () => {
    setLoading(true);
    let updateTowel = await updateCustomerTowelService(
      prevPage === 'towel-service' ? true : false,
      0,
      towelServiceType === 'rent' ? true : false
    );
    if (updateTowel) {
      if (updateTowel.success && updateTowel.message) {
        // Open towel door
        unlockKISIDoor(updateTowel.message);
      } else {
        const message = updateTowel?.response?.data?.message || updateTowel.message;
        if (updateTowel?.response?.data?.response?.decline_code !== 'insufficient_funds') {
          setErrorSlideUp(true);
        }
        renderToastError({ message });
      }
    } else {
      renderToastError({
        message: 'Failed to add the towel service to your account, please try again or contact us.',
      });
    }
    setLoading(false);
  };

  const unlockKISIDoor = async (message = '') => {
    const openDoorDB = await Api(`/session/${sessionId}/towel/open`, 'PUT', {
      openedDoor: true,
    });
    if (openDoorDB.status === 200) {
      const response = await Api(`/kisi/locks/${site.doors.TowelCabinet}/unlock`, 'GET');
      if (response.status === 200) {
        let result = response.data;
        if (result.message === 'Unlocked!') {
          renderToastSuccess({ message: `${message} Towel Cabinet door has been unlocked!` });
          navigate('/check-user');
        } else {
          renderToastError({
            message: 'There was an issue opening the towel cabinet door, please check the door is not blocked or already open.',
          });
        }
      } else {
        renderToastError({ message: 'Failed to open the towel cabinet, please speak to our staff.' });
      }
    } else {
      renderToastError({ message: 'Failed to open the towel cabinet, please speak to our staff.' });
    }
  };

  useEffect(() => {
    if (prevPage === 'towel-service') {
      getTowelServiceMessage();
    }
  }, [prevPage]);

  useEffect(() => {
    getRentalMessage();
  }, []);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-cover bg-center p-6 overflow-y-auto pb-9"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      {loading && (
        <div className="flex flex-col justify-center items-center fixed top-0 left-0 w-full h-full bg-xtraBlack50 z-50">
          <CircularProgressBar />
        </div>
      )}
      <SafeAreaHeader />

      <div className="flex flex-row items-center mb-4">
        <BackButton />
        <h6>Towel access</h6>
      </div>

      {showCardForm ? (
        <UpdateCardForm onSuccess={() => setShowCardForm(false)} onFailure={() => setShowCardForm(false)} />
      ) : (
        <div className="flex flex-col justify-between min-h-[78vh] overflow-scroll">
          <div className="flex flex-col">
            <img src={towelAccess} className="w-full h-24 mb-5" alt="towel access" />
            <div className="px-2">
              {terms.map((term, index) => (
                <div key={index} className="flex flex-row mb-2.5 gap-2.5 max-w-[90%]">
                  <div className="mt-1.5 h-2 w-2 rounded-full bg-transparent border border-white border-1 border-solid flex justify-center items-center"></div>
                  <p className="flex-1">{term}</p>
                </div>
              ))}
            </div>
            <div className="flex flex-row items-center mb-2.5 mt-5">
              <div
                className="h-12 w-12 rounded-md bg-transparent border border-white border-1 border-solid flex justify-center items-center"
                onClick={() => setAcceptedTerms(!acceptedTerms)}
              >
                {acceptedTerms && <FontAwesomeIcon icon={faCheck} size="2x text-white" />}
              </div>
              <p htmlFor="checkbox" className="font-bold ml-5 flex-1">
                I understand & agree to the above
              </p>
            </div>
          </div>
          <div className="w-full px-2">
            <div className="py-3 px-5 flex flex-row gap-1 rounded-lg items-center text-sm bg-xtraWhite20">
              <div className="flex justify-center items-center rounded-full bg-xtraOrange w-4 h-4">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">Our towel cabinet is under surveillance</p>
            </div>
            <button
              className="w-full mt-2.5"
              onClick={handleOpenClick}
              disabled={!acceptedTerms}
              style={{ opacity: acceptedTerms ? 1 : 0.5 }}
            >
              Open towel cabinet
            </button>
          </div>
        </div>
      )}

      <ErrorSlideUpDrawer
        setShow={setErrorSlideUp}
        show={errorSlideUp}
        action={() => setShowCardForm(true)} // Trigger UpdateCardForm
        actionText="Update Card Details"
        actionHeading="Update Card Details"
        message="Looks like your payment did not go through successfully. Please update your card details to proceed."
        actionRightAway={true}
      />
      <SlideUpDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        action={prevPage && prevPage === 'towel-service' ? updateTowelService : unlockKISIDoor}
        actionText="Open Towel Cabinet"
        actionHeading="Are you sure?"
        message={towelServiceType === 'rent' ? rentalPopupMessage : servicePopupMessage}
        actionRightAway={true}
      />
    </div>
  );
};

export default TowelAccess;
