import React, { useState, useEffect, useRef } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import Api from '../components/Api';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import UpdateCardForm from '../components/UpdateCardForm';
import { useCustomer } from '../contexts/CustomerContext';

const CancelRequest = () => {
  const navigate = useNavigate();
  const { customerMembership, getCustomer, customerLoading } = useCustomer();
  const mainContainer = useRef();
  const [loading, setLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    cancelDate: moment().toDate(),
    reason: '',
    cancelNow: true,
  });
  const [errors, setErrors] = useState({});
  const [futureRate, setFutureRate] = useState(null);
  const [cancellationLink, setCancellationLink] = useState('');
  const [reconsiderationLink, setReconsiderationLink] = useState('');
  const [renderUpdatePayment, setRenderUpdatePayment] = useState(false);
  const [renderUnableToCancel, setRenderUnableToCancel] = useState(false);
  const reasonRef = useRef(null);

  const minDate = moment().add(1, 'days');

  const handleFormInputs = async (e, type) => {
    const val = e.target ? e.target.value : e;
    setFormDetails({ ...formDetails, [type]: val });

    if (type === 'reason') {
      if (val.length <= 9) {
        setErrors({ ...errors, [type]: 'Please provide a detailed response to help us improve our service.' });
        mainContainer.current?.scrollTo(0, mainContainer.current.scrollHeight);
        return;
      }
    } else if (type === 'cancelDate') {
      setDateLoading(true);
      const getCancelDetails = await Api('/customer/membership/cancel/details', 'POST', {
        cancelDate: moment(val).format('YYYY-MM-DD'),
      });
      if (getCancelDetails.status === 200 && getCancelDetails.data.success) {
        setFutureRate(getCancelDetails.data.totalCancellationPrice);
        setReconsiderationLink(getCancelDetails.data.reconsiderationLink);
        setCancellationLink(getCancelDetails.data.cancellationLink);
      }
      setDateLoading(false);
    }

    if (errors[type]) setErrors({ ...errors, [type]: '' });
  };

  const validateForm = () => {
    const newErrors = {};
    for (const key in formDetails) {
      if (!formDetails[key]) {
        newErrors[key] = 'This field is required';
        reasonRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCancelSubmission = async () => {
    if (!validateForm() || Object.values(errors).some((error) => error)) {
      renderToastError({ message: 'Please fill in all required fields.' });
      return;
    }

    setLoading(true);
    try {
      const cancel = await Api('/customer/membership/cancel', 'POST', {
        cancelDate: moment(formDetails.cancelDate).format('Do MMMM YYYY'),
        cancelReason: formDetails.reason,
        cancelNow: formDetails.cancelNow,
        cancellationLink,
        reconsiderationLink,
      });

      if (cancel.status === 200) {
        renderToastSuccess({
          message: cancel.data.message,
          onClose: () => navigate(-1),
        });
        setFormDetails({ cancelDate: moment().toDate(), cancelNow: true, reason: '' });
      } else if (cancel?.response?.status === 400) {
        const cancelResponse = cancel.response.data;
        renderToastError({ message: cancelResponse?.message || 'Request failed, please try again.' });
        setRenderUpdatePayment(cancelResponse?.xtraError === 1 || cancelResponse?.xtraError === 2 || cancelResponse?.xtraError === 3);
        setRenderUnableToCancel(!cancelResponse?.xtraError);
      }
    } catch {
      renderToastError({
        message: 'Request failed, please try again or contact us for assistance.',
      });
    }
    setLoading(false);
  };

  const getCancelDetails = async () => {
    const getCancelDetails = await Api('/customer/membership/cancel/details', 'POST', { cancelDate: moment().format('YYYY-MM-DD') });
    if (getCancelDetails.status === 200 && getCancelDetails.data.success) {
      setReconsiderationLink(getCancelDetails.data.reconsiderationLink);
      setCancellationLink(getCancelDetails.data.cancellationLink);
    }
  };

  useEffect(() => {
    getCancelDetails();
  }, []);

  useEffect(() => {
    getCustomer();
    setLoading(false);
  }, [getCustomer]);

  const remainingDays = customerMembership?.nextPaymentDate ? moment(customerMembership.nextPaymentDate).diff(moment(), 'days') : 0;
  const additionalDays = 28 - remainingDays;

  return (
    <div className="flex flex-col bg-xtraNavy min-h-screen bg-cover bg-center py-6 justify-between" style={{ backgroundImage: `url(${xtraBG})` }}>
      <div className="flex flex-col">
        <SafeAreaHeader />
        <div className="flex flex-row items-center mb-6 mx-6">
          <BackButton />
          <h6>Cancel membership</h6>
        </div>
        {loading || customerLoading ? (
          <CircularProgressBar />
        ) : renderUnableToCancel ? (
          <div className="flex flex-col">
            <div className="py-3 px-5 flex flex-row gap-3 rounded-lg text-sm bg-xtraWhite20 mb-2.5 mx-6">
              <div className="flex justify-center items-center rounded-full bg-xtraError w-4 h-4">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">
                We were unable to process your cancellation. Your request has been sent, and we will contact you shortly.
              </p>
            </div>
            <button onClick={() => navigate(-1)} className="ml-6 mr-6">Continue</button>
          </div>
        ) : renderUpdatePayment ? (
          <div className='p-5'>
            <UpdateCardForm
              onSuccess={() => setRenderUpdatePayment(false)}
              onFailure={() => renderToastError({ message: 'Failed to update card. Please try again.' })}
            />
          </div>
        ) : customerMembership ? (
          <div className="flex flex-col gap-2.5 overflow-scroll max-h-[70vh]" ref={mainContainer}>
            <div className="py-3 px-5 flex flex-row gap-3 rounded-lg text-sm bg-xtraWhite20 mb-2.5 mx-6">
              <div className="flex justify-center items-center rounded-full bg-xtraError w-4 h-4">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">Please note: All memberships are bound by a 28 day cancellation policy.</p>
            </div>
            <div>
              <div className="acc-row px-6 py-5">
                <p className="text-sm">Your next payment amount</p>
                <p className="text-bold">{customerMembership?.nextPaymentAmount}</p>
              </div>
              <div className="acc-row px-6 py-5">
                <p className="text-sm">Your next payment date</p>
                <p className="text-bold">{moment(customerMembership?.nextPaymentDate).format('Do MMM YY')}</p>
              </div>
              <div className="acc-row acc-row-last px-6 py-5">
                <p className="text-sm">Days remaining in credit</p>
                <p className="text-bold">{remainingDays}</p>
              </div>
            </div>
            <div className="px-6">
              <div className="tabs flex pb-3 mt-3">
                <button
                  className={`tab w-full focus:outline-none ${
                    formDetails.cancelNow ? 'text-white' : 'bg-gray-800 text-gray-300'
                  }`}
                  onClick={() => {
                    setFormDetails({
                      ...formDetails,
                      cancelNow: true,
                      cancelDate: moment().toDate(),
                    });
                    setFutureRate(null);
                  }}
                >
                  Cancel Now
                </button>
                <button
                  className={`tab w-full focus:outline-none ${
                    !formDetails.cancelNow ? 'text-white' : 'bg-gray-800 text-gray-300'
                  }`}
                  onClick={() =>
                    setFormDetails({
                      ...formDetails,
                      cancelNow: false,
                      cancelDate: '',
                    })
                  }
                >
                  Cancel Later
                </button>
              </div>
              {!formDetails.cancelNow && (
                <div className="flex-col w-full relative ">
                  <p className="text-sm">Cancellation Date</p>
                  <input
                    type="text"
                    className="w-full text-center mt-2 mb-4"
                    value={formDetails.cancelDate ? moment(formDetails.cancelDate).format('Do MMMM, YY') : ''}
                    placeholder="Select the date you would like to cancel"
                    readOnly
                    onClick={() => document.getElementById('cancel-datepicker').click()} // Show date picker popup on click
                  />
                  <DatePicker
                    id="cancel-datepicker"
                    selected={formDetails.cancelDate}
                    onChange={(e) => handleFormInputs(e, 'cancelDate')}
                    minDate={minDate.toDate()}
                    maxDate={moment(customerMembership?.nextPaymentDate).add(28, 'days').toDate()}
                    dateFormat="yyyy-MM-dd"
                    customInput={<div style={{ display: 'none', position: 'absolute' }} />} // Hide the actual date picker input
                    popperPlacement="bottom-start"
                    popperClassName="larger-datepicker-popper"
                  />
                  {errors.cancelDate && <div className="text-xtraError">{errors.cancelDate}</div>}
                </div>
              )}
              {dateLoading ? (
                <CircularProgressBar />
              ) : (
                (formDetails.cancelNow || formDetails.cancelDate) && (
                  <div className="mt-2.5 mb-4">
                    <p className="text-bold">
                      If you cancel{' '}
                      {formDetails.cancelNow ? 'today' : `on ${moment(formDetails.cancelDate).format('Do MMMM, YY')}`}:
                    </p>
                    <div className="flex flex-col gap-3 mt-2">
                      {additionalDays > 0 && (
                        <div className="flex flex-row gap-2">
                          <div className="w-2.5 h-2.5 bg-white rounded-full mt-1"></div>
                          <p className="flex-1">
                            Payment for an <strong> additional {additionalDays} days</strong> membership, equivalent to{' '}
                            <strong>
                              {' '}
                              {futureRate
                                ? Number(futureRate).toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                  })
                                : (customerMembership?.dailyRate * additionalDays).toLocaleString('en-AU', {
                                    style: 'currency',
                                    currency: 'AUD',
                                  })}
                              , <u>will be taken immediately.</u>
                            </strong>{' '}
                            This will be your final membership payment.
                          </p>
                        </div>
                      )}
                      <div className="flex flex-row gap-2">
                        <div className="w-2.5 h-2.5 bg-white rounded-full mt-1"></div>
                        <p className="flex-1">
                          Your final day of access will be on{' '}
                          <strong>
                            {moment(formDetails.cancelNow ? moment() : formDetails.cancelDate)
                              .add(28, 'days')
                              .format('Do MMMM, YY')}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="flex flex-col">
                <p ref={reasonRef} className="text-sm">
                  Please let us know your reason for cancelling
                </p>
                <textarea
                  placeholder="Enter your reason for cancelling"
                  className="w-full text-left mt-2 mb-4"
                  rows={3}
                  value={formDetails.reason}
                  onChange={(e) => handleFormInputs(e, 'reason')}
                />
                {errors.reason && <div className="text-xtraError">{errors.reason}</div>}
              </div>
            </div>
          </div>
        ) : (
          <div className="px-5">
            <p>Sorry, your subscription details were not able to be retrieved. Please try again.</p>
          </div>
        )}
      </div>
      {customerMembership && (
        <div className="flex flex-col mb-10 px-5">
          <button className="w-full bg-xtraWhite10 border border-xtraError" onClick={handleCancelSubmission}>
            {'Submit cancellation request'}
          </button>
        </div>
      )}
    </div>
  );
};

export default CancelRequest;