import { Bounce, toast } from 'react-toastify';

const renderToastSuccess = ({
  toastId = 'SuccessToast',
  message,
  position = 'top-center',
  style = { top: '5vh', marginLeft: 20, marginRight: 20 },
  autoClose = 5000,
  onClose = false,
}) => {
  var toastOptions = {
    toastId: toastId,
    position: position,
    style: style,
    autoClose: autoClose,
    hideProgressBar: autoClose !== false ? true : false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    transition: Bounce,
  };

  if (onClose !== false) {
    toastOptions.onClose = () => onClose();
  }

  toast.success(message, toastOptions);

  if (toastId) {
    toast.update(toastId, { autoClose: autoClose, render: message });
  }
};

export default renderToastSuccess;
