import moment from 'moment-timezone';
import greyDashIcon from '../images/greyDashIcon.png';

const RestartPause = ({ restartMembership, endPauseDate }) => {
  return (
    <div
      className={`bg-xtraNavy rounded flex flex-col mb-3 mx-2 py-3 pr-2 pl-4 items-center justify-center text-center`}
    >
      <img className="w-14 h-auto mb-2" src={greyDashIcon} alt="grey dash icon" />
      <h6>Your membership is paused</h6>
      <p className="text-sm mt-1 mb-10">Would you like to reactivate your membership?</p>
      <button className={`mb-2 text-lg px-7`} onClick={() => restartMembership(true)}>
        Reactivate Membership
      </button>
      <p className="text-xs mt-1 px-20 mb-2">
        Your pause is scheduled to end on {moment(endPauseDate).format('DD/MM/YYYY')}
      </p>
    </div>
  );
};

export default RestartPause;
