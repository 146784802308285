import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import notifySwift from '../constants/notifySwift';

const BackButton = ({
  onClick = () => {
    window.history.back();
    notifySwift({
      function: 'stopTrackingLocation',
    });
  },
  size = 'lg',
}) => {
  return (
    <div className="flex justify-start">
      <button
        onClick={onClick}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon size={size} icon={faChevronLeft} />
      </button>
    </div>
  );
};

export default BackButton;
