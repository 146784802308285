import isCasual from './isCasual';

export default function notifySwift(jsonObject) {
  try {
    if (isCasual) {
      if (['openInternalLink', 'openExteneralLink'].includes(jsonObject?.function) && jsonObject?.data?.url) {
        window.open(jsonObject.data.url, '_blank');
      }
    } else if (window.Android) {
      console.log('Notify kotlin : ', JSON.stringify(jsonObject));
      window.Android.postMessage(JSON.stringify(jsonObject));
    } else if (window.webkit?.messageHandlers?.eventListener?.postMessage) {
      console.log('Notify swift : ', JSON.stringify(jsonObject));
      window.webkit.messageHandlers.eventListener.postMessage(JSON.stringify(jsonObject));
    }
  } catch (err) {
    console.error('notifySwift error:', err);
  }
}
