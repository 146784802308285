import axios from 'axios';
import notifySwift from '../constants/notifySwift';
import renderToastError from '../renderFunctions/renderToastError';

const Api = async (uri, method, data, _, v = '/v1', external = false, formData = false) => {
  var url = uri;
  var headers = {
    'Content-Type': 'application/json',
  };

  const saveDeviceToken = localStorage.getItem('customDeviceToken');
  const accessToken = localStorage.getItem('accessToken');

  notifySwift({
    function: 'getUniqueDeviceID',
  });

  console.log('endpoint hit: ', uri);

  if (external === false) {
    // url = process.env.REACT_APP_API_LOCAL + v + uri;
    url = process.env.REACT_APP_API_LIVE + v + uri;

    if (method === 'get') {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        url += '?';
        for (var k = 0; k < keys.length; k++) {
          url += keys[k] + '=' + encodeURIComponent(data[keys[k]]) + '&';
        }
        url = url.substring(0, url.length - 1);
        console.log('URL: ', url);
      }
    }

    headers = {
      'Content-Type': formData ? 'multipart/form-data' : 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    if (saveDeviceToken) {
      headers.xcdeviceid = saveDeviceToken;
    }
  }

  const config = {
    method: method,
    maxBodyLength: Infinity,
    url: url,
    headers: headers,
    data: data,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    console.log('error: ', error);
    if (error?.response) {
      if (error.response.data?.message === "Couldn't find user.") {
        console.log('error.response.data: ', error.response.data);
        localStorage.clear();
        setTimeout(() => {
          renderToastError({
            message: 'There was an issue logging in. Please try again.',
            autoClose: 2500,
            onClose: () => {
              logout();
            },
          });
        }, 200);
      }

      if (error?.response?.data?.error === 'jwt expired') {
        console.log('token expired');

        const storedAccessToken = localStorage.getItem('accessToken');
        if (storedAccessToken) {
          localStorage.removeItem('accessToken');
        }

        logout();
      }

      if (error?.response?.data?.error === 'jwt malformed') {
        console.log('error for token sent');
        const storedAccessToken = localStorage.getItem('accessToken');
        if (storedAccessToken) {
          localStorage.removeItem('accessToken');
        }
      }

      if (error.response?.data?.success === false) {
        let erroResp = error.response?.data;
        if (erroResp.error === 'XCDeviceID header is missing' || erroResp.error === 'Device ID does not match.') {
          console.log('error on device token');
          console.log('error: ', error);
          localStorage.clear();
          setTimeout(() => {
            renderToastError({
              message: 'Your session is no longer valid, you will need to log in again.',
              autoClose: 2500,
              onClose: () => {
                logout();
              },
            });
          }, 200);
        }
      }
    }

    return error;
  }
};

const logout = () => {
  localStorage.clear();
  notifySwift({
    function: 'logout',
    data: {},
  });
  window.location.href = '/login';
};

export default Api;
