import React from 'react';

const AccountIcon = ({ yourDetails, handleClick = () => {}, size = 36, fontSize = 18, noPhoto = true }) => {
  return !noPhoto && yourDetails?.profile_photo ? (
    <img
      src={yourDetails?.profile_photo}
      alt="yourDetails"
      className="rounded-full cover"
      style={{
        height: size,
        width: size,
        objectFit: 'cover',
      }}
      onClick={handleClick}
    />
  ) : (
    <h3
      className="bg-xtraWhite30 rounded-full flex justify-center items-center"
      style={{
        height: size,
        width: size,
        fontSize: fontSize,
      }}
      onClick={handleClick}
    >
      {yourDetails?.first_name ? yourDetails?.first_name.charAt(0) : ''}
      {yourDetails?.last_name ? yourDetails?.last_name.charAt(0) : ''}
    </h3>
  );
};

export default AccountIcon;
