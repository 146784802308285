import close from '../images/close.png';

const SimplePopup = ({ show, setShow, title = '', messages = [], buttons = [] }) => {
  const handleButtonPress = (clickHandler) => {
    setShow(false);
    if (clickHandler) {
      clickHandler();
    }
  };
  return (
    <>
      <div className={`popup-overlay ${show ? 'visible' : ''}`} onClick={() => setShow(false)} />
      <div
        onClick={() => setShow(false)}
        className={`fixed bottom-0 right-0 left-0 top-0 flex flex-col justify-center items-center z-[999] px-5 bg-[rgba(0, 0, 0, 0.5)] ${
          show ? 'visible' : 'invisible'
        }`}
      >
        <div className="p-5 bg-[#002126]">
          <div className="mb-6 flex flex-row justify-between items-center">
            <h5>{title}</h5>
            <img onClick={() => setShow(false)} src={close} className="w-6 h-6 object-fit" alt="close" />
          </div>
          <div className="flex flex-col gap-4">
            {messages.map((message, index) => (
              <p className="text-lg font-bold" key={'p_' + index}>
                {message}
              </p>
            ))}
          </div>
          <div className="mt-7 flex flex-col gap-3">
            {buttons.map((button, index) => (
              <button
                key={'button_' + index}
                className={`w-full text-lg ${
                  button.isSecondary ? 'border border-white bg-transparent whiteShadow' : ''
                }`}
                onClick={() => handleButtonPress(button.onClick)}
              >
                {button.text}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SimplePopup;
