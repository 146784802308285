import React, { useState } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import CircularProgressBar from '../components/CircularProgressBar';
import renderToastError from '../renderFunctions/renderToastError';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import BackButton from '../components/BackButton';
import Api from '../components/Api';
import { useNavigate } from 'react-router-dom';
import openEye from '../images/openEye.png';
import closeEye from '../images/closeEye.png';

const ChangePassword = () => {
  const navigate = useNavigate();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const updateUserPassword = async () => {
    setUpdateLoading(true);
    let formData = new FormData();
    if (newPassword !== '' && confirmPassword !== '') {
      if (newPassword !== confirmPassword) {
        setUpdateLoading(false);
        return renderToastError({
          message: 'The passwords do not match, please try again.',
        });
      }
      if (newPassword.length < 6) {
        setUpdateLoading(false);
        return renderToastError({
          message: 'Sorry, please enter a stronger password.',
        });
      }
      formData.append('newPassword', newPassword);
    } else {
      return;
    }

    let response = await Api(`/customer/password`, 'PUT', formData, null, '/v1', false, true);

    if (response.status === 200) {
      let result = response.data;
      if (result?.success === true) {
        setConfirmPassword('');
        setNewPassword('');
        renderToastSuccess({
          message: 'You successfully updated your account password!',
        });
      } else {
        renderToastError({
          message: response.data.message,
        });
      }
    } else {
      renderToastError({
        message: response.data.message,
      });
    }
    setUpdateLoading(false);
  };

  const renderUpdate = () => {
    return (
      <div className="roundedflex flex-col overflow-scroll max-h-[65vh]">
        <div className="acc-row flex flex-col px-6 py-5">
          <p className="text-sm">New Password</p>
          <div className="flex flex-row relative w-full">
            <input
              className="w-full"
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={handleNewPassword}
            />
            <img
              src={showNewPassword ? closeEye : openEye}
              alt="show new pass"
              className="w-10 object-fit absolute right-2"
              onClick={() => {
                setShowNewPassword(!showNewPassword);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col px-6 py-5">
          <p className="text-sm">Confirm Password</p>
          <div className="flex flex-row relative w-full">
            <input
              className="w-full"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPassword}
            />
            <img
              src={showConfirmPassword ? closeEye : openEye}
              alt="show new pass"
              className="w-10 object-fit absolute right-2"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />

      <div className="flex flex-row items-center mb-6 px-6">
        <BackButton />
        <h6>Change Password</h6>
      </div>
      {renderUpdate()}

      <div className="flex flex-col fixed bottom-14 left-0 right-0 z-10 px-6">
        <button
          className="mb-2"
          onClick={() => {
            if (!updateLoading) {
              updateUserPassword();
            }
          }}
        >
          Update Password
        </button>
        <button
          className="cancel"
          onClick={() => {
            if (!updateLoading) {
              navigate(-1);
            }
          }}
        >
          Cancel
        </button>
      </div>
      {updateLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 flex justify-center items-center z-50">
          <CircularProgressBar />
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
