import { useState, useEffect, useRef } from 'react';
import BackButton from '../components/BackButton';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import CircularProgressBar from '../components/CircularProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import Api from '../components/Api';
import { useNavigate } from 'react-router-dom';
import renderToastError from '../renderFunctions/renderToastError';
import { useAppSettings } from '../contexts/AppSettingsContext';

const MemberAgreement = () => {
  const navigate = useNavigate();
  const { appSettings, getAppSettings } = useAppSettings();
  const [medicalQuestions, setMedicalQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef();

  const continueEnabled = !medicalQuestions.some((question) => !question.answer || question.answer === 'Yes');

  const handleContinueClick = async () => {
    const response = await Api('/customer/requirement', 'PATCH', { requirement: 'memberHealthCheck', value: true });
    if (response.status === 200) {
      navigate('/welcome');
    } else {
      renderToastError({
        message: 'Failed to save health check progression, please close the app and try again.',
      });
    }
  };

  useEffect(() => {
    getAppSettings();
    setLoading(false);
  }, [getAppSettings]);

  useEffect(() => {
    if (appSettings) {
      setMedicalQuestions(appSettings.membershipAgreement?.medicalQuestions?.map((question) => ({ text: question })));
    }
  }, [appSettings]);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-cover bg-center p-6 overflow-y-auto pb-9"
      style={{ backgroundImage: `url(${xtraBG})` }}
      ref={containerRef}
    >
      <SafeAreaHeader />
      <div className="flex flex-row items-center mb-2.5">
        <BackButton />
        <h5>Health Check</h5>
      </div>
      {loading && <CircularProgressBar />}
      {!loading && (
        <>
          <p className="mb-5">
            Welcome to Xtra Clubs, where your health and safety is paramount.
            <br />
            <br />
            By providing us with information about any health concerns or symptoms that could impact your experience,
            you help us tailor our approach to your needs and minimize potential risks during your sessions.
            <br />
            <br />
            Please take a moment to complete the following questions. Should you require any clarification or assistance
            with this screening form, don't hesitate to contact a health professional.
          </p>
          {medicalQuestions.map((question, index) => (
            <div className="mb-5 flex flex-col gap-2.5">
              <h6>{question.text}</h6>
              <div className="flex flex-row gap-2.5">
                <button
                  className={`flex-1 py-3 ${question.answer === 'Yes' ? '' : 'bg-transparent'}`}
                  style={{ borderWidth: question.answer === 'Yes' ? 0 : 1 }}
                  onClick={() => {
                    const updatedQuestions = [...medicalQuestions];
                    updatedQuestions[index] = { ...question, answer: 'Yes' };
                    setMedicalQuestions(updatedQuestions);
                  }}
                >
                  Yes
                </button>
                <button
                  className={`flex-1 py-3 ${question.answer === 'No' ? '' : 'bg-transparent'}`}
                  style={{ borderWidth: question.answer === 'No' ? 0 : 1 }}
                  onClick={() => {
                    const updatedQuestions = [...medicalQuestions];
                    updatedQuestions[index] = { ...question, answer: 'No' };
                    setMedicalQuestions(updatedQuestions);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          ))}
        </>
      )}
      {!loading && medicalQuestions.some((question) => question?.answer === 'Yes') && (
        <div className="p-5 flex flex-row gap-1 rounded-lg bg-xtraWhite20">
          <div className="flex justify-center items-center rounded-full bg-xtraOrange w-4 h-4">
            <FontAwesomeIcon icon={faExclamation} size="xs text-white" />
          </div>
          <p className="flex-1">
            As you answered “YES” to one of the questions, please seek guidance from an appropriate allied health
            professional or medical practitioner prior to undertaking exercise. (Letter of advice required to complete
            Member Induction)
            <br />
            <br />
            <p className="underline">Questions? Contact Us</p>
          </p>
        </div>
      )}
      {!loading && (
        <button
          disabled={!continueEnabled}
          className="mt-2.5"
          onClick={handleContinueClick}
          style={{ opacity: continueEnabled ? 1 : 0.5 }}
        >
          Continue
        </button>
      )}
    </div>
  );
};

export default MemberAgreement;
