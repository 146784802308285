import Api from '../components/Api';

export const getCustomerActiveSession = async () => {
  // console.log('get customer active session');
  let getCustomerActiveSession = await Api(`/session/active`, 'GET');
  if (getCustomerActiveSession.status === 200) {
    let result = getCustomerActiveSession.data;
    if (result.session !== null) {
      return result.session;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
