import React from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import xtraLogo from '../images/xtra-clubs-logo.png';
import memberLogin from '../images/member-login-bg.png';
import becomeAMember from '../images/become-a-member-bg.jpg';
import blueRightArrow from '../images/blue-right-arrow.png';
import casualSessions from '../images/casual-sessions-bg.png';
import createAnAccount from '../images/create-an-account.jpg';
import { useNavigate } from 'react-router-dom';
import SafeAreaHeader from '../components/SafeAreaHeader';
import notifySwift from '../constants/notifySwift';

function Dash() {
  const navigate = useNavigate();

  const options = [
    {
      img: memberLogin,
      title: 'Log in',
      route: '/login-form',
    },
    {
      img: casualSessions,
      title: 'Casual sessions',
      onclick: () => {
        notifySwift({
          function: 'clearWebCache',
        });
        let path = 'https://xtraclubs.au/book-session/';
        // let path = 'http://192.168.1.11:3001/book-session/';
        // let path = 'http://192.168.1.11:3002/buy-session-packs/';
        notifySwift({
          function: 'openInternalLink',
          data: {
            url: `${path}`,
          },
        });
      },
    },
    {
      img: becomeAMember,
      title: 'Become a member',
      onclick: () => {
        notifySwift({
          function: 'clearWebCache',
        });
        let path = 'https://xtraclubs.au/become-a-member/?hideDetails=true';
        notifySwift({
          function: 'openInternalLink',
          data: {
            url: `${path}`,
          },
        });
      },
    },
    {
      img: createAnAccount,
      title: 'Create an account',
      route: '/create-account',
    },
  ];

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-5 pt-10 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <img className="w-52 h-auto" src={xtraLogo} alt="xtra" />
        <div className="flex flex-col overflow-scroll max-h-[70vh] mt-6">
          {options.map(function (option, index) {
            return (
              <div
                key={index}
                className="flex flex-col bg-no-repeat bg-center bg-cover justify-end rounded mb-5 h-[240px] relative"
                style={{ backgroundImage: `url(${option.img})` }}
                onClick={() => {
                  if (option.route) {
                    navigate(option.route);
                  } else {
                    option.onclick();
                  }
                }}
              >
                <div className="flex flex-row justify-between items-center w-full pl-5 pr-5 pb-2 z-10">
                  <h4>{option.title}</h4>
                  <img src={blueRightArrow} alt="Right Arrow" className="w-10 h-10 object-fit" />
                </div>
                <div className="absolute bottom-0 opacity-80 transform -rotate-y-180 bg-gradient-to-t from-xtraNavy to-transparent h-[60px] w-full z-neg-1"></div>
              </div>
            );
          })}
        </div>
      </div>
      <span
        onClick={() => {
          navigate('/instore-help?navigate=true');
        }}
        className="pb-10 text-white font-bold underline"
      >
        Need help?
      </span>
    </div>
  );
}

export default Dash;
