import React, { useState, useEffect } from 'react';
import SafeAreaHeader from '../components/SafeAreaHeader';
import CircularProgressBar from '../components/CircularProgressBar';
import xtraBG from '../images/xtra-bg.jpg';
import xtraIcon from '../images/xtra-icon.png';
import Api from '../components/Api';
import { useNavigate } from 'react-router-dom';
import renderToastError from '../renderFunctions/renderToastError';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import notifySwift from '../constants/notifySwift';
import BackButton from '../components/BackButton';

const useDeviceDetect = () => {
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for common WebView identifiers
    const isWebViewDetected = /WebView|(iPhone|iPod|iPad)(?!.*Safari)|Android.*wv/.test(userAgent);

    setIsWebView(isWebViewDetected);
  }, []);

  return { isWebView };
};

const CreateAccountPage = () => {
  const navigate = useNavigate();
  const { isWebView } = useDeviceDetect();

  console.log('is isWebView? ', isWebView);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    street: '',
    city: '',
    state: '',
    postalCode: '',
    phone: '',
    password: '',
  });

  const [passwordStrength, setPasswordStrength] = useState({
    minLength: false,
    capitalLetter: false,
    specialChar: false,
    number: false,
  });

  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'password') {
      setShowPasswordRequirements(true);
    }
  };

  useEffect(() => {
    const { password } = formData;

    const strength = {
      minLength: password.length >= 8,
      capitalLetter: /[A-Z]/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      number: /[0-9]/.test(password),
    };

    setPasswordStrength(strength);

    if (strength.minLength && strength.capitalLetter && strength.specialChar && strength.number) {
      setShowPasswordRequirements(false);
    }
  }, [formData]);

  const createAccount = async () => {
    setIsLoading(true); // Start loading

    let deviceId = localStorage.getItem('customDeviceToken');

    console.log('device id: ', deviceId);

    var sendData = formData;

    if (deviceId) {
      sendData.deviceId = deviceId;
    }

    const response = await Api('/customer/create', 'POST', sendData);

    if (response.status !== 200) {
      if (response.response) {
        const errorMessage = response.response.data.message || 'An error occurred';
        renderToastError({ message: errorMessage });
      } else if (response.request) {
        console.error('No response received:', response.request);
        renderToastError({ message: 'No response from server. Please try again later.' });
      } else {
        console.error('Error setting up the request:', response.message);
        renderToastError({ message: response.message });
      }

      setIsLoading(false);
      return;
    }

    let result = response.data;
    console.log('Account created successfully:', result);
    if (result.success) {
      renderToastSuccess({
        message: result.message,
      });
      if (result.accessToken) {
        localStorage.setItem('accessToken', result.accessToken);
      }
      setTimeout(() => {
        if (isWebView) {
          navigate('/splash');
        } else {
          navigate('/download-app');
        }
      }, 800);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !passwordStrength.minLength ||
      !passwordStrength.capitalLetter ||
      !passwordStrength.specialChar ||
      !passwordStrength.number
    ) {
      renderToastError({
        message: 'Password must meet minimum requirements to create account.',
      });
      return;
    }

    notifySwift({
      function: 'getUniqueDeviceID',
    });

    createAccount();
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-5 justify-center items-center"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      {isLoading && (
        <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <CircularProgressBar />
        </div>
      )}
      <div className="flex flex-col w-full max-w-md relative">
        <SafeAreaHeader />
        <div className="flex-1 flex flex-row items-start">
          <div className="basis-[46%]">
            <BackButton />
          </div>
          <img className="w-10 h-auto mb-5" src={xtraIcon} alt="xtra" />
        </div>

        {/* Scrollable container for the form */}
        <div className="bg-xtraNavy bg-opacity-80 p-8 rounded-lg shadow-lg w-full h-full max-h-[80vh] overflow-y-auto">
          <h2 className="text-2xl font-bold text-center mb-6">Create an Account</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-white">First Name:</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Last Name:</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Street:</label>
              <input
                type="text"
                name="street"
                value={formData.street}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">City:</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">State:</label>
              <select
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              >
                <option value="">Select State</option>
                <option value="ACT">Australian Capital Territory (ACT)</option>
                <option value="NSW">New South Wales (NSW)</option>
                <option value="NT">Northern Territory (NT)</option>
                <option value="QLD">Queensland (QLD)</option>
                <option value="SA">South Australia (SA)</option>
                <option value="TAS">Tasmania (TAS)</option>
                <option value="VIC">Victoria (VIC)</option>
                <option value="WA">Western Australia (WA)</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Postcode:</label>
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Phone:</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-xtraNavy focus:border-xtraNavy"
                required
              />
              {showPasswordRequirements && (
                <div className="mt-2 text-sm">
                  <p className={passwordStrength.minLength ? 'text-green-500' : 'text-red-500'}>
                    <span className={passwordStrength.minLength ? 'text-xtraAqua' : ''}>
                      {passwordStrength.minLength ? '✔' : '✖'}
                    </span>{' '}
                    Minimum 8 characters
                  </p>
                  <p className={passwordStrength.capitalLetter ? 'text-green-500' : 'text-red-500'}>
                    <span className={passwordStrength.capitalLetter ? 'text-xtraAqua' : ''}>
                      {passwordStrength.capitalLetter ? '✔' : '✖'}
                    </span>{' '}
                    At least 1 capital letter
                  </p>
                  <p className={passwordStrength.specialChar ? 'text-green-500' : 'text-red-500'}>
                    <span className={passwordStrength.specialChar ? 'text-xtraAqua' : ''}>
                      {passwordStrength.specialChar ? '✔' : '✖'}
                    </span>{' '}
                    At least 1 special character
                  </p>
                  <p className={passwordStrength.number ? 'text-green-500' : 'text-red-500'}>
                    <span className={passwordStrength.number ? 'text-xtraAqua' : ''}>
                      {passwordStrength.number ? '✔' : '✖'}
                    </span>{' '}
                    At least 1 number
                  </p>
                </div>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-aquaBlue text-white py-2 px-4 rounded-md shadow-md hover:bg-xtraNavy-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-xtraNavy"
              disabled={isLoading}
            >
              Create Account
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountPage;
