import getCustomerSubscription from '../constants/customerSubscription';
import pushOptionDetails from '../constants/pushOptionDetails';
import Api from '../components/Api';
import moment from 'moment-timezone';
import getPurchasedPeakSessions from './getPurchasedPeakSessions';

const generateDates = async ({
  setTimeSlots,
  setLoading,
  setPurchase,
  setEntitlements,
  setIsoWeekPeakLimits,
  setIsoWeeks,
  setTypeOptions,
  setPreviousReservations,
  setSessionPackUsage = () => {},
  updateReservationType,
  setSubOnHold,
  reservationType,
  setPurchasedPeakSessions = () => {},
  getFromWP = false,
}) => {
  const currentDate = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
  let siteSelected = '65c468e98af2a3e02af945ae';
  let site = null;
  let nextFiveDays = [];
  let activeSubscription = false;
  let isoWeeks = new Set();
  let isoWeekLimits = {};
  let peakAlreadySelectedForIsoWeek = {};
  let resTypeOptions = [];
  let sessionPackU = [];
  let visibility = 6;

  if (getFromWP) {
    setLoading(true);
  }

  const unavailable = await getSiteUnavailability(siteSelected);
  site = unavailable.site;

  const reservations = await getCustomerReservations(siteSelected);
  const customerReservations = reservations?.customer ? reservations.customer : [];
  const prevCustomerReserv = reservations?.previous ? reservations?.previous : [];
  const customerSubscription = await getCustomerSubscription(getFromWP);

  if (prevCustomerReserv !== null) {
    const prevCustomerReservations = prevCustomerReserv?.reservations ? prevCustomerReserv.reservations : [];
    setPreviousReservations(prevCustomerReservations);
    setSessionPackUsage(reservations?.sessionPackUsage ? reservations.sessionPackUsage : []);
    sessionPackU = reservations?.sessionPackUsage ? reservations.sessionPackUsage : [];

    if (!customerSubscription || customerSubscription.userSubscriptions.length === 0) {
      if (
        customerSubscription &&
        (customerSubscription.sessionPacks?.length > 0 || customerSubscription.gifts?.length > 0)
      ) {
        activeSubscription = true;
        processProducts(customerSubscription.sessionPacks, 'session_pack', resTypeOptions, sessionPackU);
        processProducts(customerSubscription.gifts, 'gift', resTypeOptions, sessionPackU);
      } else {
        setPurchase(true);
      }
    } else {
      if (customerSubscription.sessionPacks?.length > 0 || customerSubscription.gifts?.length > 0) {
        activeSubscription = true;
        processProducts(customerSubscription.sessionPacks, 'session_pack', resTypeOptions, sessionPackU);
        processProducts(customerSubscription.gifts, 'gift', resTypeOptions, sessionPackU);
      }

      for (const subscription of customerSubscription.userSubscriptions) {
        if (subscription.status === 'active') {
          activeSubscription = true;
          const membershipProduct = subscription.products.find((product) =>
            product.categories.includes('Membership Packages')
          );
          pushOptionDetails({
            subscriptionId: subscription.subscription_id,
            productId: membershipProduct.product_id,
            name: membershipProduct.product_name,
            valueType: 'subscription',
            resTypeOptions: resTypeOptions,
          });
          break;
        } else if (subscription.status === 'on-hold') {
          setSubOnHold(true);
        }
      }

      if (!activeSubscription) {
        setPurchase(true);
      }
    }

    console.log('resTypeOptions: ', resTypeOptions);
    console.log('activeSubscription: ', activeSubscription);

    if (resTypeOptions.length === 0 || !activeSubscription) {
      setTimeSlots([]);
      setLoading(false);
      return;
    }

    setTypeOptions(resTypeOptions);

    const defaultOption = resTypeOptions.find((option) => option.type === 'subscription') ?? resTypeOptions[0];
    if (defaultOption?.valueName !== reservationType?.valueName) {
      updateReservationType(defaultOption);
      reservationType = defaultOption;
    }

    if (reservationType) {
      const rolling = await Api('/schedule/rollingvisibility', 'POST', {
        subscriptionName: reservationType?.valueName,
      });
      if (rolling.status === 200 && rolling.data?.visibility) {
        visibility = rolling.data.visibility;
      }
    }

    for (let i = 0; i < visibility; i++) {
      const date = currentDate.clone().add(i, 'days');
      let disabled = false;
      let timeDisabled = false;
      if (unavailable.unavailableDates.includes(moment(date).format('YYYY-MM-DD'))) {
        disabled = true;
      }

      if (site) {
        let day = moment(date).format('dddd');
        let openHours = site.openHours[day];
        let startHour = parseInt(openHours.start.split(':')[0]);
        let endHour = parseInt(openHours.finish.split(':')[0]);
        let endMinute = openHours.finish.split(':')[1];
        const peakTimes = site.peakTimes;
        var formattedDate = moment(date).format('YYYY-MM-DD');
        var isoWeek = moment(date).isoWeek();

        isoWeeks.add(isoWeek);
        if (openHours.open === false) {
          disabled = true;
        }

        endHour = String(endMinute).includes('30') ? endHour + '.5' : endHour;
        for (let i = Number(startHour); i <= Number(endHour); i += 0.5) {
          if (i <= Number(endHour)) {
            let type = 'Off-peak';
            let existingReservation = false;
            let checkDay = moment(date).format('dddd');
            let check = `${formattedDate}|${i}`;

            if (peakTimes[checkDay]) {
              if (peakTimes[checkDay].includes(i)) {
                type = 'Peak';
              }
            }

            if (customerReservations.reservations[check]) {
              existingReservation = true;
            }

            let dateSelected = moment(date).format('YYYY-MM-DD');
            if (unavailable.unavailableTimes && unavailable.unavailableTimes[dateSelected]) {
              let times = unavailable.unavailableTimes[dateSelected];
              if (times.includes(i)) {
                timeDisabled = true;
              } else {
                timeDisabled = false;
              }
            }

            let capacity = site.capacity;
            if (site.dateTimeCapacities && site.dateTimeCapacities[dateSelected]) {
              if (site.dateTimeCapacities[dateSelected][i]) {
                capacity = site.dateTimeCapacities[dateSelected][i];
              }
            } else if (site.dateTimeCapacities && site.dateTimeCapacities[checkDay]) {
              if (site.dateTimeCapacities[checkDay][i]) {
                capacity = site.dateTimeCapacities[checkDay][i];
              }
            }

            nextFiveDays.push({
              date: formattedDate,
              time: i,
              capacity: capacity,
              numReservations: 0,
              type: type,
              disabled: disabled,
              timeDisabled: timeDisabled,
              existingReservation: existingReservation,
              site: site,
            });
          }
        }
      }
    }
  }

  var purchasedPeakSessions = await getPurchasedPeakSessions(siteSelected);
  setPurchasedPeakSessions(purchasedPeakSessions);
  for (const isoWeek of isoWeeks) {
    for (const reservation of Object.keys(customerReservations?.reservations)) {
      const [date, time] = reservation.split('|');
      if (moment(date).isoWeek() === isoWeek) {
        const dayOfWeek = moment(date).format('dddd');
        const peakTimes = site.peakTimes[dayOfWeek];
        if (peakTimes?.includes(Number(time))) {
          peakAlreadySelectedForIsoWeek[isoWeek] = peakAlreadySelectedForIsoWeek[isoWeek]
            ? peakAlreadySelectedForIsoWeek[isoWeek] + 1
            : 1;
        }
      }
    }
    let peakAlready = peakAlreadySelectedForIsoWeek[isoWeek] ? peakAlreadySelectedForIsoWeek[isoWeek] : 0;
    let morePeak = false;
    if (customerSubscription.entitlements === null) {
      morePeak = false;
    } else {
      morePeak = peakAlready > customerSubscription.entitlements['Peak-Hour Reservations'];
    }

    console.log('peakAlready: ', peakAlready);
    console.log('morePeak: ', morePeak);

    isoWeekLimits[isoWeek] = morePeak
      ? 0 + purchasedPeakSessions.length
      : customerSubscription.entitlements === null
      ? 999
      : customerSubscription.entitlements['Peak-Hour Reservations'] - peakAlready + purchasedPeakSessions.length;

    console.log(isoWeekLimits[isoWeek]);
  }

  console.log('isoWeekLimits: ', isoWeekLimits);

  const schedule = await getSiteCapacity(nextFiveDays);
  setIsoWeeks(isoWeeks);

  setIsoWeekPeakLimits(isoWeekLimits);

  setEntitlements(customerSubscription.entitlements);
  setTimeSlots(schedule);
  setLoading(false);
};

export default generateDates;

const getCustomerReservations = async (siteSelected) => {
  const getReservations = await Api(`/customer/reservations`, 'POST', {
    site: siteSelected,
    startDate: moment().startOf('day').subtract(7, 'days').toISOString(),
    endDate: moment().endOf('day').add(5, 'days').toISOString(),
  });

  if (getReservations.status === 200) {
    const result = getReservations.data;
    if (result.success) {
      // console.log('result: ', result);
      return result;
    }
  } else {
    return null;
  }
};

function isNotEmptyObject(obj) {
  return Object.keys(obj).length > 0;
}

const getSiteUnavailability = async (siteSelected) => {
  const unavailability = await Api(`/schedule/unavailabledates?siteid=${siteSelected}`, 'GET');

  console.log('un: ', unavailability);
  if (unavailability.status === 200) {
    let dates = unavailability.data.data;
    console.log('dates response: ', dates);
    var site = dates.site;
    var returnObj = {
      site: site,
    };
    if (dates.unavailabledates.length > 0 || isNotEmptyObject(dates.unavailableTimes)) {
      if (dates.unavailabledates.length > 0) {
        returnObj.unavailableDates = [...dates.unavailabledates];
      }
      if (isNotEmptyObject(dates.unavailableTimes)) {
        returnObj.unavailableTimes = { ...dates.unavailableTimes };
      }
    } else {
      return [];
    }

    return returnObj;
  } else {
    return [];
  }
};

const processProducts = (productsArray, valueType, resTypeOptions, sessionPackU) => {
  for (let i = 0; i < productsArray.length; i++) {
    const item = productsArray[i];
    const orderId = item.order_id;
    for (let j = 0; j < item.products.length; j++) {
      const product = item.products[j];
      pushOptionDetails({
        orderId: orderId,
        productId: product.product_id,
        name: product.product_name,
        quantity: product.quantity,
        valueType: valueType,
        resTypeOptions: resTypeOptions,
        sessionPackU: sessionPackU,
      });
    }
  }
};

const getSiteCapacity = async (nextFiveDays) => {
  const capacity = await Api(`/schedule/capacities`, 'POST', {
    times: nextFiveDays,
  });

  if (capacity.status === 200) {
    const result = capacity.data;
    if (result.success) {
      return result.data.dates;
    }
  } else {
    return nextFiveDays;
  }
};
