import React, { useState, useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import updateCustomerTowelService from '../constants/customerTowelService';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import SlideUpDrawer from '../components/SlideUpDrawer';
import UpdateCardForm from '../components/UpdateCardForm'; // Import UpdateCardForm
import updateCustomerSessionDuration from '../constants/customerSessionDuration';
import { useNavigate } from 'react-router';
import updateCustomerPeakSession from '../constants/customerPeakSession';
import ErrorSlideUpDrawer from '../components/SlideUpDrawer';
import RestartSlideUpDrawer from '../components/SlideUpDrawer';
import MembershipOptionsDrawer from '../components/MembershipOptionsDrawer';
import moment from 'moment-timezone';
import Api from '../components/Api';
import getPurchasedPeakSessions from '../constants/getPurchasedPeakSessions';
import PeakSessionsDrawer from '../components/PeakSessionDrawer';
import handlePurchaseSinglePeakVisit from '../constants/handlePurchaseSinglePeakVisit';
import { useCustomer } from '../contexts/CustomerContext';

const MembershipDetails = () => {
  const navigate = useNavigate();
  const {
    getCustomer,
    customerLoading,
    customerSubscription,
    customerEntitlements,
    customerSessionPacksAndGifts,
    customerMembership,
    customerMembershipOnHold,
    customerMembershipAwaitingCancellation,
    customerMembershipProductName,
    customerMembershipHasExtendedSession,
    customerMembershipProduct,
  } = useCustomer();
  const [peakSessionPurchased, setPeakSessionPurchased] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [errorSlideUp, setErrorSlideUp] = useState(false);
  const [membershipDrawer, showMemebshipDrawer] = useState(false);
  const [peakSessionDrawer, showPeakSessionDrawer] = useState(false);
  const [displaySinglePeakVisitPurchase, setDisplaySinglePeakVisitPurchase] = useState(false);
  const [singlePeakVisitInfo, setSinglePeakVisitInfo] = useState(null);
  const [showRestartDrawer, setShowRestartDrawer] = useState(false);
  const [popupMessage, setPopupMessage] = useState();
  const [serviceLoading, setServiceLoading] = useState({
    towelService: false,
    sessionDuration: false,
    membership: false,
    peakSession: false,
  });
  const [peakVisitLoading, setPeakVisitLoading] = useState(false);
  const [peakVisitPurchaseLoading, setPeakVisitPurchaseLoading] = useState(false);
  const [activePause, setActivePause] = useState();
  const [showCardForm, setShowCardForm] = useState(false); // State to display UpdateCardForm

  const isThrive = customerMembershipProductName === 'Thrive';

  let sessionPackCount = 0;
  for (const pack of customerSessionPacksAndGifts) {
    sessionPackCount += pack?.products?.length;
  }

  const activeMembership =
    customerMembership && customerMembershipProduct ? { ...customerMembership, ...customerMembershipProduct } : null;

  const getActivePause = async () => {
    const response = await Api('/customer/membership/pause/active', 'GET', null);

    if (response.status === 200 && response.data.pause) {
      setActivePause(response.data.pause);
    }
    setLoading(false);
  };

  const getPeakSessions = async () => {
    const purchasedPeakSessions = await getPurchasedPeakSessions(null);
    setPeakSessionPurchased(purchasedPeakSessions);
    setLoading(false);
  };

  const getTowelServiceMessage = async () => {
    setPopupMessage(null);
    const enable = customerEntitlements?.['Towel Service'];
    let updateTowel = await updateCustomerTowelService(!enable, 1);
    if (updateTowel) {
      if (updateTowel.success && updateTowel.message) {
        console.log('updateTowel', updateTowel);
        setPopupMessage(updateTowel.message);
      }
    }
  };

  const getSessionDurationMessage = async (enable) => {
    setPopupMessage(null);
    let updateDuration = await updateCustomerSessionDuration(!enable, 1);
    if (updateDuration) {
      if (updateDuration.success && updateDuration.message) {
        setPopupMessage(updateDuration.message);
      }
    }
  };

  const getPeakSessionMessage = async (type) => {
    setPopupMessage(null);
    let peakSession = await updateCustomerPeakSession(1, type);
    if (peakSession) {
      if (peakSession.success && peakSession.message) {
        setPopupMessage(peakSession.message);
      }
    }
  };

  const updateTowelService = async () => {
    setServiceLoading({
      ...serviceLoading,
      towelService: true,
    });
    const enable = customerEntitlements?.['Towel Service'];
    let updateTowel = await updateCustomerTowelService(!enable, 0);
    if (updateTowel) {
      console.log('update towel ', updateTowel);
      if (updateTowel.success && updateTowel.message) {
        getCustomer({ update: true });
        renderToastSuccess({
          toastId: 'CustomerSubscriptionError',
          message: updateTowel.message,
          autoClose: false,
        });
      } else {
        let message = updateTowel?.response?.data?.message ? updateTowel.response.data.message : updateTowel.message;

        if (updateTowel?.response?.data?.response?.decline_code !== 'insufficient_funds') {
          setErrorSlideUp(true);
        }
        renderToastError({
          message: message,
        });
      }
    } else {
      renderToastError({
        message: 'Failed to add the towel service to your account, please try again or contact us.',
      });
      setLoading(false);
    }
    setServiceLoading({
      ...serviceLoading,
      towelService: false,
    });
  };

  const updateSessionDuration = async () => {
    setServiceLoading({
      ...serviceLoading,
      sessionDuration: true,
    });
    const enable = !customerMembershipHasExtendedSession;
    console.log('enable: ', enable);
    let updateDuration = await updateCustomerSessionDuration(enable, 0);
    if (updateDuration) {
      console.log('update session duration ', updateDuration);
      if (updateDuration.success && updateDuration.message) {
        getCustomer({ update: true });
        renderToastSuccess({
          toastId: 'CustomerSubscriptionError',
          message: updateDuration.message,
          autoClose: false,
        });
      } else {
        let message = updateDuration?.response?.data?.message
          ? updateDuration.response.data.message
          : updateDuration.message;

        if (updateDuration?.response?.data?.response?.decline_code !== 'insufficient_funds') {
          setErrorSlideUp(true);
        }
        renderToastError({
          message: message,
        });
      }
    } else {
      renderToastError({
        message: 'Failed to add the towel service to your account, please try again or contact us.',
      });
      setLoading(false);
    }
    setServiceLoading({
      ...serviceLoading,
      sessionDuration: false,
    });
  };

  const updatePeakSession = async (type) => {
    setServiceLoading({
      ...serviceLoading,
      peakSession: true,
    });
    let updatePeakSession = await updateCustomerPeakSession(0, type);
    if (updatePeakSession) {
      console.log('update peak session ', updatePeakSession);
      if (updatePeakSession.success && updatePeakSession.message) {
        getCustomer({ update: true });
        renderToastSuccess({
          message: updatePeakSession.message,
          autoClose: false,
        });
      } else {
        let message = updatePeakSession?.response?.data?.message
          ? updatePeakSession.response.data.message
          : updatePeakSession.message;

        if (updatePeakSession?.response?.data?.response?.decline_code !== 'insufficient_funds') {
          setErrorSlideUp(true);
        }
        renderToastError({
          message: message,
        });
      }
    } else {
      renderToastError({
        message: 'Failed to add the towel service to your account, please try again or contact us.',
      });
      setLoading(false);
    }
    setServiceLoading({
      ...serviceLoading,
      peakSession: false,
    });
  };

  const handleSessionChangeClick = () => {
    getSessionDurationMessage(customerMembershipHasExtendedSession);
    setSelectedOption({
      action: updateSessionDuration,
      text: customerMembershipHasExtendedSession ? 'Shorten session duration' : 'Extend session duration',
      class: '',
    });
    setShowDrawer(true);
  };

  const handleTowelChangeClick = () => {
    getTowelServiceMessage();
    setSelectedOption({
      action: updateTowelService,
      text: customerEntitlements?.['Towel Service'] ? 'Remove Towel Service' : 'Add Towel Service',
      class: '',
    });
    setShowDrawer(true);
  };

  const handlePeakSessionClick = (type) => {
    showPeakSessionDrawer(false);
    getPeakSessionMessage(type);
    setSelectedOption({
      action: () => updatePeakSession(type),
      text: type ? 'Decrease Peak Sessions' : 'Increase Peak Sessions',
      class: '',
    });
    setShowDrawer(true);
  };

  const restartMembership = async () => {
    setLoading(true);
    const restart = await Api('/customer/membership/restart', 'GET');
    if (restart.status === 200) {
      let result = restart.data;
      if (result.success) {
        renderToastSuccess({
          message: result.message,
        });
      } else {
        renderToastError({
          message: result.message,
        });
      }
    } else {
      renderToastError({
        message: 'Unable to restart your subscription. Please contact our support for further instructions.',
      });
    }
    setTimeout(() => {
      getCustomer({ update: true });
    }, 1000);
  };

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  useEffect(() => {
    getActivePause();
    getPeakSessions();
  }, []);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover py-6"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row items-center px-6 mb-6">
        <BackButton />
        <h6>Membership details</h6>
      </div>
      {customerLoading || loading ? (
        <CircularProgressBar marginTop="mt-2" />
      ) : customerSubscription ? (
        <div className="rounded flex flex-col">
          <div className="acc-row flex flex-col px-6 py-5">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col">
                <p className="text-sm">Membership tier</p>
                <h6 className="text-base">
                  {customerMembership
                    ? `${customerMembershipProductName} ${customerMembershipOnHold ? '- ON HOLD' : ''}`
                    : 'No Active Membership'}
                </h6>
              </div>
              <div className="flex flex-row gap-2.5">
                <button className="rounded-full text-sm" onClick={() => showMemebshipDrawer(true)}>
                  Options
                </button>
              </div>
            </div>
            {customerMembership && customerSubscription && (
              <>
                {!customerMembershipOnHold &&
                  (customerMembershipAwaitingCancellation ? (
                    <div className="flex flex-row justify-between items-center mt-5">
                      <div className="flex flex-col">
                        <p className="text-sm">Cancellation Date</p>
                      </div>
                      <div className="flex flex-row gap-2.5">
                        <h6 className="text-base">
                          {moment(customerSubscription.cancellationDate).format('Do MMM YY')}
                        </h6>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-between items-center mt-5">
                      <div className="flex flex-col">
                        <p className="text-sm">Next Payment Date</p>
                      </div>
                      <div className="flex flex-row gap-2.5">
                        <h6 className="text-base">
                          {moment(customerSubscription.nextPaymentDate).format('Do MMM YY')}
                        </h6>
                      </div>
                    </div>
                  ))}
                {!customerMembershipOnHold && !customerMembershipAwaitingCancellation && (
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-col">
                      <p className="text-sm">Next Payment Amount</p>
                    </div>
                    <div className="flex flex-row gap-2.5">
                      <h6 className="text-base">{customerMembership.nextPaymentAmount}</h6>
                    </div>
                  </div>
                )}
                {customerMembershipOnHold && activePause && (
                  <div className="flex flex-row justify-between items-center mt-5">
                    <div className="flex flex-col">
                      <p className="text-sm">Pause Schedule</p>
                    </div>
                    <div className="flex flex-row gap-2.5">
                      <h6 className="text-base">
                        {moment(activePause.startDateUTC).format('Do MMM YY')} to{' '}
                        {moment(activePause.endDateUTC).format('Do MMM YY')}
                      </h6>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {customerSessionPacksAndGifts && (
            <div className="acc-row flex flex-col px-6 py-5">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col">
                  <p className="text-sm">Session Packs</p>
                  <h6 className="text-base">{sessionPackCount}</h6>
                </div>
                <div className="flex flex-row gap-2.5">
                  <button
                    className="bg-xtraWhite30 rounded-full text-sm"
                    onClick={() => navigate('/customer-session-packs')}
                  >
                    View All
                  </button>
                </div>
              </div>
            </div>
          )}
          {customerMembership && (
            <div className="acc-row flex flex-col px-6 py-5">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col">
                  <p className="text-sm">Peak sessions</p>
                  {!isThrive ? (
                    <h6 className="text-base">
                      {customerEntitlements?.['Peak-Hour Reservations'] ?? 0 + peakSessionPurchased?.length ?? 0}
                    </h6>
                  ) : (
                    <p className="text-white font-bold">Unlimited</p>
                  )}
                </div>
                <div className="flex flex-row gap-2.5">
                  {serviceLoading.peakSession ? (
                    <div>
                      <CircularProgressBar width={30} height={30} marginTop="" />
                    </div>
                  ) : (
                    <div className="flex flex-row gap-2.5">
                      <button
                        className="rounded-full text-sm"
                        onClick={() => {
                          showPeakSessionDrawer(true);
                        }}
                      >
                        Options
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {!isThrive && customerEntitlements?.['Number of Additional Peak Visits Will Be Removed'] > 0 && (
                <p className="text-xs mt-3">
                  Number of additional visits to be removed:{' '}
                  <b>{customerEntitlements?.['Number of Additional Peak Visits Will Be Removed']}</b>
                </p>
              )}
            </div>
          )}
          <div className="acc-row flex flex-col px-6 py-5">
            <p className="text-sm">Off-peak sessions</p>
            <h6 className="text-base">{customerEntitlements?.['Unlimited Off-Peak'] ? 'Unlimited' : 'Limited'}</h6>
          </div>
          <div className="acc-row flex flex-col px-6 py-5">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col">
                <p className="text-sm">Session duration</p>
                {!isThrive ? (
                  <h6 className="text-base">
                    {customerMembership ? customerEntitlements?.['Session Duration'] : '90'} minutes
                  </h6>
                ) : (
                  <p className="text-white font-bold">Unlimited</p>
                )}
              </div>
              {customerMembership && (
                <div className="flex flex-row gap-2.5">
                  {serviceLoading.sessionDuration ? (
                    <div>
                      <CircularProgressBar width={30} height={30} marginTop="" />
                    </div>
                  ) : (
                    <div className="flex flex-row gap-2.5">
                      {!customerMembershipHasExtendedSession
                        ? !isThrive && (
                            <button className="rounded-full text-sm" onClick={handleSessionChangeClick}>
                              Increase
                            </button>
                          )
                        : !customerEntitlements?.['Extended Session Time Will Be Removed'] && (
                            <button
                              className="rounded-full bg-transparent border border-xtraWhite30 text-sm"
                              onClick={handleSessionChangeClick}
                            >
                              Subtract
                            </button>
                          )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {!isThrive && customerEntitlements?.['Extended Session Time Will Be Removed'] === true && (
              <p className="text-xs mt-3">Extended session time will be removed</p>
            )}
          </div>
          <div className="acc-row flex flex-col px-6 py-5">
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-col">
                <p className="text-sm">Towel service</p>
                <h6 className="text-base">{customerEntitlements?.['Towel Service'] ? 'Included' : 'Not Included'}</h6>
              </div>
              {serviceLoading.towelService ? (
                <div>
                  <CircularProgressBar width={30} height={30} marginTop="" />
                </div>
              ) : (
                customerMembership && (
                  <div className="flex flex-row gap-2.5">
                    {!isThrive && customerEntitlements?.['Towel Service'] ? (
                      <button
                        className="rounded-full bg-transparent border border-xtraWhite30 text-sm"
                        onClick={handleTowelChangeClick}
                      >
                        Downgrade
                      </button>
                    ) : (
                      !isThrive && (
                        <button className="rounded-full text-sm" onClick={handleTowelChangeClick}>
                          Upgrade
                        </button>
                      )
                    )}
                  </div>
                )
              )}
            </div>
            {customerEntitlements?.['Towel Service Will Be Removed'] === true && (
              <p className="text-xs mt-3">Towel service will be removed</p>
            )}
            {!customerMembership && (
              <p className="text-xs mt-3">
                The towel service is only available for our subscribed members. Purchase a membership to gain greater
                access to our facilities.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="px-5">
          <p>Sorry, your subscription details were not able to be retrieved. Please try again.</p>
        </div>
      )}

      {/* Conditionally render the UpdateCardForm component as a modal */}
      {showCardForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-xtraNavy rounded-lg p-6 w-11/12 max-w-md relative">
            <h2 className="text-xl font-bold text-center mb-2">Update Card Details</h2>
            <p className="text-center text-white mb-6">
              Your current card is not valid. Please update your card details to continue. Once you have updated your
              card, please try again.
            </p>
            <UpdateCardForm onSuccess={() => setShowCardForm(false)} onFailure={() => setShowCardForm(false)} />
            <button
              className="absolute top-2 right-2 text-white hover:text-white"
              onClick={() => setShowCardForm(false)}
            >
              &times;
            </button>
          </div>
        </div>
      )}

      {/* Error drawer to trigger UpdateCardForm if needed */}
      <ErrorSlideUpDrawer
        setShow={setErrorSlideUp}
        show={errorSlideUp}
        action={() => setShowCardForm(true)} // Directly toggle showCardForm
        actionText="Update Card Details"
        actionHeading="Update Card Details"
        message="Looks like your payment did not go through successfully. Please update your card details to proceed."
        actionRightAway={true}
      />
      <SlideUpDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        action={selectedOption.action}
        actionText={selectedOption.text}
        actionClass={selectedOption}
        message={popupMessage ?? ''}
        loading={!popupMessage}
      />
      <RestartSlideUpDrawer
        setShow={setShowRestartDrawer}
        show={showRestartDrawer}
        action={restartMembership}
        actionText={'Re-Activate Membership'}
        actionHeading={'Re-Activate membership'}
        message={
          'By re-activating, your membership will be started again from today and you will be able to reserve a session time and access Xtra Clubs. Continue?'
        }
        actionRightAway={true}
      />
      <MembershipOptionsDrawer
        setShow={showMemebshipDrawer}
        show={membershipDrawer}
        activeMembership={activeMembership}
        setShowRestartDrawer={setShowRestartDrawer}
      />
      <PeakSessionsDrawer
        setShow={showPeakSessionDrawer}
        show={peakSessionDrawer}
        activeMembership={activeMembership}
        membershipDetails={{ entitlements: customerEntitlements }}
        peakSessionPurchased={peakSessionPurchased}
        handlePeakSessionClick={handlePeakSessionClick}
        handlePurchaseSinglePeakVisit={() => {
          showPeakSessionDrawer(false);
          setPeakVisitLoading(true);
          handlePurchaseSinglePeakVisit({
            returnPrice: 1,
            setDisplaySinglePeakVisitPurchase,
            setPeakVisitPurchaseLoading,
            setPeakVisitLoading,
            setSinglePeakVisitInfo,
            successFunctionCalls: () => getCustomer({ update: true }),
          });
        }}
      />
      {displaySinglePeakVisitPurchase && (
        <SlideUpDrawer
          setShow={setDisplaySinglePeakVisitPurchase}
          show={displaySinglePeakVisitPurchase}
          action={() => {
            handlePurchaseSinglePeakVisit({
              returnPrice: 0,
              setDisplaySinglePeakVisitPurchase,
              setPeakVisitPurchaseLoading,
              setPeakVisitLoading,
              setSinglePeakVisitInfo,
              successFunctionCalls: () => getCustomer({ update: true }),
            });
          }}
          actionText={`Pay ${singlePeakVisitInfo?.price}`}
          actionHeading={'Add a once-off peak visit'}
          message={`${singlePeakVisitInfo?.message}`}
          actionRightAway={false}
          loading={peakVisitLoading}
        />
      )}
    </div>
  );
};

export default MembershipDetails;
