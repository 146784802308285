import React, { useEffect, useState, useCallback } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import getProduct from '../constants/product';
import CircularProgressBar from '../components/CircularProgressBar';

const ProductNoAuth = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sku = queryParams.get('sku');
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);

  const retrieveProduct = useCallback(async () => {
    const product = await getProduct(sku);
    if (product?.success) {
      setProduct(product.data);
    }
    setLoading(false);
  }, [sku]);

  useEffect(() => {
    retrieveProduct();
  }, [retrieveProduct]);

  const handleBuyClick = async () => {
    window.open('https://xtraclubs.au/my-account/', '_blank');
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover p-6 overflow-y-auto"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row items-center mb-4">
        <h6>Product</h6>
      </div>
      {loading && <CircularProgressBar />}
      {!loading && (
        <>
          {!product ? (
            <p>Product could not be found. Please go back and scan again.</p>
          ) : (
            <>
              <div className="flex flex-col overflow-scroll max-h-[54vh]">
                <img src={product.featured_image_url} className="w-full h-80 mb-2.5 rounded-lg" alt="feature" />
                <div className="px-2.5">
                  <h5 className="mb-0.5">
                    {product.name}
                    <span className="text-sm ml-2.5">${product.price}</span>
                  </h5>
                  <p>{product.description}</p>
                </div>
              </div>
              <div className="fixed bottom-14 left-0 right-0 z-10 px-6">
                <div className="py-3 pl-5 pr-14 flex flex-row rounded-lg gap-1 mt-2.5  text-sm bg-xtraWhite20 px-2.5">
                  <div className="flex justify-center items-center rounded-full bg-xtraOrange text-white w-4 h-4">
                    <FontAwesomeIcon icon={faExclamation} size="xs" />
                  </div>
                  <p className="flex-1 text-sm">Our fridge is under surveillance, only take what you pay for</p>
                </div>
                <div className="flex flex-row justify-between items-center mt-2.5 mb-5 px-2.5">
                  <h2
                    className="flex justify-center items-center w-16 h-16 rounded-full bg-xtraWhite20"
                    onClick={() => setQuantity(quantity - 1 ? quantity - 1 : 1)}
                  >
                    -
                  </h2>
                  <div className="flex flex-col items-center justify-center">
                    <h3>{quantity}</h3>
                    <h5 className="text-xs">Quantity</h5>
                  </div>
                  <h2
                    className="flex justify-center items-center w-16 h-16 rounded-full bg-xtraWhite20"
                    onClick={() => setQuantity(quantity + 1)}
                  >
                    +
                  </h2>
                </div>
                <div className="px-2.5">
                  <button className="w-full" onClick={handleBuyClick}>
                    $
                    {(Number(product.price) * quantity).toLocaleString('en-AU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    - Open the App to Buy
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {/* <ErrorSlideUpDrawer
        setShow={setErrorSlideUp}
        show={errorSlideUp}
        action={() => {
          handleUpdatePaymentMethod();
        }}
        actionText={'Update Card Details'}
        actionHeading={`Update Card Details`}
        message={`Look's like your payment did not go through successfully, to continue with your purchase you will be required to update your card details. Once you have done so, try the purchase again.`}
        actionRightAway={true}
      /> */}
    </div>
  );
};

export default ProductNoAuth;
