import React, { useEffect, useState } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import { useNavigate } from 'react-router-dom';
import ListTimeSlots from '../components/ListTimeSlots';
import Api from '../components/Api';
import moment from 'moment-timezone';
import CircularProgressBar from '../components/CircularProgressBar';
import SafeAreaHeader from '../components/SafeAreaHeader';
import { toast } from 'react-toastify';
import Select from 'react-select';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import CustomOption from '../renderFunctions/customOption';
import customFormatOptionLabel from '../renderFunctions/customFormatOptionLabel';
import generateDates from '../constants/generateDates';
import SlideUpDrawer from '../components/SlideUpDrawer';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import notifySwift from '../constants/notifySwift';
import bluePlus from '../images/blue-plus.png';
import handlePurchaseSinglePeakVisit from '../constants/handlePurchaseSinglePeakVisit';
import RestartPause from '../components/RestartPause';
import RetryPayment from '../components/RetryPayment';
import getSite from '../constants/getSite';
import { useCustomer } from '../contexts/CustomerContext';
import { useAppSettings } from '../contexts/AppSettingsContext';

const Reserve = () => {
  const navigate = useNavigate();
  const { appSettings, getAppSettings } = useAppSettings();
  const { customer, getCustomer, customerLoading } = useCustomer();
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [purchase, setPurchase] = useState(false);
  const [entitlements, setEntitlements] = useState(null);
  const [isoWeekPeakLimits, setIsoWeekPeakLimits] = useState({}); // State variable for ISO week peak limits
  const [isoWeeks, setIsoWeeks] = useState({});
  const [reservationType, setReservationType] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [previousCustomerReservations, setPreviousReservations] = useState([]);
  const [subscriptionSelectOpen, setSubscriptionSelectOpen] = useState(false);
  const [subOnHold, setSubOnHold] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [reservationAttemptId, setReservationAttemptId] = useState();
  const [peakVisitLoading, setPeakVisitLoading] = useState(false);
  const [peakVisitPurchaseLoading, setPeakVisitPurchaseLoading] = useState(false);
  const [displaySinglePeakVisitPurchase, setDisplaySinglePeakVisitPurchase] = useState(false);
  const [singlePeakVisitInfo, setSinglePeakVisitInfo] = useState(null);
  const [site, setSite] = useState();
  const [membershipOnHoldType, setMembershipOnHoldType] = useState(null);
  const [pauseEndDate, setPauseEndDate] = useState('');
  const [membershipPauseDetails, setMembershipPauseDetails] = useState(null);
  const [notices, setNotices] = useState([]);
  const siteSelected = '65c468e98af2a3e02af945ae';
  const getReservationAttempt = async () => {
    const response = await Api('/customer/reservationattempt', 'GET');

    if (response?.status === 200 && response.data?.reservationAttemptId) {
      setReservationAttemptId(response.data.reservationAttemptId);
    }
  };

  const getSelectedSite = async () => {
    const site = await getSite(siteSelected);
    setSite(site);
  };

  useEffect(() => {
    getReservationAttempt();
  }, []);

  useEffect(() => {
    generateDates({
      setTimeSlots,
      setLoading,
      setPurchase,
      setEntitlements,
      setIsoWeekPeakLimits,
      setIsoWeeks,
      setTypeOptions,
      setPreviousReservations,
      updateReservationType,
      setSubOnHold,
      reservationType,
    });
  }, [reservationType]);

  useEffect(() => {
    getSelectedSite();
  }, []);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  useEffect(() => {
    getAppSettings();
  }, [getAppSettings]);

  const handleClickTimeslot = async (date, time, peak) => {
    var reservations = [...selectedReservations];
    const isoWeekOfSelectedDate = moment(date).isoWeek();

    var isThrive = reservationType.type === 'subscription' && reservationType.value.includes('Thrive');
    var isSessionPack = reservationType && reservationType.type === 'subscription' ? false : true;
    let packLimit = reservationType.sessionMaximum;

    if (reservationType.sessionsUsed !== '') {
      packLimit = reservationType.sessionsUsed;
    }

    let peakLimit = isThrive ? 9999 : isoWeekPeakLimits[isoWeekOfSelectedDate] || 0;

    const existingReservationIndex = reservations.findIndex(
      (reservation) => reservation.date === date && reservation.time === time
    );

    if (existingReservationIndex !== -1) {
      // If the same timeslot is already selected, remove it
      reservations.splice(existingReservationIndex, 1);
    } else {
      if (isSessionPack) {
        if (reservations.length === packLimit) {
          renderToastError({
            message: `Sorry, you have reached the maximum number of sessions for the selected session pack: ${reservationType.label}.`,
          });
          return;
        }
      } else {
        let peakSelectedCount = reservations.filter(
          (reservation) => moment(reservation.date).isoWeek() === isoWeekOfSelectedDate && reservation.peak === 'Peak'
        ).length;

        if (peak === 'Peak' && peakSelectedCount >= peakLimit) {
          const currentIsoWeek = moment().isoWeek();
          const isoWeekMessage = isoWeekOfSelectedDate === currentIsoWeek ? 'this week' : 'next week';
          renderToastError({
            message: `Sorry, you have already selected the total number of peak sessions for ${isoWeekMessage}.`,
          });
          setPeakVisitLoading(true);
          handlePurchaseSinglePeakVisit({
            returnPrice: 1,
            setDisplaySinglePeakVisitPurchase,
            setPeakVisitPurchaseLoading,
            setPeakVisitLoading,
            setSinglePeakVisitInfo,
            siteSelected,
            successFunctionCalls: () => {
              getCustomer({ update: true });
              generateDates({
                setTimeSlots,
                setLoading,
                setPurchase,
                setEntitlements,
                setIsoWeekPeakLimits,
                setIsoWeeks,
                setTypeOptions,
                setPreviousReservations,
                updateReservationType,
                setSubOnHold,
              });
            },
          });
          return;
        }
      }

      // Check if there are existing reservations within 3 hours of the selected time
      const existingReservationsWithinFourHours = reservations.some(
        (reservation) =>
          moment(reservation.date).isSame(date, 'day') &&
          moment(reservation.time, 'HH:mm').diff(moment(time, 'HH:mm'), 'hours', true) <= 3 &&
          moment(reservation.time, 'HH:mm').diff(moment(time, 'HH:mm'), 'hours', true) >= -3
      );

      if (existingReservationsWithinFourHours) {
        renderToastError({
          message: 'Sorry, consecutive sesions cannot be selected within 3 hours.',
        });
        return;
      }

      // Check if there are existing reservations within 3 hours of the selected time in customerReservations
      const existingReservationsWithinFourHoursCustomer = previousCustomerReservations.some(
        (reservation) =>
          moment(reservation.date).isSame(date, 'day') &&
          moment(reservation.time, 'HH:mm').diff(moment(time, 'HH:mm'), 'hours', true) <= 3 &&
          moment(reservation.time, 'HH:mm').diff(moment(time, 'HH:mm'), 'hours', true) >= -3
      );

      if (existingReservationsWithinFourHours || existingReservationsWithinFourHoursCustomer) {
        renderToastError({
          message: 'Sorry, consecutive timeslots cannot be selected within 3 hours.',
        });
        return;
      }

      // Add the new timeslot
      reservations.push({
        site: siteSelected,
        date: date,
        time: time,
        peak: peak,
      });

      toast.dismiss('ErrorToast');
    }

    setSelectedReservations(reservations);
  };

  const formatOptionLabel = (props) => {
    return customFormatOptionLabel({
      subscriptionSelectOpen,
      isoWeeks,
      isoWeekPeakLimits,
      entitlements,
      props,
    });
  };

  const updateReservationType = (event) => {
    var selectedOption = event;

    setReservationType(selectedOption);
    setSelectedReservations([]);
  };

  const handleCompleteReservations = async () => {
    try {
      const reservation = await Api('/schedule', 'post', {
        reservations: selectedReservations,
        reservationType: reservationType,
        reservationAttemptId: reservationAttemptId,
      });

      if (reservation.status === 200) {
        const result = reservation.data;
        if (result.success && result.data.queuedReservations.length > 0) {
          navigate('/reservation-confirmed', {
            state: {
              reservations: result.data.queuedReservations,
            },
          });
        }
      } else {
        renderToastError({
          message: 'There was an error completing your reservation.',
        });
      }
    } catch (error) {
      renderToastError({
        message: 'There was an error completing your reservation.',
      });
    }
  };

  const restartMembership = async () => {
    const restart = await Api('/customer/membership/restart', 'GET');
    if (restart.status === 200) {
      let result = restart.data;
      if (result.success) {
        renderToastSuccess({
          message: result.message,
        });
        generateDates({
          setTimeSlots,
          setLoading,
          setPurchase,
          setEntitlements,
          setIsoWeekPeakLimits,
          setIsoWeeks,
          setTypeOptions,
          setPreviousReservations,
          updateReservationType,
          setSubOnHold,
          getFromWP: true,
        });
      } else {
        renderToastError({
          message: result.message,
        });
      }
    } else {
      renderToastError({
        message: 'Unable to restart your subscription. Please contact our support for further instructions.',
      });
    }
    setShowDrawer(false);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const completeEnabled = selectedReservations?.length > 0;

  const retryPayment = async () => {
    setLoading(true);
    const response = await Api(
      `/customer/membership/failed-order?returnFailed=0&order_id=${membershipPauseDetails.id}`,
      'GET'
    );

    console.log('rror: ', response);

    if (response?.status === 200 && response.data?.charged) {
      renderToastSuccess({
        message: response.data.message,
      });
      generateDates({
        setTimeSlots,
        setLoading,
        setPurchase,
        setEntitlements,
        setIsoWeekPeakLimits,
        setIsoWeeks,
        setTypeOptions,
        setPreviousReservations,
        updateReservationType,
        setSubOnHold,
        getFromWP: true,
      });
    } else {
      renderToastError({
        message: response?.data.message || 'An error occured, please try again.',
      });
    }
  };

  useEffect(() => {
    if (subOnHold) {
      setLoading(true);

      const checkOnHoldStatus = async () => {
        const response = await Api('/customer/membership/pause/active', 'GET', null);

        const activePause = response?.data?.pause;

        if (activePause) {
          setPauseEndDate(activePause.endDateUTC);
          setMembershipOnHoldType('pause');
        } else {
          await getMembershipPauseReason();
          setMembershipOnHoldType('payment');
        }

        setLoading(false);
      };

      const getMembershipPauseReason = async () => {
        const response = await Api(`/customer/membership/failed-order?returnFailed=1`, 'GET');

        if (response?.status === 200 && response.data?.last_failed_order) {
          setMembershipPauseDetails(response.data.last_failed_order);
        } else {
          if (response?.status === 200 && response.data.success) {
            setSubOnHold(false);
            setMembershipOnHoldType(null);
          } else if (!response?.data?.success) {
            setMembershipPauseDetails(response.data);
          } else {
            renderToastError({
              message: response?.response.data.message || 'An error occured, please try again.',
            });
          }
        }
      };

      checkOnHoldStatus(subOnHold);
    }
  }, [subOnHold]);

  useEffect(() => {
    if (appSettings?.notices) {
      const updatedNotices = appSettings.notices ? [...appSettings.notices] : [];
      if (site) {
        const currentDayName = moment().format('dddd');
        const today = site.openHours[currentDayName];
        const finish = today.finish;

        const startTime = moment(finish, 'HH:mm');
        const endTime = moment(startTime).add(90, 'minutes');
        const currentTime = moment();

        const isWithinTimeWindow = currentTime.isBetween(startTime, endTime);

        updatedNotices.push({
          description: `All guests are required to leave the club before ${endTime.format(
            'hh:mma'
          )}. Failure to do so will incur fees and penalties. Max penalty $1,000.`,
          disabled: !isWithinTimeWindow,
          title: 'Club Closing',
        });
        setNotices(updatedNotices);
      }
      setNotices(updatedNotices);
    }
  }, [appSettings, site]);

  const showNotices = notices?.some((notice) => !notice.disabled);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover p-6 pb-32"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex flex-row justify-between items-center" style={{ position: 'relative' }}>
        <div className="flex flex-row justify-start items-center">
          <BackButton />
          <h5>Reserve a session</h5>
        </div>
        {membershipOnHoldType === null && reservationType && reservationType.type === 'subscription' && (
          <div>
            <button
              onClick={() => {
                setPeakVisitLoading(true);
                handlePurchaseSinglePeakVisit({
                  returnPrice: 1,
                  setDisplaySinglePeakVisitPurchase,
                  setPeakVisitPurchaseLoading,
                  setPeakVisitLoading,
                  setSinglePeakVisitInfo,
                  siteSelected,
                  successFunctionCalls: () => {
                    getCustomer({ update: true });
                    generateDates({
                      setTimeSlots,
                      setLoading,
                      setPurchase,
                      setEntitlements,
                      setIsoWeekPeakLimits,
                      setIsoWeeks,
                      setTypeOptions,
                      setPreviousReservations,
                      updateReservationType,
                      setSubOnHold,
                    });
                  },
                });
              }}
              className="py-2 pl-2 pr-3 text-sm rounded-full flex flex-row"
            >
              <img src={bluePlus} alt="blue plus" className="w-5 h-5 mr-1" />
              Peak
            </button>
          </div>
        )}
      </div>
      {loading || customerLoading ? (
        <div className="">
          <CircularProgressBar />
        </div>
      ) : (
        <>
          {timeSlots.length === 0 || !reservationType ? (
            <div className="flex flex-col min-h-[80vh] justify-between">
              <p>
                {membershipOnHoldType !== null && membershipOnHoldType === 'pause' ? (
                  <div className="mt-2">
                    <RestartPause restartMembership={setShowDrawer} endPauseDate={pauseEndDate} />
                  </div>
                ) : membershipOnHoldType !== null && membershipOnHoldType === 'payment' ? (
                  <RetryPayment
                    retryPayment={retryPayment}
                    customerBillingDetails={customer}
                    membershipPauseDetails={membershipPauseDetails}
                  />
                ) : !reservationType ? (
                  <>
                    You currently don't have a Subscription or Session Pack that is active, please purchase one to be
                    able to continue reserving your session. <br /> <br /> After purchasing a subscription or session
                    pack, press the refresh button at the bottom to retrieve the session times.
                  </>
                ) : (
                  'Please try again or contact us for assistance.'
                )}
              </p>
              {membershipOnHoldType === null && (
                <div className="flex flex-col p-4 reservedCompleteSection">
                  <button
                    onClick={() => {
                      if (purchase || !reservationType) {
                        let path = '/become-a-member/?hideDetails=true';
                        let token = localStorage.getItem('accessToken');
                        let url = `${process.env.REACT_APP_WP_ACCESS_PAGE}`;
                        notifySwift({
                          function: 'openInternalLink',
                          data: {
                            url: `${url}?token=${token}&path=${path}`,
                          },
                        });
                      } else {
                        window.location.reload();
                      }
                    }}
                  >
                    {purchase || !reservationType ? 'Become a member' : 'Try again'}
                  </button>
                  {purchase ||
                    (!reservationType && (
                      <button
                        className="mt-3"
                        onClick={async () => {
                          let path = 'https://xtraclubs.au/book-session';
                          let token = localStorage.getItem('accessToken');
                          notifySwift({
                            function: 'openInternalLink',
                            data: {
                              url: `${path}?token=${token}`,
                            },
                          });
                        }}
                      >
                        Book a session
                      </button>
                    ))}
                  {!reservationType && (
                    <button
                      className="blueBorder mt-3 "
                      onClick={() => {
                        setLoading(true);
                        generateDates({
                          setTimeSlots,
                          setLoading,
                          setPurchase,
                          setEntitlements,
                          setIsoWeekPeakLimits,
                          setIsoWeeks,
                          setTypeOptions,
                          setPreviousReservations,
                          updateReservationType,
                          setSubOnHold,
                        });
                      }}
                    >
                      Refresh Time Slots
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className={`overflow-scroll ${windowSize.height > 667 ? 'max-h-[75vh]' : 'max-h-[80vh]'}`}>
              {showNotices && (
                <div className="mt-2">
                  {notices.map((notice, index) =>
                    notice.disabled ? null : (
                      <div key={index} className="bg-xtraNavy rounded-lg p-4 flex flex-col">
                        <div className="flex-grow-0 flex flex-row relative rounded-3xl bg-xtraOrange py-0.5 justify-center text-center items-center max-w-24 mb-2">
                          <p className="text-sm font-bold items-center">Notice</p>
                        </div>
                        <h6>{notice.title}</h6>
                        <p className="text-sm mt-1">{notice.description}</p>
                      </div>
                    )
                  )}
                </div>
              )}
              <div className="flex flex-col mb-5">
                <Select
                  isSearchable={false}
                  className={`mt-5 ${
                    reservationType && reservationType.valueType === 'subscription'
                      ? 'subscriptionSelect'
                      : 'packSelect'
                  }`}
                  defaultValue={
                    typeOptions.find((option) => option.type === 'subscription')
                      ? typeOptions.find((option) => option.type === 'subscription')
                      : typeOptions[0]
                  }
                  formatOptionLabel={formatOptionLabel}
                  options={typeOptions}
                  onChange={(event) => {
                    updateReservationType(event);
                  }}
                  onMenuOpen={() => {
                    setSubscriptionSelectOpen(true);
                  }}
                  onMenuClose={() => {
                    setSubscriptionSelectOpen(false);
                  }}
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: () => {
                      return reservationType && reservationType.type === 'subscription' ? null : (
                        <FontAwesomeIcon
                          size="sm"
                          className="text-white px-2"
                          style={subscriptionSelectOpen && { color: 'transparent' }}
                          icon={faChevronDown}
                        />
                      );
                    },
                    IndicatorSeparator: ({ innerProps }) => {
                      return reservationType && reservationType.type === 'subscription' ? null : (
                        <span className="self-stretch bg-white my-2 w-[1px]" {...innerProps} />
                      );
                    },
                  }}
                />
              </div>
              {peakVisitPurchaseLoading && (
                <div class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
                  <CircularProgressBar />
                </div>
              )}
              <ListTimeSlots
                onClickTimeSlot={(date, time, peak) => handleClickTimeslot(date, time, peak)}
                reservationsSelected={selectedReservations}
                timeSlots={timeSlots}
                subscriptionType={reservationType && reservationType.type === 'subscription' ? true : false}
              />
              <div className="fixed bottom-6 left-0 right-0 shadow-md flex flex-col p-4 reservedCompleteSection tabBarBackground">
                <button
                  style={{ opacity: completeEnabled ? 1 : 0.5 }}
                  disabled={!completeEnabled}
                  onClick={handleCompleteReservations}
                >
                  Complete reservation
                </button>
              </div>
            </div>
          )}
        </>
      )}
      <SlideUpDrawer
        setShow={setShowDrawer}
        show={showDrawer}
        action={restartMembership}
        actionText={'Re-Activate Membership'}
        actionHeading={'Re-Activate membership'}
        message={
          'By re-activating, your membership will be started again from today and you will be able to reserve a session time and access Xtra Clubs. Continue?'
        }
        actionRightAway={true}
      />
      {displaySinglePeakVisitPurchase && (
        <SlideUpDrawer
          setShow={setDisplaySinglePeakVisitPurchase}
          show={displaySinglePeakVisitPurchase}
          action={() =>
            handlePurchaseSinglePeakVisit({
              returnPrice: 0,
              setDisplaySinglePeakVisitPurchase,
              setPeakVisitPurchaseLoading,
              setPeakVisitLoading,
              setSinglePeakVisitInfo,
              siteSelected,
              successFunctionCalls: () => {
                getCustomer({ update: true });
                generateDates({
                  setTimeSlots,
                  setLoading,
                  setPurchase,
                  setEntitlements,
                  setIsoWeekPeakLimits,
                  setIsoWeeks,
                  setTypeOptions,
                  setPreviousReservations,
                  updateReservationType,
                  setSubOnHold,
                });
              },
            })
          }
          actionText={`Pay ${singlePeakVisitInfo?.price}`}
          actionHeading={'Add a once-off peak visit'}
          message={`${singlePeakVisitInfo?.message}`}
          actionRightAway={false}
          loading={peakVisitLoading}
        />
      )}
    </div>
  );
};

export default Reserve;
