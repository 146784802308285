const FutureReservationsExist = (reservationsForDate, date, todayDate) => {
  return reservationsForDate.some((reservation) => {
    if (date === todayDate) {
      const start = parseFloat(reservation.time);
      const end = start + 0.5;

      const d = new Date();
      const currentHour = d.getHours();
      const currentMinute = d.getMinutes();
      const currentTime = currentHour + currentMinute / 60;

      if (currentTime < end) {
        return true;
      } else {
        return false;
      }
    } else {
      return reservationsForDate.length > 0;
    }
  });
};

export default FutureReservationsExist;
