import Api from '../components/Api';

const getCustomerSubscription = async (wp = false) => {
  const accessToken = localStorage.getItem('accessToken');
  let customerAuth0Details = await Api('/customer/auth0', 'POST', {
    token: accessToken,
  });
  let isXtra = false;
  if (customerAuth0Details.data?.success && customerAuth0Details.data?.customer.email.includes('@xtraclubs.au')) {
    if (customerAuth0Details.data.customer.email === 'development@xtraclubs.au') {
      isXtra = false;
    } else {
      isXtra = true;
    }
  }

  if (isXtra) {
    return {
      userSubscriptions: [],
    };
  } else {
    console.log('wp : ', wp);
    const subscription = await Api(`/customer/subscription?wp=${wp}`, 'GET');

    if (subscription.status === 200) {
      const result = subscription.data;
      if (result.message === undefined) {
        if (result.success || result.userSubscriptions) {
          return result;
        } else {
          return result.subscription;
        }
      } else {
        return result;
      }
    } else {
      return null;
    }
  }
};

export default getCustomerSubscription;
