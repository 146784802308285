import React, { useState, useEffect } from 'react';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import xtraTick from '../images/xtra-tick.png';
import notifySwift from '../constants/notifySwift';
import memberOnboardingImg from '../images/memberOnboarding.png';
import memberOnboardingSquareImg from '../images/memberOnboardingSquare.png';
import memberAgreement from '../images/memberAgreement.png';
import locationPermissionsImg from '../images/locationPermissions.png';
import locationPermissionsSquareImg from '../images/locationPermissionsSquare.png';
import bluetoothPermissionsImg from '../images/bluetoothPermissions.png';
import pushNotificationsImg from '../images/pushNotifications.png';
import CircularProgressBar from '../components/CircularProgressBar';
import { useNavigate } from 'react-router-dom';
import { updateCustomerRequirements } from '../constants/updateCustomerRequirements';
import Api from '../components/Api';
import allValuesTrue from '../constants/allValuesTrue';
import AccountIcon from '../components/AccountIcon';
import BackButton from '../components/BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faGear } from '@fortawesome/free-solid-svg-icons';
import isCasual from '../constants/isCasual';
import { useCustomer } from '../contexts/CustomerContext';

const Welcome = ({ isPermissionsRoute = false }) => {
  const navigate = useNavigate();
  const { customer, getCustomer, customerLoading } = useCustomer();
  const [loading, setLoading] = useState(true);
  const [notificationLoading, setNotificationLoading] = useState(false);

  useEffect(() => {
    if (customer && allValuesTrue(customer.requirements)) {
      Api('/customer/details/mongo', 'PUT', { initialComplete: true });
    }
  }, [customer]);

  useEffect(() => {
    getCustomer();
    setLoading(false);

    window.addEventListener('LocationPermissions', (e) =>
      updateCustomerRequirements({
        type: 'location',
        data: e,
        retrieveCustomer: () => getCustomer(),
      })
    );
    window.addEventListener('BluetoothPermissions', (e) =>
      updateCustomerRequirements({
        type: 'bluetooth',
        data: e,
        retrieveCustomer: () => getCustomer(),
      })
    );
    window.addEventListener('PushNotifications', (e) =>
      updateCustomerRequirements({
        type: 'notifications',
        data: e,
        retrieveCustomer: () => {
          setNotificationLoading(false);
          getCustomer();
        },
      })
    );

    return () => {
      window.removeEventListener('LocationPermissions', (e) =>
        updateCustomerRequirements({
          type: 'location',
          data: e,
          retrieveCustomer: () => getCustomer(),
        })
      );
      window.removeEventListener('BluetoothPermissions', (e) =>
        updateCustomerRequirements({
          type: 'bluetooth',
          data: e,
          retrieveCustomer: () => getCustomer(),
        })
      );
      window.removeEventListener('PushNotifications', (e) =>
        updateCustomerRequirements({
          type: 'notifications',
          data: e,
          retrieveCustomer: () => {
            setNotificationLoading(false);
            getCustomer();
          },
        })
      );
    };
  }, [getCustomer]);

  const requirements = [
    {
      id: 'memberOnboarding',
      image: memberOnboardingImg,
      title: 'Club rules & terms',
      description: 'To make our club a safe and inclusive space for every guest.',
      complete: customer?.requirements?.memberOnboarding,
      completeTitle: 'Club rules & terms',
      completeImage: memberOnboardingSquareImg,
      onClick: () => navigate('/member-onboarding/1'),
      required: true,
    },
    {
      id: 'memberHealthCheck',
      image: memberAgreement,
      title: 'Health check',
      description: 'The finer details of our agreement for you to review before you get started.',
      complete: customer?.requirements?.memberAgreement && customer?.requirements?.memberHealthCheck,
      completeTitle: 'Health check',
      completeImage: memberAgreement,
      onClick: () => navigate('/member-agreement'),
      required: true,
    },
    {
      id: 'locationPermissions',
      image: locationPermissionsImg,
      title: 'Allow location permissions',
      description:
        "Using your location will make it easier to gain access to the doors of Xtra Clubs. Xtra Clubs doesn't store or share your location data",
      complete: customer?.requirements?.locationPermissions,
      completeTitle: 'Location permissions',
      completeImage: locationPermissionsSquareImg,
      onClick: () => {
        notifySwift({
          function: 'promptLocationPermissions',
          data: {},
        });
        notifySwift({
          function: 'checkLocationPermissions',
        });
      },
      isPermission: true,
      disabled: isCasual,
    },
    {
      id: 'bluetoothPermissions',
      image: bluetoothPermissionsImg,
      title: 'Allow bluetooth permission',
      description: 'The doors at our clubs use bluetooth to open. Bluetooth is required for access to Xtra Clubs.',
      complete: customer?.requirements?.bluetoothPermissions,
      completeTitle: 'Bluetooth permissions',
      completeImage: bluetoothPermissionsImg,
      onClick: () => {
        notifySwift({
          function: 'promptBluetoothPermissions',
          data: {},
        });
        notifySwift({
          function: 'checkBluetoothPermissions',
        });
      },
      required: false,
      isPermission: true,
      disabled: isCasual,
    },
    {
      id: 'pushNotifications',
      image: pushNotificationsImg,
      title: 'Allow push notifications',
      description:
        'Push notifications are used to notify you of any charges made to your membership. We do not use notifications for marketing purposes.',
      complete: customer?.requirements?.pushNotifications,
      completeTitle: 'Push notifications',
      completeImage: pushNotificationsImg,
      onClick: () => {
        setNotificationLoading(true);
        notifySwift({
          function: 'promptPushNotifications',
          data: {},
        });
        notifySwift({
          function: 'checkPushNotifications',
        });
      },
      onClickTitle: 'Enable Push Notifications',
      required: false,
      isPermission: true,
      disabled: isCasual,
    },
  ];

  const handleAccountClick = async () => {
    navigate('/you');
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-cover bg-center p-6 pb-9"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      {loading || customerLoading ? (
        <div className="flex flex-col justify-center">
          <CircularProgressBar />
        </div>
      ) : (
        <>
          {isPermissionsRoute ? (
            <div className="flex flex-row justify-between items-center mb-4 mt-1 z-10">
              <div className="flex flex-row justify-start items-center">
                <BackButton />
                <h6>Permissions</h6>
              </div>
              <button
                className="rounded-full p-1.5"
                onClick={() =>
                  notifySwift({
                    function: 'openAppSettings',
                  })
                }
              >
                <FontAwesomeIcon icon={faGear} size="xl" className="text-white w-6 h-6" />
              </button>
            </div>
          ) : (
            <div className="flex justify-between">
              <h3 className="mb-2">Welcome {customer?.first_name}</h3>
              <AccountIcon customer={customer} handleClick={handleAccountClick} noPhoto={false} />
            </div>
          )}
          <div className="flex-col overflow-y-auto">
            {!isPermissionsRoute && (
              <p className="mb-5" style={{ width: '80%' }}>
                Before visiting XtraClubs for the first time, there are a few requirements we need you to review below.
              </p>
            )}
            {requirements
              .filter(
                (requirement) =>
                  !requirement.disabled &&
                  (!isPermissionsRoute || !['memberOnboarding', 'memberHealthCheck'].includes(requirement.id))
              )
              .map((requirement, index) => (
                <div key={index} className="bg-xtraNavy rounded-lg p-4 flex flex-col mb-4">
                  {requirement.complete ? (
                    <div className="flex flex-row gap-8">
                      <img
                        src={requirement.completeImage}
                        className="h-20 w-20 rounded-lg object-cover"
                        alt="complete"
                      />
                      <div className="flex flex-col justify-center gap-4">
                        <h5>{requirement.completeTitle}</h5>
                        <div className="flex-grow-0 flex flex-row relative rounded-3xl bg-xtraSuccess py-0.5 justify-center text-center items-center gap-2 max-w-24">
                          <img src={xtraTick} alt="Tick" className="w-3" />
                          <span className="text-xtraNavy text-xs font-bold items-center">Complete</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className="w-full h-28 rounded-lg mb-4 bg-contain bg-no-repeat"
                        style={{ backgroundImage: `url(${requirement.image})` }}
                      >
                        {requirement.required ? (
                          <div className="relative rounded-3xl bg-xtraError text-white py-0.5 text-xs left-2 top-2 w-20 justify-center text-center">
                            Required
                          </div>
                        ) : (
                          <div className="relative rounded-3xl bg-xtraOrange text-white py-0.5 text-xs left-2 top-2 w-20 justify-center text-center">
                            Optional
                          </div>
                        )}
                      </div>
                      <h5>{requirement.title}</h5>
                      <p className="mt-2">{requirement.description}</p>
                      {requirement.title === 'Allow push notifications' && notificationLoading ? (
                        <div>
                          <CircularProgressBar width={50} height={50} />
                        </div>
                      ) : (
                        <button className="mt-4" onClick={requirement.onClick}>
                          {requirement.onClickTitle ? requirement.onClickTitle : 'Continue'}
                        </button>
                      )}
                    </>
                  )}
                  {requirement.isPermission && !requirement.complete && (
                    <div
                      className="py-3 px-5 flex flex-row gap-1 rounded-lg text-sm bg-xtraWhite20 mt-4"
                      onClick={() =>
                        notifySwift({
                          function: 'openAppSettings',
                        })
                      }
                    >
                      <div className="flex justify-center items-center rounded-full bg-xtraOrange w-4 h-4 mt-0.5">
                        <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
                      </div>
                      <p className="flex-1 text-sm">
                        Tap here to open device settings if you have already responded to in-app prompt.
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </>
      )}
      {!isPermissionsRoute &&
        customer?.requirements?.memberOnboarding &&
        customer?.requirements?.memberAgreement &&
        customer?.requirements?.memberHealthCheck && (
          <button
            className="w-full mt-10"
            onClick={async () => {
              var data = new FormData();
              data.append('initialComplete', true);
              await Api('/customer/details/mongo', 'PUT', { initialComplete: true });
              navigate('/check-user');
            }}
          >
            Continue
          </button>
        )}
    </div>
  );
};

export default Welcome;
