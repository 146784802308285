import React, { useState, useEffect, useMemo } from 'react';
import { formatTimeSchedule } from '../renderFunctions/formatTime';
import { toast, Bounce } from 'react-toastify';
import FutureReservationsExist from '../constants/futureReservationsExist';
import ReservationItem from './ReservationItem';
import moment from 'moment-timezone';
import CircularProgressBar from './CircularProgressBar';
import { useNavigate } from 'react-router-dom';
import waitlistIcon from '../images/waitlistIcon.png';

const ReservationsList = ({
  reservations,
  page,
  setPage,
  pageLoading,
  pageEnd,
  setShowDrawer,
  setSelectedReservation,
  undoInProgress,
  theWaitlist,
  hideWaitlist = false,
  getCustomerQueue = null,
}) => {
  const navigate = useNavigate();
  const [reservationToWithdraw, setReservationToWithdraw] = useState({});

  const formatDayOfWeek = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[new Date(date).getDay()];
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const toggleSettingsMenu = async (reservation) => {
    // console.log('reservation: ', reservation);
    if (undoInProgress) {
      toast.error('Please wait for previous reservation to finish withdrawing.', {
        toastId: 'ErrorWithdraw',
        position: 'top-center',
        style: { top: '5vh', marginLeft: 20, marginRight: 20 },
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce,
      });
    } else {
      setSelectedReservation(reservation);
      setReservationToWithdraw(reservation);
      setShowDrawer(true);
    }
  };

  // Function to group reservations by date
  const groupedReservations = useMemo(() => {
    const groupedReservations = {};
    reservations?.forEach((reservation) => {
      const date = reservation.date.split('T')[0]; // Extract date from ISO string
      if (!groupedReservations[date]) {
        groupedReservations[date] = [];
      }
      groupedReservations[date].push(reservation);
    });
    return groupedReservations;
  }, [reservations]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to update window size
    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener to window resize
    window.addEventListener('resize', updateSize);

    // Call updateSize function once initially
    updateSize();

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', updateSize);
  }, []); // Empty dependency array ensures this effect only runs once

  const renderGroupedReservations = () => {
    let firstRendered = false;
    return Object.entries(groupedReservations).map(([date, reservationsForDate], groupIndex) => {
      let futureReservationsExist = FutureReservationsExist(reservationsForDate, date, todayDate);
      const showHeading = futureReservationsExist || reservationsForDate.length !== 0;

      return showHeading ? (
        <div key={groupIndex}>
          <div
            className={`reservationHeading flex flex-row items-center bg-xtraNavy pl-2 pr-2 pt-1 pb-1 rounded self-start mb-2 w-fit ${
              reservationsForDate.length > 0
                ? !reservationsForDate.some((res) => res._id === reservationToWithdraw._id)
                  ? reservationsForDate[0].slideUp
                    ? 'slide-up-heading'
                    : ''
                  : ''
                : 'hide-heading'
            }`}
            style={{
              transitionDelay: `${reservationsForDate[0].slideUpDelay}ms`,
            }}
          >
            <h6>{formatDayOfWeek(date)}</h6>
            <p className="text-xs opacity-50 ml-2 mt-0.5">{formatDate(date)}</p>
          </div>
          {reservationsForDate.map((reservation, index) => {
            if (date === todayDate) {
              if (
                isSlotTimeSameOrAfterNow(reservation).isFuture ||
                isSlotTimeSameOrAfterNow(reservation).is30MinsAfter
              ) {
                const isFirst = !firstRendered;
                firstRendered = true;
                return (
                  <ReservationItem
                    key={reservation._id}
                    reservation={reservation}
                    toggleSettingsMenu={toggleSettingsMenu}
                    isInBufferRange={isSlotTimeSameOrAfterNow(reservation).isInBufferRange}
                    is2HoursBefore={isSlotTimeSameOrAfterNow(reservation).is2HoursBefore}
                    is30MinsAfter={isSlotTimeSameOrAfterNow(reservation).is30MinsAfter}
                    formatTimeSchedule={formatTimeSchedule}
                    hideStatus={hideWaitlist}
                    getCustomerQueue={getCustomerQueue}
                    isFirst={isFirst}
                  />
                );
              } else {
                return null; // Don't render past reservations for today's date
              }
            } else {
              const isFirst = !firstRendered;
              firstRendered = true;
              return (
                <ReservationItem
                  key={reservation._id}
                  reservation={reservation}
                  toggleSettingsMenu={toggleSettingsMenu}
                  formatTimeSchedule={formatTimeSchedule}
                  hideStatus={hideWaitlist}
                  isFirst={isFirst}
                />
              );
            }
          })}
        </div>
      ) : null;
    });
  };

  const isSlotTimeSameOrAfterNow = (slot) => {
    const hours = Math.floor(slot.time);
    const minutes = (slot.time - hours) * 60;
    const slotTime = moment(slot.date).hours(hours).minutes(minutes).add(30, 'minutes');
    const startTime = moment(slot.date).hours(hours).minutes(minutes).subtract(5, 'minutes');
    const slotStartTime = moment(slot.date).hours(hours).minutes(minutes);
    const now = moment();
    const endTime = moment(slotTime).add(5, 'minutes');
    const beforeStartTimeDiff = slotStartTime.diff(now, 'minutes');
    const afterEndTimeDiff = now.diff(slotTime, 'minutes');

    return {
      is2HoursBefore: beforeStartTimeDiff <= 120 && beforeStartTimeDiff >= 0,
      is30MinsAfter: afterEndTimeDiff <= 20 && afterEndTimeDiff >= 0,
      isFuture: slotTime.isSameOrAfter(now),
      isInBufferRange: now.isBetween(startTime, endTime),
    };
  };

  const todayDate = moment().format('YYYY-MM-DD');

  return (
    <div
      className={`flex flex-col overflow-scroll ${windowSize.height > 667 ? 'h-[63vh]' : 'h-[66vh]'}`}
      style={{
        overscrollBehaviorY: 'none',
        scrollBehavior: 'smooth',
      }}
      onScroll={(event) => {
        const scrollableElement = event.target;
        const scrollDistanceFromTop = scrollableElement.scrollTop;
        const totalScrollableHeight = scrollableElement.scrollHeight;
        const visibleHeight = scrollableElement.clientHeight;
        // Check if user has reached the bottom
        if (scrollDistanceFromTop + visibleHeight === totalScrollableHeight && !pageEnd) {
          // console.log('Reached the end of scroll.');
          // Do something when reached the end of scroll
          setPage(page + 1);

          setTimeout(() => {
            scrollableElement.scrollTop += 100; // Scroll down by 50 pixels
          }, 100);
        }
      }}
    >
      {!hideWaitlist && theWaitlist.length !== 0 && (
        <div
          className="flex flex-row justify-start mb-3"
          onClick={() =>
            navigate('/waitlist', {
              state: {
                waitlist: theWaitlist,
              },
            })
          }
        >
          <div className="flex flex-row items-center">
            <img src={waitlistIcon} alt="waitlist" className="w-10 h-10 object-fit" />
            <p className={'font-semibold ml-2'}>Waitlist</p>
          </div>
        </div>
      )}
      {renderGroupedReservations()}
      {pageLoading && (
        <div className="flex flex-col justify-center mt-2">
          <CircularProgressBar />
        </div>
      )}
    </div>
  );
};

export default ReservationsList;
