import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../components/BackButton';
import frontDoorTap from '../images/frontDoorTap.jpg';
import gateTap from '../images/gateTap.jpg';
import endTap from '../images/endTap.jpg';
import frontDoorTapGreen from '../images/frontDoorTapGreen.jpg';
import gateTapGreen from '../images/gateTapGreen.jpg';
import endTapGreen from '../images/endTapGreen.jpg';
import hand from '../images/hand.png';
import Api from '../components/Api';
import renderToastSuccess from '../renderFunctions/renderToastSuccess';
import renderToastError from '../renderFunctions/renderToastError';
import CircularProgressBar from '../components/CircularProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import notifySwift from '../constants/notifySwift';

const TapHelp = ({ type }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { session, reservationId } = state ?? {};
  const [loading, setLoading] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  let title = 'Open Door';
  let backgroundImage = frontDoorTap;
  let greenBackgroundImage = frontDoorTapGreen;
  let continueStep = '/tap-gate';
  let notWorkingStep = '/open-door';
  let notWorkingText = 'Not Working?';

  if (type === 'gate') {
    title = 'Open Gate';
    backgroundImage = gateTap;
    greenBackgroundImage = gateTapGreen;
    continueStep = '/tap-start-success';
    notWorkingStep = '/open-gate';
  } else if (type === 'end') {
    title = 'End Session';
    backgroundImage = endTap;
    greenBackgroundImage = endTapGreen;
    continueStep = '/tap-end-success';
    notWorkingStep = '/splash';
    notWorkingText = "Can't Tap?";
  }

  const endSession = useCallback(
    async (isContinue) => {
      if (session?._id) {
        setLoading(true);
        const endSession = await Api(`/session/${session._id}/end`, 'PUT');

        if (endSession.status === 200) {
          if (!isContinue) {
            renderToastSuccess({
              message: 'You successfully ended your session. We hope to see you again soon.',
            });
          }
          navigate(isContinue ? continueStep : '/splash');
        } else {
          renderToastError({ message: endSession?.error?.message ?? 'Error ending session, please try again.' });
          navigate('/splash');
        }
      } else {
        navigate('/splash');
      }
      setLoading(false);
    },
    [session, continueStep, navigate]
  );

  const checkSession = useCallback(async () => {
    if (reservationId) {
      setLoading(true);
      await Api('/session', 'POST', {
        reservationId,
      });
      navigate(continueStep);
      setLoading(false);
    }
  }, [reservationId, continueStep, navigate]);

  const handleContinuePress = () => {
    if (type === 'end') {
      endSession(true);
    } else if (type === 'gate') {
      checkSession();
    } else {
      navigate(continueStep, {
        state,
      });
    }
  };

  const handleNotWorkingPress = () => {
    if (type === 'end') {
      endSession();
    } else if (type === 'gate') {
      checkSession();
    } else {
      navigate(notWorkingStep, {
        state,
      });
    }
  };

  useEffect(() => {
    const updateSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-top bg-cover justify-start">
      <div
        className="absolute top-0 right-0 left-0 bottom-0 min-h-screen bg-no-repeat bg-top bg-cover"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div
        className="absolute top-0 right-0 left-0 bottom-0 min-h-screen bg-no-repeat bg-top bg-cover snapIn"
        style={{ backgroundImage: `url(${greenBackgroundImage})` }}
      ></div>
      <div className="absolute top-0 right-0 left-0 bottom-0 min-h-screen bg-xtraNavy30"></div>
      <div
        className={`absolute top-0 left-0 w-full bg-gradient-to-b from-xtraNavy to-transparent min-h-[12vh] p-6`}
        style={{ paddingTop: windowSize.height > 667 ? '5vh' : '1vh' }}
      >
        <div className="flex flex-row justify-between items-center mb-4 z-10 mt-1">
          <div className="flex flex-row justify-start items-center">
            <BackButton />
            <h3 className="font-recoleta">{title}</h3>
          </div>
        </div>
        <div
          className="flex flex-col gap-2 py-3 px-5 bg-xtraWhite20 rounded-lg"
          onClick={() =>
            notifySwift({
              function: window.Android ? 'openBluetoothSettings' : 'openAppSettings',
            })
          }
        >
          <div className="flex flex-row gap-1 text-sm">
            <div className="flex justify-center items-center rounded-full bg-xtraOrange w-4 h-4 mt-0.5">
              <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
            </div>
            <p className="flex-1 text-sm">Bluetooth must be enabled. Tap here to open device settings.</p>
          </div>
        </div>
        {type === 'end' && (
          <div className="flex flex-col gap-2 py-3 px-5 bg-xtraWhite20 rounded-lg mt-2">
            <div className="flex flex-row gap-1 text-sm">
              <div className="flex justify-center items-center rounded-full bg-xtraOrange w-4 h-4 mt-0.5">
                <FontAwesomeIcon icon={faExclamation} size="xs" className="text-white" />
              </div>
              <p className="flex-1 text-sm">Ending your session will remove your access to all club services.</p>
            </div>
          </div>
        )}
      </div>
      <img src={hand} alt="Sliding" className="fixed top-1/3 right-0 w-72 animate-slide" />
      <div
        className={`absolute bottom-0 left-0 w-full p-6`}
        style={{ paddingTop: windowSize.height > 667 ? '5vh' : '1vh' }}
      >
        <p className="text-center text-3xl font-black mb-5 leading-normal whiteShadow">
          Tap & hold your phone at the reader
        </p>
        <div className="flex flex-col justify-center items-center mb-4 z-10 mt-1 gap-2.5">
          <button className="w-[90%] p-4 text-xl" onClick={handleContinuePress}>
            Continue
          </button>
          <button
            className="w-[90%] p-4 text-xl border border-white bg-transparent whiteShadow"
            onClick={handleNotWorkingPress}
          >
            {notWorkingText}
          </button>
        </div>
      </div>
      {loading && (
        <div className="absolute left-0 right-0 bottom-0 top-0 flex flex-col justify-center items-center bg-xtraBlack50">
          <CircularProgressBar />
        </div>
      )}
    </div>
  );
};

export default TapHelp;
