import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import Api from '../components/Api';
import * as Sentry from '@sentry/react';

export const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(true);

  const customerSubscription = customer?.subscription;
  const customerEntitlements = customerSubscription?.entitlements;
  const customerSessionPacks = customerSubscription?.sessionPacks ?? [];
  const customerGifts = customerSubscription?.gifts ?? [];
  const customerSessionPacksAndGifts = [...customerSessionPacks, ...customerGifts];
  const customerActiveMembership = customerSubscription?.userSubscriptions?.find((sub) => sub.status === 'active');
  const customerOnHoldMembership = customerActiveMembership
    ? null
    : customerSubscription?.userSubscriptions?.find((sub) => sub.status === 'on-hold');
  const customerMembership = customerActiveMembership || customerOnHoldMembership;
  const customerMembershipOnHold = !customerActiveMembership && customerOnHoldMembership;
  const customerMembershipAwaitingCancellation = customerMembership?.cancellationDate !== '';
  const customerMembershipProduct = customerMembership?.products?.find((product) =>
    product.categories?.includes('Membership Packages')
  );
  const customerMembershipHasExtendedSession = customerMembership?.products?.some(
    (product) => product.categories.includes('Add-On') && product.product_name === 'Extended session time'
  );
  const customerMembershipProductName = customerMembershipProduct?.product_name;

  const getCustomer = useCallback(async ({ update } = {}) => {
    setCustomerLoading(true);
    const query = update ? '?update=true' : '';
    const response = await Api(`/customer${query}`, 'GET');
    if (response.status === 200 && response.data.customer) {
      let cust = response.data.customer;
      if (cust.billing_details) {
        cust = { ...cust, ...cust.billing_details };
      }
      setCustomer(cust);
    }
    setCustomerLoading(false);
  }, []);

  useEffect(() => {
    Sentry.setUser(customer ? { email: customer.email } : null);
  }, [customer]);

  return (
    <CustomerContext.Provider
      value={{
        customer,
        customerSubscription,
        customerEntitlements,
        customerSessionPacksAndGifts,
        customerMembership,
        customerMembershipOnHold,
        customerMembershipAwaitingCancellation,
        customerMembershipProduct,
        customerMembershipProductName,
        customerMembershipHasExtendedSession,
        getCustomer,
        customerLoading,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  return useContext(CustomerContext);
};
