import React, { useState } from 'react';
import openEye from '../images/openEye.png';
import closeEye from '../images/closeEye.png';
import close from '../images/close.png';
import CircularProgressBar from './CircularProgressBar';

function LockerDetailPopup({
  details,
  handleRequestTallLocker,
  handleReAssignTallLocker,
  handleReAssignLocker,
  loading,
  showLockerPopup,
  setShowLockerPopup,
}) {
  const [seeCode, setSeeCode] = useState(false);

  const handleClose = () => {
    setSeeCode(false);
    setShowLockerPopup(false);
  };

  if (showLockerPopup) {
    return (
      <>
        <div className={`drawer-overlay ${showLockerPopup ? 'visible' : ''}`} onClick={handleClose} />
        <div className={`drawer ${showLockerPopup ? 'drawer-open' : 'drawer-closed'} pt-10 mb-[25vh]`}>
          <div className="drawer-content">
            {loading ? (
              <CircularProgressBar />
            ) : (
              <div className="flex flex-col pb-8">
                <img
                  onClick={handleClose}
                  src={close}
                  className=" absolute top-[20px] right-[20px] w-6 h-6 object-fit"
                  alt="close"
                />
                <h4 className="mb-5 font-recoleta">Your Locker</h4>
                <p className="mb-5 text-xl font-semibold">Number: {details?.locker_number}</p>
                <div className="flex flex-row mb-5">
                  <p className="text-xl font-semibold flex flex-row">
                    Code: {seeCode ? details?.locker_code : '- - -'}
                  </p>
                  <div
                    className="flex flex-row whiteBorder ml-5 items-center px-2 rounded"
                    onClick={() => setSeeCode(!seeCode)}
                  >
                    <img src={seeCode ? closeEye : openEye} alt="show code" className="w-5 h-5 object-fit" />
                    <p className="text-sm ml-1 text-xtraAqua">{seeCode ? 'Hide' : 'Show'}</p>
                  </div>
                </div>
                <div className="flex flex-col mt-5">
                  {details.locker_type === 'small' ? (
                    <button
                      className="whiteBorder mb-5"
                      onClick={() => {
                        setSeeCode(false);
                        handleRequestTallLocker();
                      }}
                    >
                      Need a tall locker?
                    </button>
                  ) : (
                    <button
                      className="whiteBorder mb-5"
                      onClick={() => {
                        setSeeCode(false);
                        handleReAssignTallLocker();
                      }}
                    >
                      Need a new tall locker?
                    </button>
                  )}
                  <button
                    className="whiteBorder mb-5"
                    onClick={() => {
                      setSeeCode(false);
                      handleReAssignLocker();
                    }}
                  >
                    Need a new locker?
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default LockerDetailPopup;
