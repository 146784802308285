import { toast, Bounce } from 'react-toastify';
import Api from '../components/Api';

export default async function withdrawReservation({
  customerQueue,
  undoReservations,
  message,
  setCustomerQueue,
  fadeOutDuration,
  setUndoInProgress,
  headings,
}) {
  try {
    const undo = await Api('/schedule/withdraw', 'post', { reservations: undoReservations });
    if (undo.status === 200) {
      const result = undo.data;
      if (result.success) {
        // Fade out each reservation in the array
        // Adjust timeout to match fade-out duration (if necessary)
        undoReservations.forEach((reservation, index) => {
          // console.log('undo reservation: ', reservation);
          const reservationId = reservation._id;
          const reservationElement = document.getElementById(reservationId);
          if (reservationElement) {
            // Apply fade-out effect using CSS transition
            reservationElement.style.transition = `opacity ${fadeOutDuration / 1000}s ease`;
            reservationElement.style.opacity = 0;
          }
        });

        setTimeout(() => {
          let totalSlideUpDelay = 0;
          const startIndex = customerQueue.findIndex((reserv) =>
            undoReservations.some((undoRes) => undoRes._id === reserv._id)
          );

          // console.log('state Index: ', startIndex);

          setCustomerQueue(
            customerQueue.map((reservation, index) => {
              if (index >= startIndex) {
                const slideUpDelay = index * 500;
                totalSlideUpDelay += slideUpDelay - 500;

                return {
                  ...reservation,
                  slideUp: true,
                  slideUpDelay: slideUpDelay,
                };
              } else {
                return {
                  ...reservation,
                };
              }
            })
          );

          // console.log('totaql slide up  delay: ', totalSlideUpDelay);
          setTimeout(() => {
            // Reset opacity and apply slide-up animation to all divs with classname "reservation-item"
            const reservationItems = document.querySelectorAll('.reservation-item');
            reservationItems.forEach((item) => {
              item.style.opacity = 1;
              item.style.transition = 'none'; // Remove transition effect for opacity
              item.style.transform = 'translateY(0)'; // Reset translateY to 0 for slide-up animation
            });

            const reservationHeadings = document.querySelectorAll('.reservationHeading');
            reservationHeadings.forEach((item) => {
              item.style.transition = 'none'; // Remove transition effect for opacity
              item.style.transform = 'translateY(0)'; // Reset translateY to 0 for slide-up animation
            });

            const updatedQueue = customerQueue
              .filter((reserv) => undoReservations.every((undoRes) => String(undoRes._id) !== String(reserv._id)))
              .map((reserv) => ({ ...reserv, slideUp: false }));

            setCustomerQueue(updatedQueue);
            setUndoInProgress(false);
            toast.dismiss('ErrorWithdraw');

            setTimeout(() => {
              reservationItems.forEach((item) => {
                item.style.transition = ''; // Remove transition effect for opacity
                item.style.transform = ''; // Reset translateY to 0 for slide-up animation
              });
              reservationHeadings.forEach((item) => {
                item.style.transition = ''; // Remove transition effect for opacity
                item.style.transform = ''; // Reset translateY to 0 for slide-up animation
              });
            }, 1000);
          }, totalSlideUpDelay);
        }, fadeOutDuration - 1000);
      }
      toast.info('Successfully withdrew your reservations.', {
        position: 'top-center',
        style: { top: '5vh', marginLeft: 20, marginRight: 20 },
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce,
      });
    } else {
      toast.error(message, {
        position: 'top-center',
        style: { top: '5vh', marginLeft: 20, marginRight: 20 },
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        transition: Bounce,
      });
    }
  } catch (error) {
    setUndoInProgress(false);
    toast.error(message, {
      position: 'top-center',
      style: { top: '5vh', marginLeft: 20, marginRight: 20 },
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      transition: Bounce,
    });
  }
}
