import React from 'react';

function PeakSessionsDrawer({
  show,
  setShow,
  activeMembership,
  membershipDetails,
  handlePeakSessionClick,
  handlePurchaseSinglePeakVisit,
}) {
  const handleClose = () => {
    setShow(false);
  };

  const checkCanSubtract = () => {
    if (membershipDetails?.entitlements) {
      let peakHourReservationsAvailable = membershipDetails.entitlements?.['Peak-Hour Reservations'] || 0;
      let peakHoursToBeRemoved =
        membershipDetails?.entitlements?.['Number of Additional Peak Visits Will Be Removed'] || 0;
      let canSubtract = peakHourReservationsAvailable - 1 > peakHoursToBeRemoved;

      return canSubtract;
    }

    return false;
  };

  checkCanSubtract();

  return (
    <>
      <div className={`drawer-overlay ${show ? 'visible' : ''}`} onClick={handleClose} />
      <div className={`drawer ${show ? 'drawer-open' : 'drawer-closed'} pt-5`}>
        <div className="drawer-content mb-8 w-full">
          <h4 className="mb-5">Peak Session Options</h4>
          {!activeMembership?.product_name.includes('Thrive') && checkCanSubtract() && (
            <button
              className="rounded-full bg-transparent border border-xtraWhite30 text-sm w-full mb-3"
              onClick={() => {
                handlePeakSessionClick(true);
              }}
            >
              Subtract
            </button>
          )}
          <button
            className="rounded-full text-sm w-full mb-3"
            onClick={() => {
              handlePurchaseSinglePeakVisit();
            }}
          >
            Purchase Single Use
          </button>
          {!activeMembership?.product_name.includes('Thrive') && (
            <button
              className="rounded-full text-sm w-full mb-3"
              onClick={() => {
                setShow(false);
                handlePeakSessionClick(false);
              }}
            >
              Increase
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default PeakSessionsDrawer;
