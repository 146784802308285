import Api from '../components/Api';

const getCustomerPauses = async () => {
  const response = await Api('/customer/membership/pause', 'GET');

  if (response.status === 200 && response.data.pauses) {
    return response.data.pauses;
  } else {
    return [];
  }
};

export default getCustomerPauses;
