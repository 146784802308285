import React from 'react';
import arrowRight from '../images/arrow-right.png';
import { useNavigate } from 'react-router-dom';

const NavigationMenuItem = ({ title, location, onClick }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        location ? navigate(location) : onClick();
      }}
      className={`flex flex-row items-center justify-between px-6 py-4 ${title !== 'Logout' && 'acc-row'} ${title === 'Membership details' && 'top-row'} `}
    >
      <h5 className="text-lg">{title}</h5>
      <img className="w-3" src={arrowRight} alt="arrow right" />
    </div>
  );
};

export default NavigationMenuItem;
