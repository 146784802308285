import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faInfinity } from '@fortawesome/free-solid-svg-icons';

const customFormatOptionLabel = ({ subscriptionSelectOpen, isoWeeks, isoWeekPeakLimits, entitlements, props }) => {
  const { label, orderId, sessionsUsed, sessionMaximum, type } = props;
  // Get keys from isoWeekPeakLimits object
  const isoWeekPeakLimitsKeys = Object.keys(isoWeekPeakLimits);

  // Find the intersection of isoWeeks Set and isoWeekPeakLimitsKeys
  const matchedKeys = isoWeekPeakLimitsKeys.filter((key) => isoWeeks.has(Number(key)));

  // Get the isoWeekPeak limit values for the matched keys
  const isoWeekPeakLimitsValues = matchedKeys.map((key) => isoWeekPeakLimits[key]);

  return (
    <div className="flex flex-col w-full p-2">
      <div className="flex flex-row text-white items-center">
        <p className="text-lg font-semibold optionHeading">
          {label}{' '}
          {type === 'subscription' && !subscriptionSelectOpen && (
            <FontAwesomeIcon size="xs" className="text-white" icon={faChevronDown} />
          )}
        </p>
        {orderId && <p className="text-sm ml-1 orderId"> - Order #{orderId}</p>}
      </div>
      {type !== 'subscription' ? (
        <div className="flex flex-row w-full items-center mt-2.5 text-white text-sm justify-between">
          <p className="text-sm">Sessions Remaining</p>
          <p>{sessionsUsed !== '' ? sessionsUsed : sessionMaximum}</p>
        </div>
      ) : (
        <div className="flex flex-row items-center">
          <div className="w-1/2 bg-xtraWhite30 rounded-tl rounded-bl pr-3 py-2 flex flex-col min-h-[72px] max-h-[92px]">
            <p className="text-xs pr-2 pl-2 pt-1 pb-1">Sessions remaining</p>
            <p className="text-xs pr-2 pl-2 pt-2 pb-1">This week</p>
            {isoWeeks.size > 1 && <p className="text-xs pr-2 pl-2 pt-1 pb-1">Next week</p>}
          </div>
          <div className="w-3/10 bg-xtraWhite30 px-3 py-2 flex text-center flex-col min-h-[72px] max-h-[92px]">
            <p className={`slot-peak rounded-full pr-2 pl-2 pt-1 pb-1 text-xs`}>Peak</p>
            {label === 'Thrive' ? (
              <p className="text-xs pr-2 pl-2 pt-3">
                <FontAwesomeIcon size="lg" icon={faInfinity} />
              </p>
            ) : (
              <p className="relative text-sm pr-2 pl-2 pt-2 font-semibold">
                {isoWeekPeakLimitsValues[0]} 
              </p>
            )}
            {isoWeeks.size > 1 && label === 'Thrive' ? (
              <p className="text-xs pr-2 pl-2 pt-2">
                <FontAwesomeIcon size="lg" icon={faInfinity} />
              </p>
            ) : (
              <p className="text-sm pr-2 pl-2 pt-1">{isoWeekPeakLimitsValues[1]}</p>
            )}
          </div>
          <div className="w-2/10 bg-xtraWhite30 rounded-tr rounded-br px-3 py-2 flex text-center flex-col min-h-[72px] max-h-[92px]">
            <p className={`bg-white text-black rounded-full pr-2 pl-2 pt-1 pb-1 text-xs`}>Off-peak</p>
            <p className="text-xs pr-2 pl-2 pt-3">
              {entitlements['Peak-Hour Reservations'] ? <FontAwesomeIcon size="lg" icon={faInfinity} /> : 'Limited'}
            </p>
            {isoWeeks.size > 1 && (
              <p className="text-xs pr-2 pl-2 pt-2">
                {entitlements['Peak-Hour Reservations'] ? <FontAwesomeIcon size="lg" icon={faInfinity} /> : 'Limited'}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default customFormatOptionLabel;