import React, { useEffect } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import CircularProgressBar from '../components/CircularProgressBar';
import notifySwift from '../constants/notifySwift';
import * as Sentry from '@sentry/react';
import isCasual from '../constants/isCasual';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    Sentry.setUser(null);

    if (isCasual) {
      navigate('/login');
    } else {
      notifySwift({
        function: 'logout',
        data: {},
      });
    }
  }, [navigate]);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-10 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <CircularProgressBar />
    </div>
  );
}

export default Logout;
