import Api from '../components/Api';

const getSite = async (site) => {
  const theSite = await Api(`/site/${site}`, 'GET');
  if (theSite.status === 200) {
    let result = theSite.data;
    if (result.success) {
      return result.site;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default getSite;
