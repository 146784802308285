import Api from '../components/Api';

const updateMembership = async (membership, returnPrice = 0) => {
  const response = await Api(`/customer/membership/update`, 'POST', {
    membership,
    returnPrice,
  });

  if (response.status === 200) {
    const result = response.data;
    if (result.success) {
      return result;
    }
  } else {
    return response;
  }
};

export default updateMembership;
