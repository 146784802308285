import React from 'react';
import alertIcon from '../images/alert.png';

const ErrorPopover = ({ message, onClose }) => {
  return (
    <div className="fixed h-full w-full top-0 left-0 bg-xtraNavy50 items-center justify-center flex">
      <div className="p-10 bg-xtraNavy drop-shadow-sm z-50 flex flex-col items-start justify-center">
        <img className="w-14 h-auto mb-4" src={alertIcon} alt="alert icon" />
        <h5 className="mb-4">{message}</h5>
        <button className="rounded-full" onClick={onClose}>
          Try Again
        </button>
      </div>
    </div>
  );
};

export default ErrorPopover;
