import Api from '../components/Api';

const updateCustomerPeakSession = async (returnMessage, remove = false) => {
  const service = await Api(`/customer/subscription/peaksession`, 'PUT', {
    returnPrice: returnMessage,
    remove: remove,
  });

  if (service.status === 200) {
    const result = service.data;
    if (result.success) {
      return result;
    }
  } else {
    return service;
  }
};

export default updateCustomerPeakSession;
