import Api from '../components/Api';

const purchaseProduct = async (productDetails) => {
  const response = await Api(`/customer/purchase-product`, 'POST', productDetails);

  if (response.status === 200) {
    const result = response.data;
    if (result.success) {
      return result;
    }
  } else {
    return response;
  }
};

export default purchaseProduct;
