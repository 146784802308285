import React, { useEffect, useCallback } from 'react';
import splashBG from '../images/splash-bg.jpg';
import { useNavigate } from 'react-router-dom';
import { getCustomerActiveSession } from '../constants/getCustomerSession';
import { getCustomerDetails } from '../constants/customerDetails';
import { updateCustomerRequirements } from '../constants/updateCustomerRequirements';
import notifySwift from '../constants/notifySwift';
import allValuesTrue from '../constants/allValuesTrue';
import renderToastError from '../renderFunctions/renderToastError';
import Api from '../components/Api';
import moment from 'moment-timezone';

function Splash() {
  const navigate = useNavigate();

  const checkUser = useCallback(async () => {
    let isXtra = false;

    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      navigate('/login');
    }

    let customerAuth0Details = await Api('/customer/auth0', 'POST', {
      token: accessToken,
    });

    if (customerAuth0Details.data?.success && customerAuth0Details.data?.customer.email.includes('@xtraclubs.au')) {
      if (customerAuth0Details.data.customer.email === 'development@xtraclubs.au') {
        isXtra = false;
      } else {
        isXtra = true;
      }
    }

    if (isXtra) {
      notifySwift({
        function: 'promptLocationPermissions',
      });
      notifySwift({
        function: 'promptBluetoothPermissions',
      });

      let xtra = await Api('/session/xtra', 'GET');
      let hasSession = false;
      if (xtra.status === 200) {
        if (xtra.data.success) {
          if (xtra.data.session) {
            hasSession = true;
          }
        }
      }

      if (hasSession) {
        let date = moment().format();
        navigate(`/xtra/session`, {
          state: {
            startTime: date,
            duration: 70,
          },
        });
      } else {
        navigate(`/xtra/sessions`);
      }
    } else {
      var customer = await getCustomerDetails();

      if (!customer) {
        return;
      }

      let session = await getCustomerActiveSession();

      if (customer) {
        if (customer.flaggedForDeletion) {
          localStorage.clear();
          renderToastError({
            message:
              'Your account has been flagged for deletion and an email has been sent with further information. You are unable to continue. If this is a mistake, please contact us.',
          });
          navigate('/login');
          return;
        }

        if (customer.initialComplete) {
          notifySwift({
            function: 'promptLocationPermissions',
          });
          notifySwift({
            function: 'promptBluetoothPermissions',
          });
          notifySwift({
            function: 'promptPushNotifications',
          });
        }

        // Check if all values are true
        const allTrue = customer?.requirements && allValuesTrue(customer.requirements);
        if (allTrue) {
          if (session) {
            navigate(`/session?id=${session._id}`);
          } else {
            navigate('/your-sessions');
          }
        } else {
          navigate('/welcome');
        }
      } else {
        renderToastError({
          message:
            'User Account could not be found. Please make sure you have purchasesd a subscription or session pack before trying to login. If the issue perssists, please contact us.',
        });
      }
    }
  }, [navigate]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  useEffect(() => {
    window.addEventListener('LocationPermissions', (e) =>
      updateCustomerRequirements({
        type: 'location',
        data: e,
        retrieveCustomer: () => null,
      })
    );
    window.addEventListener('BluetoothPermissions', (e) =>
      updateCustomerRequirements({
        type: 'bluetooth',
        data: e,
        retrieveCustomer: () => null,
      })
    );
    window.addEventListener('PushNotifications', (e) =>
      updateCustomerRequirements({
        type: 'notifications',
        data: e,
        retrieveCustomer: () => null,
      })
    );

    return () => {
      window.removeEventListener('LocationPermissions', (e) =>
        updateCustomerRequirements({
          type: 'location',
          data: e,
          retrieveCustomer: () => null,
        })
      );
      window.removeEventListener('BluetoothPermissions', (e) =>
        updateCustomerRequirements({
          type: 'bluetooth',
          data: e,
          retrieveCustomer: () => null,
        })
      );
      window.removeEventListener('PushNotifications', (e) =>
        updateCustomerRequirements({
          type: 'notifications',
          data: e,
          retrieveCustomer: () => null,
        })
      );
    };
  }, []);

  useEffect(() => {
    const refreshTimeout = setTimeout(() => {
      window.location.reload();
    }, 5000);

    return () => clearTimeout(refreshTimeout);
  }, []);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover"
      style={{ backgroundImage: `url(${splashBG})` }}
    ></div>
  );
}

export default Splash;
