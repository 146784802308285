import Api from '../components/Api';

const getProduct = async (sku) => {
  const response = await Api(`/appsettings/product-details?sku=${sku}`, 'GET', null);

  if (response.status === 200) {
    const result = response.data;
    if (result.success) {
      return result;
    }
  } else {
    return null;
  }
};

export default getProduct;
