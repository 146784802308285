import React, { useEffect } from 'react';
import SafeAreaHeader from '../components/SafeAreaHeader';
import xtraBG from '../images/xtra-bg.jpg';
import callHelp from '../images/callHelp.png';
import emailHelp from '../images/emailHelp.png';
import lockersHelp from '../images/Lockers.jpg';
import { useNavigate, useLocation } from 'react-router-dom';
import TabBarNavigator from '../components/TabBarNavigator';
import { ReactComponent as PlanIcon } from '../assets/tabBar/Plan.svg';
import { ReactComponent as HelpIcon } from '../assets/tabBar/Help.svg';
import AccountIcon from '../components/AccountIcon';
import notifySwift from '../constants/notifySwift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useCustomer } from '../contexts/CustomerContext';

const HelpOption = ({ image, title, description, handleClick }) => (
  <div className="bg-xtraNavy rounded-lg p-2 flex flex-row gap-3 items-center" onClick={handleClick}>
    <img src={image} className="h-16 w-16 rounded-lg object-cover" alt="option" />
    <div className="flex flex-1 flex-row justify-between items-center">
      <div className="flex flex-col justify-center gap-1 flex-1">
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
      <div className="w-10 h-10 flex justify-center items-center bg-xtraAqua rounded-full text-center align-middle">
        <FontAwesomeIcon icon={faArrowRight} size="xl" className="text-white" />
      </div>
    </div>
  </div>
);

const Help = () => {
  const { customer, getCustomer } = useCustomer();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('id');
  const isXtra = queryParams.get('xtra');

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-cover bg-center p-6 overflow-y-auto pb-9"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <SafeAreaHeader />
      <div className="flex justify-between items-center mb-5">
        <h6>We're here to help</h6>

        <AccountIcon yourDetails={customer} handleClick={() => navigate('/you')} noPhoto={false} />
      </div>
      <div className="flex flex-col overflow-scroll max-h-[75vh] gap-4">
        <HelpOption
          image={lockersHelp}
          title="Locked out?"
          description="Recover locker code"
          handleClick={() => navigate('/retrieve-locker-code')}
        />
        {/* <HelpOption
          image={accessHelp}
          title="Club access"
          description="Stuck? Watch these videos"
          handleClick={() => {}}
        />
        <HelpOption
          image={emailHelp}
          title="Frequently asked"
          description="Answers to your questions"
          handleClick={() => {}}
        /> */}
        <HelpOption
          image={emailHelp}
          title="Email support"
          description="Feedback & enquiries"
          handleClick={() => {
            notifySwift({
              function: 'openExteneralLink',
              data: {
                url: `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`,
              },
            });
          }}
        />
        <HelpOption
          image={callHelp}
          title="Call help line"
          description="Need urgent help?"
          handleClick={() => {
            notifySwift({
              function: 'openExteneralLink',
              data: {
                url: `tel://${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}`,
              },
            });
          }}
        />
      </div>
      <TabBarNavigator
        tabs={[
          {
            name: sessionId ? 'Session' : 'Sessions',
            Icon: PlanIcon,
            route: sessionId ? `/session?id=${sessionId}` : isXtra ? '/xtra/sessions' : '/your-sessions',
          },
          { name: 'Help', Icon: HelpIcon, route: '/help' },
        ]}
        paddingHorizontal={80}
      />
    </div>
  );
};

export default Help;
